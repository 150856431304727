import React, { useCallback, useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Checkbox, Input } from "antd";
import TrackedItemTypeNameDropdown from "../../../../../../../layout/components/select/TrackedItemTypeNameDropdown";
import localize from "../../../../../../../core/utils/translation-files";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import LookupChildDropdown from "../../../../../../../layout/components/select/LookupChildDropdown";
import GroupDropdownContainer from "../../../../../../../layout/components/select/GroupDropdownContainer";
import SiteDropdownContainer from "../../../../../../../layout/components/select/SiteDropdownContainer";
import CompanyDropdownContainer from "../../../../../../../layout/components/select/CompanyDropdownContainer";
import Asterik from "../../../../../shared/helper-components/Asterik";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import usePermissions from "../../../../../../../core/hooks/usePermissions";
import AreaLoader from "../../../../../../../layout/components/loading/areaLoader";
const TrackedItemInformation = ({ handleChange, isSaveHover, customError, setCustomError, handleBlur, validateName, errors, touched, setFieldValue, values, trackedItemTypeNameChangeHandler, setSelectedGroup, isClientAdmin, isSystemCreated, isSystemCreatedEdit, allowGroupNaItem, isHoldingCompany = null, setIsHoldingCompany = null, isHidden, }) => {
    const { groupOptions } = usePermissions();
    useEffect(() => {
        var _a;
        if (values.id > 0 && isEmpty(isHoldingCompany)) {
            setIsHoldingCompany((_a = groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.filter((i) => i.id === values.groupId)[0]) === null || _a === void 0 ? void 0 : _a.isHoldingCompany);
        }
    });
    const handleChangeGroup = useCallback((value, e) => {
        setSelectedGroup(value);
        setIsHoldingCompany(e.isHoldingCompany);
        // setFieldValue("groupId", value);
        // setFieldValue("companyId", undefined);
        // setFieldValue("siteId", undefined);
        trackedItemTypeNameChangeHandler(undefined, {
            groupId: value,
            siteId: undefined,
            companyId: undefined,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeCompany = useCallback((value, e) => {
        // setFieldValue("siteId", value);
        // setFieldValue("companyId", undefined);
        trackedItemTypeNameChangeHandler(undefined, {
            siteId: value,
            companyId: undefined,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const OnChangeParentCategory = (value) => {
        setFieldValue("itemCategoryId", value);
        setFieldValue("itemSubCategoryId", undefined);
    };
    return (React.createElement("div", { className: "box-main-section" },
        React.createElement("section", { className: "box-section", "data-test": "tracked-item-information-component" },
            React.createElement("div", { className: "box-title" },
                React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.trackedItem", "information"]))),
            React.createElement("div", { className: "box-description" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "group-selection-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["Group.group", "name"]),
                                    " ",
                                    React.createElement(Asterik, null))),
                            React.createElement(GroupDropdownContainer, { className: "mb-2 mb-md-0", selectedValue: values.groupId, isDisable: values.id > 0, onChange: handleChangeGroup, allowNAItem: allowGroupNaItem, error: errors.groupId && touched.groupId ? errors === null || errors === void 0 ? void 0 : errors.groupId : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "company-selection-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["Company.company", "name"]),
                                    " ",
                                    React.createElement(Asterik, null))),
                            React.createElement(CompanyDropdownContainer, { className: "mb-2 mb-md-0", selectedValue: values.siteId, groupId: values.groupId, onChange: handleChangeCompany, disabled: isEmpty(values.groupId) || values.id > 0, error: errors.siteId && touched.siteId ? errors === null || errors === void 0 ? void 0 : errors.siteId : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "site-selection-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.site", "name"]),
                                    " ",
                                    React.createElement(Asterik, null))),
                            React.createElement(SiteDropdownContainer, { className: "mb-2 mb-md-0", groupId: values.groupId, companyId: values.siteId, selectedValue: values.companyId, onChange: (value, e) => {
                                    //setFieldValue("companyId", value);
                                    trackedItemTypeNameChangeHandler(undefined, {
                                        companyId: value,
                                    });
                                }, disabled: isEmpty(values.siteId) || values.id > 0, error: errors.companyId && touched.companyId
                                    ? errors === null || errors === void 0 ? void 0 : errors.companyId
                                    : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "tracked-item-type-name-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize([
                                        "TrackedItem.trackedItem",
                                        "type",
                                        "information",
                                    ]),
                                    React.createElement(Asterik, null))),
                            React.createElement(TrackedItemTypeNameDropdown, { className: "mb-2 mb-md-0", selectedValue: values.id > 0 ? values.reportType.name : values.reportTypeId, onChange: trackedItemTypeNameChangeHandler, disabled: isEmpty(values.companyId) || values.id > 0, selectedSiteId: values.companyId === -1
                                    ? values.siteId === -1
                                        ? values.groupId
                                        : values.siteId
                                    : values.companyId, systemCreated: isSystemCreated, isHidden: isHidden }),
                            errors.reportTypeId && touched.reportTypeId && (React.createElement("p", { className: "error" }, errors.reportTypeId)))),
                    React.createElement("div", { className: "col-md-3", "data-test": "tracked-item-name-input" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.trackedItem", "name"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(Input, { type: "text", name: "name", suffix: React.createElement(AreaLoader, { wrapperClassName: "ti-name-check-loader", area: "trackedItemNameCheck" }), placeholder: localize(["TrackedItem.trackedItem", "name"]), 
                                // onBlur={handleBlur}
                                onBlur: (e) => { handleBlur(e); isSaveHover.current === true && validateName(e.target.value); }, onChange: (e) => { handleChange(e); !isEmpty(customError) && setCustomError(undefined); }, value: values.name, 
                                // className={`${
                                //   errors.name && touched.name ? "input-error" : null
                                // }`}
                                className: `${((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.name) &&
                                    (customError === null || customError === void 0 ? void 0 : customError.name) !== "") ||
                                    errors.name) &&
                                    touched.name
                                    ? "input-error"
                                    : null}`, disabled: values.reportTypeId === undefined || isSystemCreatedEdit }),
                            ((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.name) && (customError === null || customError === void 0 ? void 0 : customError.name) !== "") ||
                                errors.name) &&
                                touched.name && (React.createElement("p", { className: "error" }, errors.name || (customError === null || customError === void 0 ? void 0 : customError.name))))),
                    React.createElement("div", { className: "col-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "tracked-item-format-input" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.trackedItem", "format"]))),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.format, onChange: setFieldValue, inputName: "format", localizationKey: ["TrackedItem.trackedItemFormat"], disabled: values.reportTypeId === undefined, lookUpType: LookupNames.LKReportType, error: errors.format && touched.format ? errors.format : null }))),
                    React.createElement("div", { className: "col-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "parent-group-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.category"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: !isEmpty(values.itemCategoryId)
                                    ? Math.abs(values.itemCategoryId)
                                    : values.itemCategoryId, customOnChangeHandler: OnChangeParentCategory, localizationKey: ["TrackedItem.category"], lookUpType: LookupNames.LKItemCategory, disabled: true, error: errors.itemCategoryId && touched.itemCategoryId
                                    ? errors.itemCategoryId
                                    : null }))),
                    React.createElement("div", { className: "col-3", hidden: isClientAdmin || isSystemCreatedEdit, "data-test": "topic-group-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.subCategory"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(LookupChildDropdown, { parentId: values.itemCategoryId, className: "mb-2 mb-md-0", selectedValue: values.itemSubCategoryId === -1
                                    ? ""
                                    : values.itemSubCategoryId, onChange: setFieldValue, inputName: "itemSubCategoryId", localizationKey: ["TrackedItem.subCategory"], parentLookUpType: LookupNames.LKItemCategory, disabled: true, error: errors.itemSubCategoryId && touched.itemSubCategoryId
                                    ? errors.itemSubCategoryId
                                    : null }))),
                    React.createElement("div", { className: "col-3", "data-test": "tracked-item-risk-level-input" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.levelOfRisk"]))),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.itemRiskLevelId, onChange: setFieldValue, inputName: "itemRiskLevelId", localizationKey: ["TrackedItem.levelOfRisk"], disabled: values.reportTypeId === undefined, lookUpType: LookupNames.LKItemRiskLevel, error: errors.itemRiskLevelId && touched.itemRiskLevelId
                                    ? errors.itemRiskLevelId
                                    : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit || isSystemCreated },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.groupWeight"])),
                                " ",
                                React.createElement(Asterik, null)),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.groupWeight, onChange: setFieldValue, inputName: "groupWeight", localizationKey: ["Group.group", "TrackedItem.weight"], 
                                // disabled={true}
                                lookUpType: LookupNames.LKReportWeight, error: errors.groupWeight && touched.groupWeight
                                    ? errors.groupWeight
                                    : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit || isSystemCreated },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.companyWeight"])),
                                " ",
                                React.createElement(Asterik, null)),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.companyWeight, onChange: setFieldValue, inputName: "companyWeight", localizationKey: ["Company.company", "TrackedItem.weight"], 
                                // disabled={true}
                                lookUpType: LookupNames.LKReportWeight, error: errors.companyWeight && touched.companyWeight
                                    ? errors.companyWeight
                                    : null }))),
                    React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.weight"])),
                                " ",
                                React.createElement(Asterik, null)),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.weight, onChange: setFieldValue, inputName: "weight", localizationKey: ["TrackedItem.weight"], 
                                // disabled={true}
                                lookUpType: LookupNames.LKReportWeight, error: errors.weight && touched.weight ? errors.weight : null }))),
                    React.createElement("div", { className: "col-md-6 descriptionField" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.trackedItem", "description"]))),
                            React.createElement(TextArea, { name: "description", placeholder: localize([
                                    "TrackedItem.trackedItem",
                                    "description",
                                ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.description, value: values.description, className: `${errors.description && touched.description
                                    ? "input-error"
                                    : null}`, disabled: values.reportTypeId === undefined }),
                            errors.description && touched.description && (React.createElement("p", { className: "error" }, errors.description))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-3", hidden: isClientAdmin || isSystemCreatedEdit },
                        React.createElement("div", { className: values.id === 0 ? "form-group" : "form-group d-none" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null, localize(["TrackedItem.disableForThisSite"]))),
                            React.createElement(Checkbox, { name: "disabled", checked: values.disabled, onChange: handleChange, disabled: values.reportTypeId === undefined }))))))));
};
export default TrackedItemInformation;
