import { useEffect } from "react";
import React from "react";
import SingleSelectNew from "./SingleSelectNew";
import { useDispatch, useSelector } from "react-redux";
import { getTrackedItemTypesBySite } from "../../../redux/tracked-item-type/actions";
const TrackedItemTypeNameDropdown = ({ onChange, selectedValue, className, disabled, selectedSiteId, systemCreated, isHidden, }) => {
    const dispatch = useDispatch();
    const trackedItemTypes = useSelector((state) => { var _a; return (_a = state.trackedItemTypes) === null || _a === void 0 ? void 0 : _a.trackedItemBySites; });
    useEffect(() => {
        selectedSiteId &&
            dispatch(getTrackedItemTypesBySite(selectedSiteId, systemCreated, isHidden));
    }, [selectedSiteId, systemCreated, isHidden, dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { allowClear: false, "data-test": "tracked-item-type-dropdown", options: trackedItemTypes, selectedValue: selectedValue, onChange: onChange, valueProperty: "id", textProperty: "name", placeholder: "Select Tracked Item Type Name", key: "trackedItemTypeSelect", className: className, disabled: disabled })));
};
export default TrackedItemTypeNameDropdown;
