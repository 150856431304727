var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Input } from "antd";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { trackedItemTypesListingRoute } from "../../../../../../../core/constants/routesPath";
import MovingAveragesAndProjections from "./MovingAveragesAndProjections";
import TrackedItemTypeBenchmark from "./trackedItemTypeBenchmark";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import localize from "../../../../../../../core/utils/translation-files";
import { TrackedItemVariables } from "./trackedItemVariables";
import { inputDefaultLength, maxTextAreaLength, } from "../../../../../../../core/utils/default-length";
import UsePrintPage from "../../../../../../../core/hooks/usePrintPage";
import { isPositiveValue } from "../../../../../../../core/validations/valuesComparison";
import PromptComponent from "../../../../../../../layout/components/prompt-message";
import isEmpty, { arePropertiesEmpty, } from "../../../../../../../core/helpers/isEmpty";
import FormActionButtons from "../../../../../../../layout/components/common/FormsActionButtons";
import { useHistory } from "react-router-dom";
import Asterik from "../../../../../shared/helper-components/Asterik";
import Relations from "./relations";
import { BenchmarkDirectionType } from "../../../../../../../core/enums/benchmarkDirection";
import { TrackedItemTypeApi } from "../../../../../../../core/api/trackedItemTypeApi";
import SingleSelectNew from "../../../../../../../layout/components/select/SingleSelectNew";
import { ReportsApi } from "../../../../../../../core/api/reportsApi";
import AreaLoader from "../../../../../../../layout/components/loading/areaLoader";
const TrackedItemTypeForm = ({ intialTrackedItemType, onSubmit, trackedItemTypes, benchmarkListOptions, isSystemCreated, isSystemCreatedEdit, categoryOptions, categories, bandScales, setBandScales, isHidden, }) => {
    const [key, setKey] = useState(0);
    const [direction, setDirection] = useState(intialTrackedItemType.requiredBenchmarkDirection);
    const [existingBenchmarkError, setExistingBenchmarkError] = useState({
        0: "",
    });
    const [rangeErrors, setRangeErrors] = useState({});
    const [categoryData, setCategoryData] = useState();
    let validationShape = {
        type: Yup.string()
            .required(localize(["type", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        // .test(
        //   "check",
        //   localize([
        //     "TrackedItem.trackedItem",
        //     "TrackedItem.default",
        //     "alreadyExist!",
        //   ]),
        //   function (value) {
        //     return isSystemCreated
        //       ? true
        //       : isUnique(value, trackedItemTypes, intialTrackedItemType, "type");
        //   }
        // ),
        weight: Yup.number().required(localize(["TrackedItem.weight", "isRequired!"])),
        companyWeight: Yup.number().when("isSystemCreated", {
            is: !isSystemCreated,
            then: Yup.number().required(localize(["Company.company", "TrackedItem.weight", "isRequired!"])),
        }),
        groupWeight: Yup.number().when("isSystemCreated", {
            is: !isSystemCreated,
            then: Yup.number().required(localize(["Group.group", "TrackedItem.weight", "isRequired!"])),
        }),
        dataFrequency: Yup.number().required(localize(["TrackedItem.dataFrequency", "isRequired!"])),
        shortTermMovingAveragePeriod: Yup.number()
            .positive()
            .label(localize(["TrackedItem.shortTerm"]))
            .min(1)
            .required(localize([
            "TrackedItem.shortTerm",
            "TrackedItem.movingAverage",
            "isRequired!",
        ]))
            .when(["midTermMovingAverage"], (midTermMovingAverage, schema) => {
            if (midTermMovingAverage !== undefined) {
                return schema.max(midTermMovingAverage - 0.1, localize([
                    "TrackedItem.shortTerm",
                    "mustBe",
                    "lessThan",
                    "TrackedItem.midTerm",
                ]));
            }
        }),
        midTermMovingAverage: Yup.number()
            .positive()
            .label(localize(["TrackedItem.midTerm"]))
            .min(1)
            .required(localize(["TrackedItem.midTerm", "isRequired!"]))
            .when(["longTermMovingAverage"], (longTermMovingAverage, schema) => {
            if (longTermMovingAverage !== undefined) {
                return schema.max(longTermMovingAverage - 0.1, localize([
                    "TrackedItem.midTerm",
                    "mustBe",
                    "lessThan",
                    "TrackedItem.longTerm",
                ]));
            }
        }),
        longTermMovingAverage: Yup.number()
            .positive()
            .min(1)
            .label(localize(["TrackedItem.longTerm"]))
            .required(localize(["TrackedItem.longTerm", "isRequired!"])),
        projectionAverageUsed: Yup.number()
            .nullable()
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(0)
                .required(localize([
                "TrackedItem.projection",
                "TrackedItem.used",
                "isRequired!",
            ])),
        }),
        projectionPoint: Yup.number()
            .nullable()
            .label(localize(["TrackedItem.projection", "TrackedItem.point"]))
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(1)
                .required(localize([
                "TrackedItem.projection",
                "TrackedItem.point",
                "isRequired!",
            ])),
        }),
        reactionTime: Yup.number()
            .nullable()
            .label(localize(["TrackedItem.reactionTime"]))
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(1)
                .required(localize(["TrackedItem.reactionTime", "isRequired!"])),
        }),
        requiredBenchmarkDirection: Yup.number().required(localize([
            "TrackedItem.required",
            "TrackedItem.benchmark",
            "TrackedItem.Direction",
            "isRequired!",
        ])),
        name: Yup.string()
            .trim()
            .required(localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "name",
            "isRequired!",
        ]))
            // .matches(reportNameRegex.regex, reportNameRegex.message)
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        // .test(
        //   "check",
        //   localize([
        //     "TrackedItem.trackedItem",
        //     "TrackedItem.default",
        //     "name",
        //     "alreadyExist!",
        //   ]),
        //   function (value) {
        //     return isUnique(value, trackedItemTypes, intialTrackedItemType);
        //   }
        // ),
        description: Yup.string()
            .trim()
            .test("len", localize(["mustBe", "lessThan", `${maxTextAreaLength}`, "characters"]), (val) => (val ? val.length <= maxTextAreaLength : 0 < 1))
            .nullable(),
        format: Yup.number().required(localize(["TrackedItem.trackedItemFormat", "isRequired!"])),
        itemCategoryId: Yup.number().required(localize(["TrackedItem.category", "isRequired!"])),
        itemSubCategoryId: Yup.number()
            .when([], {
            is: !isSystemCreated && !isHidden,
            then: Yup.number().required(localize(["TrackedItem.subCategory", "isRequired!"])),
        })
            .nullable(),
        itemRiskLevelId: Yup.number().required(localize(["TrackedItem.levelOfRisk", "isRequired!"])),
        ratingScaleA: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
        ratingScaleB: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
        ratingScaleC: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
        ratingScaleD: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
        yaxisLabel: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        benchmarkList: direction === BenchmarkDirectionType.Middle
            ? Yup.array()
                .of(Yup.object({
                benchmarkCode: Yup.string().required(localize(["name", "isRequired!"])),
                gradeRanges: Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(7, localize([
                    "TrackedItem.needAtLeast",
                    "7",
                    "TrackedItem.grades",
                ])),
            }))
                .min(1, localize(["TrackedItem.needAtLeastOneBenchmark!"]))
            : Yup.array()
                .of(Yup.object({
                benchmarkCode: Yup.string().required(localize(["name", "isRequired!"])),
                gradeRanges: Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(4, localize([
                    "TrackedItem.needAtLeast",
                    "4",
                    "TrackedItem.grades",
                ])),
            }))
                .min(1, localize(["TrackedItem.needAtLeastOneBenchmark!"])),
        // associatedTrackedItems: Yup.array().of(
        //   Yup.object({
        //     itemName: Yup.string().required(
        //       localize(["TrackedItem.itemName", "isRequired!"])
        //     ),
        //   })
        // ),
        valueType: Yup.number().required(localize(["TrackedItem.valueType", "isRequired!"])),
        chartUnit: Yup.number().when("valueType", {
            is: (valueType) => valueType !== TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE,
            then: Yup.number().required(localize(["TrackedItem.label", "isRequired!"])),
        }),
        decimalPlaces: Yup.number().required(localize(["TrackedItem.decimalPlaces", "isRequired!"])),
        displayValue: Yup.number().required(localize(["TrackedItem.dispalyValue", "isRequired!"])),
    };
    const validationSchema = Yup.object().shape(validationShape
    //   , [
    //   "maxValue",
    //   "minValue",
    // ]
    );
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: intialTrackedItemType,
        onSubmit: (values, { setErrors, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const newValues = JSON.parse(JSON.stringify(values));
            const isValidName = yield validateName(values.name);
            const isValidType = isSystemCreated ? true : yield validateType(values.type);
            if (arePropertiesEmpty(existingBenchmarkError) &&
                arePropertiesEmpty(rangeErrors) &&
                isValidName &&
                isValidType) {
                if ((_a = newValues === null || newValues === void 0 ? void 0 : newValues.benchmarkList) === null || _a === void 0 ? void 0 : _a.length) {
                    newValues.benchmarkList = (_b = values.benchmarkList) === null || _b === void 0 ? void 0 : _b.map((benchmark) => {
                        const { gradeRanges } = benchmark, rest = __rest(benchmark, ["gradeRanges"]);
                        return rest;
                    });
                }
                if (values.projectionRequired === false) {
                    newValues.projectionAverageUsed = undefined;
                    newValues.projectionPoint = undefined;
                    newValues.reactionTime = undefined;
                }
                onSubmit(newValues, setErrors, resetForm);
            }
        }),
    });
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm, setFieldValue, dirty, setErrors, setValues, setFieldTouched, validateForm, } = formik;
    const [isFormDirty, setFormDirty] = useState(false);
    const [customError, setCustomError] = useState(undefined);
    const validateName = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const isEdited = isEmpty(intialTrackedItemType === null || intialTrackedItemType === void 0 ? void 0 : intialTrackedItemType.name)
            ? true
            : (intialTrackedItemType === null || intialTrackedItemType === void 0 ? void 0 : intialTrackedItemType.name.toLocaleLowerCase().trim()) !==
                (value === null || value === void 0 ? void 0 : value.toLocaleLowerCase().trim());
        const errorText = localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "name",
            "alreadyExist!",
        ]);
        if (!isEmpty(value) && value.length <= inputDefaultLength && isEdited) {
            const updatedValue = encodeURIComponent(value);
            const response = yield ReportsApi.isTrackedItemTypeNameUnique(updatedValue);
            if (response === true) {
                setFieldTouched("name", true);
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: errorText })));
                setErrors(Object.assign(Object.assign({}, errors), { name: errorText }));
                return false; // Name is not unique
            }
            else {
                setErrors(Object.assign(Object.assign({}, errors), { name: undefined }));
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: undefined })));
                return true; // Name is unique
            }
        }
        else {
            setErrors(Object.assign(Object.assign({}, errors), { name: undefined }));
            setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: undefined })));
            return true; // Valid when value is empty or unedited
        }
    });
    const validateType = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const errorText = localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "alreadyExist!",
        ]);
        const isEdited = isEmpty(intialTrackedItemType === null || intialTrackedItemType === void 0 ? void 0 : intialTrackedItemType.type)
            ? true
            : (intialTrackedItemType === null || intialTrackedItemType === void 0 ? void 0 : intialTrackedItemType.type.toLocaleLowerCase().trim()) !==
                (value === null || value === void 0 ? void 0 : value.toLocaleLowerCase().trim());
        if (!isEmpty(value) && value.length <= inputDefaultLength && isEdited) {
            const response = yield ReportsApi.isTrackedItemTypeUnique(value);
            if (response === true) {
                setFieldTouched("type", true);
                setErrors(Object.assign(Object.assign({}, errors), { type: errorText })); // Corrected for Formik
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { type: errorText })));
                return false; // Type is not unique
            }
            else {
                setErrors(Object.assign(Object.assign({}, errors), { type: undefined })); // Clear error for type
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { type: undefined })));
                return true; // Type is unique
            }
        }
        else {
            setErrors(Object.assign(Object.assign({}, errors), { type: undefined })); // Clear error when input is valid
            setCustomError((prev) => (Object.assign(Object.assign({}, prev), { type: undefined })));
            return true; // Valid when value is empty or unedited
        }
    });
    const isSaveHover = useRef(null);
    useEffect(() => {
        const saveButtonHover = (event) => {
            let drawerElement = document.querySelector(".formsFooter[data-test='siteFormComponent']");
            if (drawerElement &&
                !drawerElement.contains(event.target)) {
                isSaveHover.current = true;
            }
            else {
                isSaveHover.current = false;
            }
        };
        // Bind the event listener
        document.addEventListener("click", saveButtonHover);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("click", saveButtonHover);
        };
    }, []);
    const onValueTypeChange = (value) => {
        setValues(Object.assign(Object.assign({}, values), { valueType: value }));
    };
    const onWeightChange = (value) => {
        setValues(Object.assign(Object.assign({}, values), { weight: value }));
    };
    const history = useHistory();
    const { handlePrint, nodeRef } = UsePrintPage(false);
    const changeParentCategoryHandler = (res) => {
        const ratingScale = Object.entries(res)
            .map(([key, value]) => {
            if (key.includes("ratingScale"))
                return {
                    bandName: key[key.length - 1],
                    title: value,
                };
            else
                return null;
        })
            .filter((value) => !isEmpty(value));
        setBandScales(ratingScale);
        setCategoryData(res);
        setFieldValue("reportTypeFilter", !isEmpty(res === null || res === void 0 ? void 0 : res.reportTypeFilter) ? res === null || res === void 0 ? void 0 : res.reportTypeFilter : -1);
        setFieldValue("currencyFormat", (res === null || res === void 0 ? void 0 : res.currencyFormat) || false);
        setFieldValue("ratingScaleA", res === null || res === void 0 ? void 0 : res.ratingScaleA);
        setFieldValue("ratingScaleB", res === null || res === void 0 ? void 0 : res.ratingScaleB);
        setFieldValue("ratingScaleC", res === null || res === void 0 ? void 0 : res.ratingScaleC);
        setFieldValue("ratingScaleD", res === null || res === void 0 ? void 0 : res.ratingScaleD);
    };
    const OnChangeParentCategory = (value) => {
        var _a;
        setFieldValue("itemCategoryId", value);
        if (isSystemCreated) {
            errorStateClear();
            TrackedItemTypeApi.getCategoryDataforIndices(value).then((res) => {
                changeParentCategoryHandler(res);
            });
            (categories === null || categories === void 0 ? void 0 : categories.length) &&
                setFieldValue("name", (_a = categories === null || categories === void 0 ? void 0 : categories.filter(({ code }) => code === (value === null || value === void 0 ? void 0 : value.toString()))[0]) === null || _a === void 0 ? void 0 : _a.text);
            setFieldValue("itemSubCategoryId", -1);
            setFieldValue("type", -1);
            setFieldValue("disabled", true);
        }
        else {
            TrackedItemTypeApi.getCategoryDefaultData(value).then((res) => {
                changeParentCategoryHandler(res);
            });
            setFieldValue("itemSubCategoryId", isHidden ? -1 : null);
        }
    };
    const OnChangeSubcategory = (value, e) => {
        setFieldValue("itemSubCategoryId", value);
        if (isSystemCreated) {
            errorStateClear();
            setFieldValue("name", e.text);
            setFieldValue("disabled", false);
            setFieldValue("reportTypeFilter", !isEmpty(categoryData === null || categoryData === void 0 ? void 0 : categoryData.reportTypeFilter)
                ? categoryData === null || categoryData === void 0 ? void 0 : categoryData.reportTypeFilter
                : -1);
        }
    };
    const handlSetFieldValue = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;
        if (isEmpty(value)) {
            updatedValue = undefined;
        }
        else if (isPositiveValue(value)) {
            updatedValue =
                (value === null || value === void 0 ? void 0 : value.length) === String(Number(value)).length ? Number(value) : value;
        }
        if (name.includes(".")) {
            setFieldValue(name, updatedValue);
            return;
        }
        setValues((prev) => (Object.assign(Object.assign({}, prev), { [name]: updatedValue })));
    };
    useEffect(() => {
        if (values.id && !isSystemCreated && !isEmpty(values === null || values === void 0 ? void 0 : values.itemCategoryId)) {
            TrackedItemTypeApi.getCategoryDefaultData(Math.abs(values.itemCategoryId)).then((res) => {
                res && setCategoryData({ subCategories: res === null || res === void 0 ? void 0 : res.subCategories });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isDirty = useMemo(() => dirty || isFormDirty, [dirty, isFormDirty]);
    const submitForm = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const formErrors = yield validateForm(values);
        if (!isEmpty(formErrors)) {
            validateName(values.name);
            validateType(values.type);
        }
        handleSubmit(e);
    });
    const errorStateClear = () => {
        !isEmpty(customError === null || customError === void 0 ? void 0 : customError.name) && setCustomError(Object.assign(Object.assign({}, customError), { name: undefined }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormikProvider, { value: formik },
            React.createElement(PromptComponent, { isPrompt: isDirty }),
            React.createElement("form", { "data-test": "tracked-item-type-form", className: "mb-1 customForm", name: "basic", onSubmit: submitForm, key: key, ref: nodeRef, id: "my-form" },
                React.createElement(Input, { name: "id", type: "hidden" }),
                React.createElement("div", { className: "box-main-section" },
                    React.createElement("section", { className: "box-section" },
                        React.createElement("div", { className: "box-title" },
                            React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.trackedItem", "information"]))),
                        React.createElement("div", { className: "box-description" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit || isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.apiId"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(Input, { type: "text", name: "type", placeholder: localize(["TrackedItem.apiId"]), onBlur: (e) => { handleBlur(e); !isSystemCreated && isSaveHover.current === true && validateType(e.target.value); }, 
                                            // onChange={handleChange}
                                            onChange: (e) => { handleChange(e); !isEmpty(customError.type) && setCustomError(Object.assign(Object.assign({}, customError), { type: undefined })); }, value: values.type, className: `${((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.type) &&
                                                (customError === null || customError === void 0 ? void 0 : customError.type) !== "") ||
                                                errors.type) &&
                                                touched.type
                                                ? "input-error"
                                                : null}`, suffix: React.createElement(AreaLoader, { wrapperClassName: "ti-name-check-loader", area: "trackedItemTypeCheck" }) }),
                                        ((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.type) &&
                                            (customError === null || customError === void 0 ? void 0 : customError.type) !== "") ||
                                            errors.type) &&
                                            touched.type && (React.createElement("p", { className: "error" }, errors.type || (customError === null || customError === void 0 ? void 0 : customError.type))))),
                                React.createElement("div", { className: "col-md-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["name"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(Input, { type: "text", name: "name", placeholder: localize(["name"]), onBlur: (e) => { handleBlur(e); isSaveHover.current === true && validateName(e.target.value); }, 
                                            // onChange={handleChange}
                                            onChange: (e) => { handleChange(e); ; errorStateClear(); }, value: values.name, className: `${((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.name) &&
                                                (customError === null || customError === void 0 ? void 0 : customError.name) !== "") ||
                                                errors.name) &&
                                                touched.name
                                                ? "input-error"
                                                : null}`, disabled: isSystemCreatedEdit, suffix: React.createElement(AreaLoader, { wrapperClassName: "ti-name-check-loader", area: "trackedItemNameCheck" }) }),
                                        ((!isEmpty(customError === null || customError === void 0 ? void 0 : customError.name) &&
                                            (customError === null || customError === void 0 ? void 0 : customError.name) !== "") ||
                                            errors.name) &&
                                            touched.name && (React.createElement("p", { className: "error" }, errors.name || (customError === null || customError === void 0 ? void 0 : customError.name))))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreatedEdit },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.format"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(LookupDropDown, { allowClear: false, className: "mb-2 mb-md-0", selectedValue: values.format, onChange: setFieldValue, inputName: "format", localizationKey: ["TrackedItem.format"], lookUpType: LookupNames.LKReportType, error: errors.format && touched.format ? errors.format : null }))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreatedEdit },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.category"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        isSystemCreated ? (React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.itemCategoryId, customOnChangeHandler: OnChangeParentCategory, localizationKey: ["TrackedItem.category"], lookUpType: LookupNames.LKItemCategory })) : (React.createElement(SingleSelectNew, { allowClear: false, "data-test": "category-dafault-dropdown", options: categoryOptions || [], selectedValue: !isEmpty(values.itemCategoryId)
                                                ? values.itemCategoryId.toString()
                                                : values.itemCategoryId, onChange: OnChangeParentCategory, valueProperty: "code", textProperty: "text", placeholder: "Select a Category", key: "categoryDefaultSelect", className: `mb-2 mb-md-0 ${errors.itemCategoryId && touched.itemCategoryId
                                                ? "input-error"
                                                : null}` })),
                                        errors.itemCategoryId && touched.itemCategoryId && (React.createElement("p", { className: "error" }, errors.itemCategoryId)))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreatedEdit || isHidden },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.subCategory"]),
                                                " ",
                                                !isSystemCreated && React.createElement(Asterik, null))),
                                        React.createElement(SingleSelectNew, { allowClear: isSystemCreated ? true : false, "data-test": "subcategory-dafault-dropdown", options: (categoryData === null || categoryData === void 0 ? void 0 : categoryData.subCategories) || [], selectedValue: values.itemSubCategoryId === -1
                                                ? undefined
                                                : !isEmpty(values.itemSubCategoryId)
                                                    ? values.itemSubCategoryId.toString()
                                                    : values.itemSubCategoryId, onChange: OnChangeSubcategory, valueProperty: "code", textProperty: "text", placeholder: "Select a Subcategory", key: "subcategoryDefaultSelect", className: `mb-2 mb-md-0 ${errors.itemSubCategoryId && touched.itemSubCategoryId
                                                ? "input-error"
                                                : null}` }),
                                        errors.itemSubCategoryId &&
                                            touched.itemSubCategoryId && (React.createElement("p", { className: "error" }, errors.itemSubCategoryId)))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.levelOfRisk"]))),
                                        React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.itemRiskLevelId, onChange: setFieldValue, inputName: "itemRiskLevelId", localizationKey: ["TrackedItem.levelOfRisk"], lookUpType: LookupNames.LKItemRiskLevel, error: errors.itemRiskLevelId && touched.itemRiskLevelId
                                                ? errors.itemRiskLevelId
                                                : null }))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.reportType"]))),
                                        React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.reportTypeFilter === -1
                                                ? undefined
                                                : values.reportTypeFilter, onChange: setFieldValue, inputName: "reportTypeFilter", localizationKey: ["TrackedItem.reportType"], lookUpType: LookupNames.LKReportsTypeFilter, disabled: isEmpty(values.itemCategoryId) ||
                                                values.itemSubCategoryId !== -1 ||
                                                !isSystemCreated, error: errors.reportTypeFilter && touched.reportTypeFilter
                                                ? errors.reportTypeFilter
                                                : null }))),
                                React.createElement("div", { className: "col-md-6 descriptionField", hidden: isSystemCreatedEdit },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.trackedItem", "description"]))),
                                        React.createElement(TextArea, { name: "description", placeholder: localize(["description"]), onBlur: handleBlur, onChange: handleChange, value: values.description, className: `${errors.description && touched.description
                                                ? "input-error"
                                                : null}` }),
                                        errors.description && touched.description && (React.createElement("p", { className: "error" }, errors.description))))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: values.id === 0 ? "form-group" : "form-group d-none" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null, localize(["TrackedItem.disabled"]))),
                                    React.createElement(Checkbox, { disabled: isSystemCreated
                                            ? isEmpty(values.itemCategoryId)
                                                ? true
                                                : values.itemSubCategoryId === -1
                                            : false, name: "disabled", checked: values.disabled, onChange: handleChange })))))),
                React.createElement(MovingAveragesAndProjections, { handlSetFieldValue: handlSetFieldValue, setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, isSystemCreated: isSystemCreatedEdit }),
                React.createElement(TrackedItemVariables, { handlSetFieldValue: handlSetFieldValue, setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, isSystemCreatedEdit: isSystemCreatedEdit, isSystemCreated: isSystemCreated, onValueTypeChange: onValueTypeChange, onWeightChange: onWeightChange }),
                !isSystemCreatedEdit && (React.createElement(Relations, { relationsList: values.associatedTrackedItems, setFieldValue: setFieldValue, type: values.type, frequency: values.dataFrequency, setFormDirty: setFormDirty })),
                React.createElement(TrackedItemTypeBenchmark, { setFieldValue: setFieldValue, handlSetFieldValue: handlSetFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, benchmarkListOptions: benchmarkListOptions, setErrors: setErrors, setExistingBenchmarkError: setExistingBenchmarkError, existingBenchmarkError: existingBenchmarkError, setDirection: setDirection, isSystemCreated: isSystemCreatedEdit, rangeErrors: rangeErrors, setRangeErrors: setRangeErrors, bandScales: bandScales })),
            React.createElement(FormActionButtons, { onCancel: () => history.push(trackedItemTypesListingRoute), fromId: "my-form", isEdit: !isDirty || intialTrackedItemType.id > 0, isDirty: !isDirty, resetForm: resetForm, setKey: setKey, printOnClick: handlePrint, dataTest: "siteFormComponent" }))));
};
export default TrackedItemTypeForm;
