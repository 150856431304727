var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Switch } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import isActionAllowed from "../../../../../../../core/authorization/userPermissions";
import { REPORT_EDIT } from "../../../../../../../core/constants/permissionConstants";
import { ResponseStatusCodes } from "../../../../../../../core/constants/responseStatusCodes";
import { editTrackedItemsRoute, viewTrackedItemChartRoute, } from "../../../../../../../core/constants/routesPath";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import UseCustomPagination from "../../../../../../../layout/components/hooks/useCustomPagination";
import { notifications } from "../../../../../../../layout/components/notifications";
import { inActivateTrackedItem, activateTrackedItem, getUserCreatedTrackedItems, getHiddenTrackedItems } from "../../../../../../../redux/trackedItem/actions";
import UserCreatedtrackedItemTable from "./components/userCreatedtrackedItemTable";
import usePermissions from "../../../../../../../core/hooks/usePermissions";
const UserCreatedTrackedItemsListingContainer = ({ filters, inActivateTrackedItem, activateTrackedItem, getUserCreatedTrackedItems, isSubmitAction, getHiddenTrackedItems, setIsSubmitAction, isPrintMode, isHidden }) => {
    var _a;
    const history = useHistory();
    const { isSiteAdmin } = usePermissions();
    const [trackedItems, setTrackedItems] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const isFirstRender = useRef(true);
    const _b = UseCustomPagination({
        totalRecords: (_a = trackedItems === null || trackedItems === void 0 ? void 0 : trackedItems.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const handleGetTrackItem = useCallback(() => {
        const { groupId, companyId, siteId } = filters;
        // setTrackedItems([]);
        if (isEmpty(siteId) && isSiteAdmin)
            return;
        if (!isEmpty(groupId)) {
            const apiCall = isHidden ? getHiddenTrackedItems : getUserCreatedTrackedItems;
            apiCall(groupId, companyId !== null && companyId !== void 0 ? companyId : -1, siteId !== null && siteId !== void 0 ? siteId : -1).then((response) => {
                var _a;
                if (!isEmpty(response)) {
                    setTrackedItems((_a = response[0]) === null || _a === void 0 ? void 0 : _a.data);
                    setTotalData(response[0]);
                }
                else {
                    setTrackedItems([]);
                    setTotalData([]);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    useEffect(() => {
        if ((isFirstRender.current || isSubmitAction) && !isEmpty(filters)) {
            handleGetTrackItem();
            isFirstRender.current = false;
            setIsSubmitAction(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleGetTrackItem, filters, isSubmitAction]);
    const inActivateTrackedItemHandler = (data) => {
        inActivateTrackedItem(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                handleGetTrackItem();
                notifications.success(localize(["TrackedItem.trackeditem", "disabled", "successfully"]));
            }
        });
    };
    const activateTrackedItemHandler = (data) => {
        activateTrackedItem(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                handleGetTrackItem();
                notifications.success(localize(["TrackedItem.trackeditem", "enabled", "successfully"]));
            }
        });
    };
    const editTrackedItemRedirect = (trackedItem) => {
        history.push(editTrackedItemsRoute + trackedItem.id, {
            trackedItemId: trackedItem.id,
        });
    };
    const viewTrackedItemChart = (trackedItem) => {
        history.push(viewTrackedItemChartRoute + trackedItem.id, {
            trackedItemId: trackedItem.id,
        });
    };
    const trackedItemActions = (trackedItem) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "TrackedItem.trackedItem"],
                onClick: editTrackedItemRedirect.bind({}, trackedItem),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([REPORT_EDIT]),
            },
            {
                priority: 1,
                title: trackedItem.disabled === true
                    ? ["unArchive", "TrackedItem.trackedItem"]
                    : ["archive", "TrackedItem.trackedItem"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${trackedItem.disabled === true ? ["unarchive"] : ["Archive"]}`,
                    "TrackedItem.thisTrackedItem?",
                ],
                // isAllowed: isActionAllowed([REPORT_CREATION]),
                onConfirm: trackedItem.disabled === true
                    ? activateTrackedItemHandler.bind({}, trackedItem.id)
                    : inActivateTrackedItemHandler.bind({}, trackedItem.id),
                disbaled: !trackedItem.belongsToHoldingCompany,
                children: (React.createElement(Switch, { disabled: !trackedItem.belongsToHoldingCompany, checked: trackedItem.disabled === false, className: `ml-1 ${!trackedItem.belongsToHoldingCompany ? `disableSwitch` : ``}` })),
            },
            {
                priority: 3,
                title: ["view", "chart"],
                onClick: viewTrackedItemChart.bind({}, trackedItem),
                disbaled: trackedItem.disabled === true,
                svgIcon: "TrackItemChart",
                iconStyle: { color: "#EA5455" },
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "row", style: { marginBottom: "5px" } },
            React.createElement("div", { className: "col-lg-6 col-md-4" }),
            React.createElement("div", { className: "col-lg-6 col-md-6" },
                React.createElement("div", { className: "row", style: { justifyContent: "flex-end" } },
                    React.createElement("div", { className: "col-sm-1" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { className: "indicator online darkGreen" }),
                            " \u00A0",
                            " ",
                            React.createElement("span", { style: { fontWeight: "bold" } }, totalData.darkGreen))),
                    React.createElement("div", { className: "col-sm-1" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { className: "indicator online green" }),
                            " \u00A0",
                            " ",
                            React.createElement("span", { style: { fontWeight: "bold" } }, totalData.green))),
                    React.createElement("div", { className: "col-sm-1" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { className: "indicator online amber" }),
                            " \u00A0",
                            " ",
                            React.createElement("span", { style: { fontWeight: "bold" } }, totalData.amber))),
                    React.createElement("div", { className: "col-sm-1" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { className: "indicator online red" }),
                            " \u00A0",
                            " ",
                            React.createElement("span", { style: { fontWeight: "bold" } }, totalData.red))),
                    React.createElement("div", { className: "col-sm-1" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { className: "indicator online grey" }),
                            " \u00A0",
                            " ",
                            React.createElement("span", { style: { fontWeight: "bold" } }, totalData.grey))),
                    React.createElement("div", { className: "col-sm-4" },
                        React.createElement("div", { className: "rating-lable" },
                            React.createElement("span", { style: { fontWeight: "bold" } },
                                "Total Enabled Tracked Items : ",
                                totalData.totalEnabledReports)))))),
        React.createElement(UserCreatedtrackedItemTable, { data: trackedItems, filters: filters, actions: trackedItemActions, paginationProps: restPaginationProps, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    inActivateTrackedItem,
    activateTrackedItem,
    getUserCreatedTrackedItems,
    getHiddenTrackedItems
};
UserCreatedTrackedItemsListingContainer.propTypes = {};
export default connect(mapStateTopProps, mapDispatchToProps)(UserCreatedTrackedItemsListingContainer);
