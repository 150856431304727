import { httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "report";
export class TrackedItemApi {
    static getPiToken() {
        return httpGet(`${controllerEndpoint}/piuser/auth`);
    }
    static getTrackedItemId(type, siteId) {
        return httpGet(`${controllerEndpoint}/getReportId/${type}/${siteId}`);
    }
    static getUserCreatedTrackedItems(groupId, companyId = -1, siteId = -1) {
        return httpGet(`${controllerEndpoint}/userCreatedTrackedItems/${groupId}/${companyId}/${siteId}`);
    }
    static getHiddenTrackedItems(groupId, companyId = -1, siteId = -1) {
        return httpGet(`${controllerEndpoint}/listHiddenTrackedItems/${groupId}/${companyId}/${siteId}`);
    }
    static getSystemCreatedTrackedItems(groupId, companyId = -1, siteId = -1) {
        return httpGet(`${controllerEndpoint}/systemCreatedTrackedItems/${groupId}/${companyId}/${siteId}`);
    }
    static getTrackedItems() {
        return httpGet(`${controllerEndpoint}/list`);
    }
    static getSingleTrackedItem(id) {
        return httpGet(`${controllerEndpoint}/${id}`);
    }
    static getTrackedItemsPerSite() {
        return httpGet(`${controllerEndpoint}/listReportAggregates`);
    }
    static getAssociatedTrackItemByDefaults(reportTypeId, siteId) {
        return httpGet(`${controllerEndpoint}/getAssociationsforTrackedItems/${reportTypeId}/${siteId}`);
    }
    static getTrackedItemsForTiles(data) {
        return httpGet(`${controllerEndpoint}/list/active/${data}`);
    }
    static getTrackedItemSignalCount(data) {
        return httpGet(`${controllerEndpoint}/statusCount/${data}`);
    }
    static getTrackedItemTypesListing() {
        return httpGet(`${controllerEndpoint}/listReportTypes`);
    }
    static getSiteStatus(data) {
        return httpPost(`${controllerEndpoint}/siteStatus`, {
            body: data,
        });
    }
    static createTrackedItem(data) {
        return httpPost(`${controllerEndpoint}`, {
            body: data,
        });
    }
    static updateTrackedItem(data) {
        return httpPut(`${controllerEndpoint}`, {
            body: data,
        });
    }
    static getOverViewTrackedItemData(data) {
        return httpGet(`${controllerEndpoint}/dashboardData/${data}`);
    }
    static getTrackedItemScale() {
        return httpGet(`${controllerEndpoint}/getReportScale`);
    }
    static getTrackedItemScaleById(id) {
        return httpGet(`${controllerEndpoint}/getReportScale/${id}`);
    }
    static inActivateTrackedItem(id) {
        return httpPost(`${controllerEndpoint}/disable/${id}`);
    }
    static activateTrackedItem(id) {
        return httpPost(`${controllerEndpoint}/enable/${id}`);
    }
    static getTrackedItemsByCompanyIdAndCategorySubCategory(siteId, subcategoryId, categoryId) {
        return httpGet(`${controllerEndpoint}/getTrackedItemsBySubCategory/${siteId}/${subcategoryId}/${categoryId}`);
    }
    static getReportsConfiguredCategories(groupCompanySiteId) {
        return httpGet(`${controllerEndpoint}/getReportsConfiguredCategories/${groupCompanySiteId}`);
    }
}
