import React from "react";
import isEmpty from "../../../../../core/helpers/isEmpty";
import localize from "../../../../../core/utils/translation-files";
import HistoricalChart from "../../../../../layout/components/rechart/tiny-chart/HistoricalChart";
import WatchlistPredictionChart from "../../../../../layout/components/rechart/tiny-chart/WatchlistPredictionChart";
import ActionsContainer from "../../../admin/watchlist/components/watchlistTable/components/ActionsContainer";
import WatchlistRatingColumnComponent from "../../../admin/watchlist/components/watchlistTable/components/WatchlistRatingColumnComponent";
import RatingAccuracy from "./ratingAccuracy";
function OverallStatusPanels({ data, selectedFrequencyLabel = null, openNotificationModal, isChart = false, currencyFormat = false, showAllCards = false, className = "", }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${className} reports__overall-status ` },
            React.createElement("div", { className: "reports__overall-status-innerBox", style: { width: "22%" }, "data-test": "overall-rating-status" },
                React.createElement("p", { className: "ratingAccuracy", style: { alignItems: "center" } },
                    React.createElement("span", null, localize(["Dashboard.rating"])),
                    !isEmpty(data === null || data === void 0 ? void 0 : data.ratingAccuracy) && React.createElement(RatingAccuracy, { data: data })),
                data && (React.createElement(WatchlistRatingColumnComponent, { data: data, showAllCards: showAllCards, currencyFormat: currencyFormat, cardText: (data === null || data === void 0 ? void 0 : data.hierarchy) ? `${data.hierarchy} Value` : "" }))),
            React.createElement("div", { className: "reports__overall-status-innerBox watchlistChartColum", style: { width: "32%" }, "data-test": "overall-status-history", hidden: isChart },
                React.createElement("p", null, localize(["Dashboard.historicalPerformance"])),
                data && !isChart ? (React.createElement(HistoricalChart, { data: data, frequency: +(selectedFrequencyLabel === null || selectedFrequencyLabel === void 0 ? void 0 : selectedFrequencyLabel.code), isDashboard: true, isListing: false, weight: data === null || data === void 0 ? void 0 : data.weight, weightFlag: data === null || data === void 0 ? void 0 : data.weightFlag })) : (React.createElement("div", { className: "content-center-aligned no-data" }, "No Data"))),
            React.createElement("div", { className: "reports__overall-status-innerBox", style: { width: "30%" }, "data-test": "overall-status-projection-level" },
                React.createElement("p", null, localize(["Dashboard.ratingPrediction"])),
                data && (React.createElement(WatchlistPredictionChart, { row: data, isListing: false, currencyFormat: currencyFormat, weight: data === null || data === void 0 ? void 0 : data.weight, weightFlag: data === null || data === void 0 ? void 0 : data.weightFlag }))),
            React.createElement("div", { className: "reports__overall-status-innerBox", style: { width: "15%" }, "data-test": "overall-status-critical-notifications" },
                React.createElement("p", null,
                    React.createElement("span", null, localize(["Dashboard.criticalNotifications"]))),
                React.createElement("div", { className: "content-center-aligned", style: { height: "66%" } }, data && (React.createElement(ActionsContainer, { data: data, openMessageModal: (e) => openNotificationModal(data, true) })))))));
}
export default OverallStatusPanels;
