import { httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "report";
export class TrackedItemTypeApi {
    static getTrackedItemTypes() {
        return httpGet(`${controllerEndpoint}/listUserCreatedReportTypes`);
    }
    static getDefaultsByFrequency(frequency, type) {
        return httpGet(`${controllerEndpoint}/typesByFrequency/${frequency}/${type}`);
    }
    static getSystemCreatedTrackedItemTypes() {
        return httpGet(`${controllerEndpoint}/listSystemCreatedReportTypes`);
    }
    static getHiddenTrackedItemTypes() {
        return httpGet(`${controllerEndpoint}/listHiddenReportTypes`);
    }
    static createTrackedItemType(data) {
        return httpPost(`${controllerEndpoint}/createReportType`, {
            body: data,
        });
    }
    static updateTrackedItemType(data) {
        return httpPut(`${controllerEndpoint}/updateReportType`, {
            body: data,
        });
    }
    static activateTrackedItemType(id) {
        return httpPost(`${controllerEndpoint}/enableReportType/${id}`);
    }
    static inActivateTrackedItemType(id) {
        return httpPost(`${controllerEndpoint}/disableReportType/${id}`);
    }
    static getTrackedItemTypeBySite(siteId, systemCreated, isHidden) {
        return httpGet(`${controllerEndpoint}/filteredTypes/${siteId}/${systemCreated}/${isHidden}`);
    }
    static getCategoryDataforIndices(categoryId) {
        return httpGet(`${controllerEndpoint}/getCategoryDefaultData/${categoryId}`);
    }
    static getCategoryDefaultData(categoryId) {
        return httpGet(`${controllerEndpoint}/getCategoryDataAndCreatedSubCategories/${categoryId}`);
    }
    static getCategoryDefaults() {
        return httpGet(`${controllerEndpoint}/getAllCreatedCategoryDefaults`);
    }
}
