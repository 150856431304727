import { GET_TRACKED_ITEM_TYPES_SUCCESS, GET_SYSTEM_CREATED_TRACKED_ITEM_TYPES_SUCCESS, ADD_TRACKED_ITEM_TYPE_SUCCESS, UPDATE_TRACKED_ITEM_TYPE_SUCCESS, INACTIVATE_TRACKED_ITEM_TYPE_SUCCESS, ACTIVATE_TRACKED_ITEM_TYPE_SUCCESS, GET_TRACKED_ITEM_TYPE_BY_SITE_SUCCESS, GET_HIDDEN_TRACKED_ITEM_TYPES_SUCCESS, } from "./types";
import { TrackedItemTypeApi } from "../../core/api/trackedItemTypeApi";
export const getTrackedItemTypesSuccess = (data) => ({
    type: GET_TRACKED_ITEM_TYPES_SUCCESS,
    payload: data,
});
export const getSystemCreatedTrackedItemTypesSuccess = (data) => ({
    type: GET_SYSTEM_CREATED_TRACKED_ITEM_TYPES_SUCCESS,
    payload: data,
});
export const addTrackedItemTypeSuccess = (data) => ({
    type: ADD_TRACKED_ITEM_TYPE_SUCCESS,
    payload: data,
});
export const getHiddenTrackedItemTypesSuccess = (data) => ({
    type: GET_HIDDEN_TRACKED_ITEM_TYPES_SUCCESS,
    payload: data,
});
export const updateTrackedItemTypeSuccess = (data) => ({
    type: UPDATE_TRACKED_ITEM_TYPE_SUCCESS,
    payload: data,
});
export const inActivateTrackedItemTypeSuccess = (data) => ({
    type: INACTIVATE_TRACKED_ITEM_TYPE_SUCCESS,
    payload: data,
});
export const activateTrackedItemTypeSuccess = (data) => ({
    type: ACTIVATE_TRACKED_ITEM_TYPE_SUCCESS,
    payload: data,
});
export const getTrackedItemTypeBySiteSuccess = (data) => ({
    type: GET_TRACKED_ITEM_TYPE_BY_SITE_SUCCESS,
    payload: data,
});
export function getTrackedItemTypesBySite(siteId, systemCreated, isHidden) {
    return function (dispatch) {
        return TrackedItemTypeApi.getTrackedItemTypeBySite(siteId, systemCreated, isHidden)
            .then((trackedItemTypes) => {
            if ((trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.length) > 0) {
                dispatch(getTrackedItemTypeBySiteSuccess(trackedItemTypes));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getTrackedItemTypes(companyId) {
    return function (dispatch) {
        return TrackedItemTypeApi.getTrackedItemTypes()
            .then((trackedItemTypes) => {
            if ((trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.length) > 0) {
                dispatch(getTrackedItemTypesSuccess(trackedItemTypes));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getSystemCreatedTrackedItemTypes() {
    return function (dispatch) {
        return TrackedItemTypeApi.getSystemCreatedTrackedItemTypes()
            .then((trackedItemTypes) => {
            if ((trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.length) > 0) {
                dispatch(getSystemCreatedTrackedItemTypesSuccess(trackedItemTypes));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getHiddenTrackedItemTypes() {
    return function (dispatch) {
        return TrackedItemTypeApi.getHiddenTrackedItemTypes()
            .then((trackedItemTypes) => {
            if ((trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.length) > 0) {
                dispatch(getHiddenTrackedItemTypesSuccess(trackedItemTypes));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addTrackedItemType(user) {
    return function (dispatch) {
        return TrackedItemTypeApi.createTrackedItemType(user)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(addTrackedItemTypeSuccess(response));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editTrackedItemType(user) {
    return function (dispatch) {
        return TrackedItemTypeApi.updateTrackedItemType(user)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(updateTrackedItemTypeSuccess(response));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function inActivateTrackedItemType(data) {
    return function (dispatch) {
        return TrackedItemTypeApi.inActivateTrackedItemType(data === null || data === void 0 ? void 0 : data.id)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(inActivateTrackedItemTypeSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function activateTrackedItemType(data) {
    return function (dispatch) {
        return TrackedItemTypeApi.activateTrackedItemType(data === null || data === void 0 ? void 0 : data.id)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(activateTrackedItemTypeSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
