var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AddLookup from "./components/addLookup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notifications } from "../../../../layout/components/notifications";
import EditLookup from "./components/editLookup";
import { LookupTable } from "./components/lookupTable";
import { LookupNames, LookupTypes } from "../../../../core/constants/lookup";
import { addLookup, editLookup, getLookup, deleteLookup, getLookupTypes, } from "../../../../redux/lookup/actions";
import LookupTypesDropdownContainer from "../../../../layout/components/select/LookupTypesDropdownContainer";
import { SessionStorage } from "../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../core/constants/SessionVariables";
import { ResponseStatusCodes } from "../../../../core/constants/responseStatusCodes";
import localize from "../../../../core/utils/translation-files";
import UsePrintPage from "../../../../core/hooks/usePrintPage";
import TableActions from "../../../../layout/components/customTable/tableActions";
import { lookupFormInitialValues } from "./defaultValues";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { LOOKUP_CREATION, LOOKUP_VIEW, } from "../../../../core/constants/permissionConstants";
import PrintButton from "../../../../layout/components/common/PrintButton";
import Header from "../../../../layout/components/common/header";
import UseCustomPagination from "../../../../layout/components/hooks/useCustomPagination";
import ListingSearch from "../../../../layout/components/listingSearch";
const LookupContainer = ({ lookups, editLookup, getLookup, addLookup, deleteLookup, getLookupTypes, isPrintMode, }) => {
    var _a, _b, _c;
    const sessionLookupType = SessionStorage.getKey(SessionVariables.SELECTED_LOOKUP_TYPE);
    const lookuptype = sessionLookupType !== "undefined" && sessionLookupType !== null
        ? sessionLookupType
        : {
            label: LookupNames.LKNucleusModule,
            value: 1,
        };
    const lookupTypeParentName = SessionStorage.getKey(SessionVariables.SELECTED_LOOKUP_TYPE_PARENT_NAME);
    const [addingModalVisibility, setAddingModalVisibility] = useState(false);
    const [selectedLookup, setSelectedLookup] = useState(null);
    const [initialLookup, setInitialLookup] = useState(lookupFormInitialValues);
    const [selectedLookupType, setSelectedLookupType] = useState(lookuptype);
    const [selectedParentLookupTypeName, setSelectedParentLookupTypeName] = useState(lookupTypeParentName);
    const valuesRef = useRef(null);
    const _d = UseCustomPagination({
        totalRecords: (_b = (_a = lookups[selectedLookupType.label]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _d, restPaginationProps = __rest(_d, ["handleCustomTablePropChange"]);
    const openAddingModal = () => {
        selectCodeType(setInitialLookup, true);
        setAddingModalVisibility(true);
    };
    const openEditModal = (lookup) => {
        setSelectedLookup(lookup);
        selectCodeType(setSelectedLookup, false);
    };
    const selectCodeType = (setlookupState, isAdd) => {
        const lookup = lookups[LookupTypes].find((x) => x.id === selectedLookupType.value);
        if (isAdd) {
            setlookupState((exsitingLookup) => (Object.assign(Object.assign({}, exsitingLookup), { codeType: lookup.codeDataType, lookupid: lookup.id })));
        }
        else {
            setlookupState((exsitingLookup) => (Object.assign(Object.assign({}, exsitingLookup), { codeType: lookup.codeDataType })));
        }
    };
    useEffect(() => {
        var _a, _b;
        if (lookups[LookupTypes] === undefined ||
            ((_a = lookups[LookupTypes]) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            getLookupTypes();
        }
        if (selectedLookupType.label !== "" &&
            (lookups[selectedLookupType.label] === undefined ||
                ((_b = lookups[selectedLookupType.label]) === null || _b === void 0 ? void 0 : _b.length) === 0)) {
            getLookup(selectedLookupType.label).catch((error) => {
                throw error;
            });
        }
        if (selectedLookupType.label === "") {
            setSelectedLookupType({
                label: LookupNames.LKNucleusModule,
                value: 1,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLookupType]);
    const onLookupTypeChange = (value) => {
        var _a, _b;
        const lookupValue = Object.assign({}, value);
        lookupValue.label = (_b = (_a = value === null || value === void 0 ? void 0 : value.label) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.title;
        SessionStorage.setKey(SessionVariables.SELECTED_LOOKUP_TYPE, lookupValue);
        setSelectedLookupType(lookupValue);
        setLookupParentName(value);
    };
    const setLookupParentName = (value) => {
        // check if selected looktype has parent value attached or not.
        const lookupParentId = lookups.LookupTypes.find((lk) => lk.id === value.value).lookupParentId;
        // if parent lookup id found, then find it's name
        let parentName = null;
        if (lookupParentId) {
            parentName = lookups.LookupTypes.find((lk) => lk.id === lookupParentId).name;
        }
        setSelectedParentLookupTypeName(parentName);
        SessionStorage.setKey(SessionVariables.SELECTED_LOOKUP_TYPE_PARENT_NAME, parentName);
    };
    const closeAddingModal = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setAddingModalVisibility(false);
        }
    };
    const addLookupHandler = (lookup, resetForm, setErrors) => {
        addLookup(lookup, lookuptype.label).then((response) => {
            if ((response.code === ResponseStatusCodes.VALIDATION_ERROR ||
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) &&
                response.hasOwnProperty("message")) {
                notifications.error(response.message);
                // setErrors({ code: response.message });
            }
            else {
                resetForm();
                setAddingModalVisibility(false);
                notifications.success(localize(["Lookup.lookup", "added", "successfully"]));
            }
        });
    };
    const closeModal = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setSelectedLookup(null);
        }
    };
    const editLookupHandler = (lookup, resetForm) => {
        editLookup(lookup, lookuptype.label).then((response) => {
            if ((response.code === ResponseStatusCodes.VALIDATION_ERROR ||
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) &&
                response.hasOwnProperty("message")) {
                notifications.error(response.message);
                // setErrors({ code: response.message });
            }
            else {
                resetForm();
                closeModal();
                setAddingModalVisibility(false);
                notifications.success(localize(["Lookup.lookup", "updated", "successfully"]));
            }
        });
    };
    // const deleteLookupHandler = (data) => {
    //   deleteLookup(data, lookuptype.label).then((response) => {
    //     if (!response?.code && !response?.message) {
    //       notifications.success(t("Lookup Deleted Successfully"));
    //     }
    //   });
    // };
    const lookupsActions = (lookup) => {
        const actionArray = [
            {
                priority: 1,
                title: ["edit", "Lookup.lookupValue"],
                onClick: openEditModal.bind({}, lookup),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([LOOKUP_VIEW]),
            },
        ];
        // <Popconfirm
        //       disabled={lookup.systemCreated === true}
        //       placement="top"
        //       title={t("Are you sure to delete this lookup?")}
        //       onConfirm={deleteLookupHandler.bind({}, lookup.id)}
        //       okText="Yes"
        //       cancelText="No"
        //     >
        //       <Button
        //         disabled={lookup.systemCreated === true}
        //         shape="circle"
        //         danger
        //       >
        //         <span className="icofont icofont-ui-delete" />
        //       </Button>
        //     </Popconfirm>
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    const { handlePrint, nodeRef } = UsePrintPage();
    return (React.createElement("div", null,
        React.createElement("div", { ref: nodeRef, className: "lookupsListing" },
            React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                    React.createElement(ListingSearch, null),
                    React.createElement(PrintButton, { printOnClick: handlePrint }),
                    React.createElement(Button, { type: "primary", onClick: openAddingModal, hidden: !isActionAllowed([LOOKUP_CREATION]) },
                        React.createElement("span", { className: "icofont icofont-plus mr-2", style: { fontSize: "1.3em" } }),
                        localize(["Lookup.addValue"]))) }),
            React.createElement("div", { className: "row d-inline-flex w-100 filter-wrapper" },
                React.createElement("div", { className: "col-3" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement(LookupTypesDropdownContainer, { selectedValue: selectedLookupType, onChange: (value) => onLookupTypeChange(value) })))),
            React.createElement(LookupTable, { data: (_c = lookups[selectedLookupType.label]) !== null && _c !== void 0 ? _c : [], actions: lookupsActions, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode })),
        React.createElement(AddLookup, { onClose: closeAddingModal, onSubmit: addLookupHandler, lookup: initialLookup, visible: addingModalVisibility, valuesRef: valuesRef, selectedParentLookupTypeName: selectedParentLookupTypeName }),
        React.createElement(EditLookup, { onEdited: editLookupHandler, existingLookup: selectedLookup, onClose: closeModal, visible: !!selectedLookup, valuesRef: valuesRef, selectedParentLookupTypeName: selectedParentLookupTypeName })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        lookups: state.lookups,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getLookup,
    addLookup,
    editLookup,
    deleteLookup,
    getLookupTypes,
};
LookupContainer.propTypes = {
    getLookup: PropTypes.func.isRequired,
    addLookup: PropTypes.func.isRequired,
    editLookup: PropTypes.func.isRequired,
    deleteLookup: PropTypes.func.isRequired,
    lookups: PropTypes.object.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(LookupContainer);
