import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../layout/components/customModal";
import NotificationSettingsForm from "./components/NotificationSettingsForm";
import "../NotificationSettings/style.scss";
import { getLookups } from "../../../redux/lookup/actions";
import useDidMount from "../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../core/constants/lookup";
import { connect } from "react-redux";
import { NotificationSettingsApi } from "../../../core/api/notificationSettingsApi";
import { NOTIFICATION_TYPE_COLLECTIVE, NOTIFICATION_TYPE_INDIVIDUAL, } from "../../../core/constants/notificationSettingsConstants";
import { notifications } from "../../../layout/components/notifications";
import { ResponseStatusCodes } from "../../../core/constants/responseStatusCodes";
import localize from "../../../core/utils/translation-files";
import isEmpty from "../../../core/helpers/isEmpty";
const NotificationSettingsContainer = ({ notificationSettingsModal, notificationSettingsModalClose, modalTitle, modalDescription, tabOptions, getLookups, }) => {
    let initialState = {
        id: 0,
        notificationType: NOTIFICATION_TYPE_INDIVIDUAL,
        categoryTypeId: null,
        notificationFrequency: null,
        timeInterval: null,
        sortId: null,
    };
    const [initialSettings, setInitialSettings] = useState();
    const [notificationSettings, setNotificationSettings] = useState();
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKCollectiveNotificationFrequency,
                LookupNames.LKCollectiveNotificationSort,
                LookupNames.LKItemCategory,
            ],
            getLookups,
        });
    });
    useEffect(() => {
        if (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) {
            let settings = [];
            NotificationSettingsApi.getNotificationSettings().then((res) => {
                var _a;
                if (!isEmpty(res)) {
                    settings = res;
                    setInitialSettings(res);
                }
                if (!(settings === null || settings === void 0 ? void 0 : settings.length)) {
                    for (let i = 0; i < (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length); i++) {
                        settings.push(Object.assign(Object.assign({}, initialState), { categoryTypeId: tabOptions[i].code }));
                    }
                    setNotificationSettings(settings);
                }
                else {
                    const notificationType = ((_a = settings === null || settings === void 0 ? void 0 : settings.filter(({ notificationType }) => notificationType === NOTIFICATION_TYPE_COLLECTIVE)) === null || _a === void 0 ? void 0 : _a.length)
                        ? NOTIFICATION_TYPE_COLLECTIVE
                        : NOTIFICATION_TYPE_INDIVIDUAL;
                    let updatedSettings = settings.filter((pref) => tabOptions.some((tabE) => (pref === null || pref === void 0 ? void 0 : pref.categoryTypeId.toString()) === tabE.code));
                    const newCat = tabOptions.filter((item1) => !updatedSettings.some((item2) => (item2 === null || item2 === void 0 ? void 0 : item2.categoryTypeId.toString()) === item1.code));
                    if (newCat === null || newCat === void 0 ? void 0 : newCat.length) {
                        newCat.map(({ code }) => updatedSettings.push(Object.assign(Object.assign({}, initialState), { categoryTypeId: +code })));
                    }
                    updatedSettings = updatedSettings === null || updatedSettings === void 0 ? void 0 : updatedSettings.map((set) => (Object.assign(Object.assign({}, set), { notificationType: notificationType })));
                    setNotificationSettings(updatedSettings);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length]);
    const notificationSettingsHandler = (payload, resetForm) => {
        const removeCategory = initialSettings === null || initialSettings === void 0 ? void 0 : initialSettings.filter((pref) => !tabOptions.some((tabE) => tabE.code === (pref === null || pref === void 0 ? void 0 : pref.categoryTypeId.toString())));
        const updatedCat = (removeCategory === null || removeCategory === void 0 ? void 0 : removeCategory.length) &&
            removeCategory.map((e) => (Object.assign(Object.assign({}, initialState), { id: e.id, categoryTypeId: e.categoryTypeId })));
        const reqPayload = tabOptions && tabOptions.length
            ? (removeCategory === null || removeCategory === void 0 ? void 0 : removeCategory.length)
                ? [...payload, ...updatedCat]
                : payload
            : payload.map((val, i) => {
                var _a;
                return Object.assign(Object.assign({}, initialState), { id: (_a = payload[i].id) !== null && _a !== void 0 ? _a : 0, categoryTypeId: payload[i].categoryTypeId });
            });
        NotificationSettingsApi.setNotificationPreferences(reqPayload).then((res) => {
            if (res &&
                (res.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    res.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                return;
            }
            else {
                resetForm();
                notificationSettingsModalClose(false);
                notifications.success(localize([
                    "Notification.notificationsSettings",
                    "updated",
                    "successfully",
                ]));
            }
        });
    };
    const valuesRef = useRef(null);
    const closeSettingsModel = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            notificationSettingsModalClose(false);
        }
    };
    return (React.createElement("div", null,
        React.createElement(CustomModal, { visible: (notificationSettings || !tabOptions.length) &&
                notificationSettingsModal, onCancel: closeSettingsModel, maskClosable: false, destroyOnClose: true, footer: null, modelClass: "notificationSettingsModal" },
            notificationSettings && (React.createElement(NotificationSettingsForm, { initialNotificationSetting: notificationSettings === null || notificationSettings === void 0 ? void 0 : notificationSettings.sort((a, b) => a.categoryTypeId - b.categoryTypeId), onSubmit: notificationSettingsHandler, modalTitle: modalTitle, modalDescription: modalDescription, closeModal: closeSettingsModel, tabOptions: tabOptions, valuesRef: valuesRef })),
            " ",
            !tabOptions.length && !initialSettings ? (React.createElement("div", { className: "reset-password", "data-test": "resetPasswordForm" },
                React.createElement("h4", { className: "mt-0 mb-3" }, modalTitle),
                React.createElement("form", { className: `mb-1 customForm`, name: "basic" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                            React.createElement("label", null, localize(["Notification.notificationType"]))),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", null, "Individual Notification")))))) : null)));
};
const mapDispatchToProps = {
    getLookups,
};
export default connect(null, mapDispatchToProps)(NotificationSettingsContainer);
