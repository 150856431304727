import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import isEmpty from "../../../core/helpers/isEmpty";
import SingleSelectNew from "./SingleSelectNew";
const SiteDropdownContainer = ({ onChange, selectedValue, defaultValue, className, groupId, companyId, disabled, allowClear = false, allowEmpty = false, labelInValue, haveDefaultSiteHandler = false, placeHolder, watchlistAllowClear = false, error = null, }) => {
    const [siteOptions, setSiteOptions] = useState([]);
    // const isAllowClear = useMemo(() => {
    //   return allowClear
    //     ? isActionAllowed([SITE_EDIT], {
    //         type: CompanyType.Company,
    //         id: companyId,
    //         parentId: groupId,
    //       })
    //     : false;
    // }, [groupId, companyId, allowClear]);
    const groupOptions = useSelector((state) => state.groupOptions);
    useEffect(() => {
        var _a, _b, _c, _d;
        setSiteOptions([]);
        if (!isEmpty(groupId) && !isEmpty(companyId)) {
            const siteOptions = (_c = (_b = (_a = groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.find(({ id }) => id === groupId)) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === companyId)) === null || _c === void 0 ? void 0 : _c.listSimpleDataDTO;
            if (haveDefaultSiteHandler && isEmpty(selectedValue) && !allowEmpty) {
                const { id, name } = (_d = siteOptions === null || siteOptions === void 0 ? void 0 : siteOptions[0]) !== null && _d !== void 0 ? _d : {};
                onChange(id, { name });
            }
            if (siteOptions === null || siteOptions === void 0 ? void 0 : siteOptions.length) {
                setSiteOptions(siteOptions);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, companyId, groupOptions]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "site-filter-dropdown", options: siteOptions, selectedValue: selectedValue, defaultValue: defaultValue, onChange: onChange, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a Site", key: "siteSelect", valueProperty: "id", textProperty: "name", className: className, disabled: disabled, labelInValue: labelInValue, 
            // allowClear={watchlistAllowClear ? allowClear : isAllowClear}
            allowClear: allowClear, error: error })));
};
export default SiteDropdownContainer;
