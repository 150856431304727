import React from "react";
import LookupForm from "./lookupForm";
import CustomModal from "../../../../../layout/components/customModal";
const EditLookup = ({ existingLookup, visible, onClose, onEdited, valuesRef, selectedParentLookupTypeName }) => {
    return (React.createElement("div", { "data-test": "EditLookupComponent" },
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["edit", "Lookup.lookupValue"] },
            React.createElement("div", { "data-test": "EditLookupFormComponent" },
                React.createElement(LookupForm, { onCancel: onClose, onSubmit: onEdited, lookup: existingLookup, submitText: "Edit Lookup", isEdit: true, valuesRef: valuesRef, selectedParentLookupTypeName: selectedParentLookupTypeName })))));
};
export default EditLookup;
