import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DelphiGroupId } from "../../../core/constants/groupConstants";
import isEmpty from "../../../core/helpers/isEmpty";
import usePermissions from "../../../core/hooks/usePermissions";
import localize from "../../../core/utils/translation-files";
import CompanyDropdownContainer from "../select/CompanyDropdownContainer";
import CategoryDropdownContainer from "../select/CategoryDropdownContainer";
import SubCategoryDropdownContainer from "../select/SubCategoryDropdownContainer";
import TrackedItemDropdownContainer from "../select/TrackedItemDropdownContainer";
import GroupDropdownContainer from "../select/GroupDropdownContainer";
import SiteDropdownContainer from "../select/SiteDropdownContainer";
import { watchlistRoute } from "../../../core/constants/routesPath";
const ListingPermissionDropdown = ({ groupOptionValues: { groupId, companyId, siteId, categoryId, subcategoryId, trackedItemId, }, onChangeGroupOptions, haveDefaultCompanyHandler, haveDefaultSiteHandler, customPlaceHolder, allowClearCompany = true, allowClearGroup, allowClearSite = true, allowNAItem = false, }) => {
    const { isDelphieorSuperUser, isDelphiSuperOrGroupUser, isSingleSiteAdmin, singleGroupValues, singleCompanyValues, singleSiteValues, groupOptions, isMultiCompanyAdmin, isSingleGroup, isSingleCompany, isSingleSite, isSiteAdmin, isGroupAdmin, isCompanyAdmin, isExternalUser, } = usePermissions(false, true);
    const isWatchlist = useRef(false);
    const location = useLocation();
    if ((location === null || location === void 0 ? void 0 : location.pathname) === watchlistRoute) {
        isWatchlist.current = true;
    }
    const handleGroupChange = (value) => {
        onChangeGroupOptions({
            groupId: value,
            companyId: null,
            siteId: null,
            categoryId: null,
            subcategoryId: null,
            trackedItemId: null,
        });
    };
    const handleChangeCompany = (value) => {
        onChangeGroupOptions({
            groupId,
            companyId: value,
            siteId: null,
            categoryId: null,
            subcategoryId: null,
            trackedItemId: null,
        });
    };
    const handleChangeSite = (value, _, selectedCompany) => {
        onChangeGroupOptions({
            groupId,
            companyId: selectedCompany !== null && selectedCompany !== void 0 ? selectedCompany : companyId,
            siteId: value,
            categoryId: null,
            subcategoryId: null,
            trackedItemId: null,
        });
    };
    const handleChangeCategory = (value, _, selectedSite) => {
        onChangeGroupOptions({
            groupId,
            companyId: companyId,
            siteId: selectedSite !== null && selectedSite !== void 0 ? selectedSite : siteId,
            categoryId: value,
            subcategoryId: null,
            trackedItemId: null,
        });
    };
    const handleChangeSubCategory = (value, _, selectedCategory) => {
        onChangeGroupOptions({
            groupId,
            companyId: companyId,
            siteId: siteId,
            categoryId: selectedCategory !== null && selectedCategory !== void 0 ? selectedCategory : categoryId,
            subcategoryId: value,
            trackedItemId: null,
        });
    };
    const handleChangeTrackedItem = (value, _, selectedSubcategory) => {
        onChangeGroupOptions({
            groupId,
            companyId: companyId,
            siteId: siteId,
            categoryId: categoryId,
            subcategoryId: selectedSubcategory !== null && selectedSubcategory !== void 0 ? selectedSubcategory : subcategoryId,
            trackedItemId: value,
        });
    };
    useEffect(() => {
        var _a, _b, _c;
        if ((groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) && isEmpty(groupId) && !allowClearGroup) {
            onChangeGroupOptions({
                groupId: (_a = singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.id) !== null && _a !== void 0 ? _a : DelphiGroupId,
                companyId: (_b = singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.listSimpleDataDTO[0]) === null || _b === void 0 ? void 0 : _b.id,
                siteId: (_c = singleCompanyValues === null || singleCompanyValues === void 0 ? void 0 : singleCompanyValues.listSimpleDataDTO[0]) === null || _c === void 0 ? void 0 : _c.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        groupOptions,
        singleGroupValues,
        singleCompanyValues,
        singleSiteValues,
        groupId,
    ]);
    function getSiteId(groupId, companyId, siteId) {
        return siteId > 0 ? siteId : companyId > 0 ? companyId : groupId > 0 ? groupId : null;
    }
    return (React.createElement("div", { className: "customFilterFields" },
        React.createElement("div", { className: "form-group col-12", hidden: isSingleGroup && !isDelphieorSuperUser, "data-test": "group-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["Group.group", "name"]))),
            React.createElement(GroupDropdownContainer, { className: "mb-2 mb-md-0", onChange: handleGroupChange, selectedValue: groupId, allowClear: allowClearGroup && isDelphieorSuperUser, allowNAItem: allowNAItem, placeHolder: customPlaceHolder && isEmpty(groupId)
                    ? `All Groups`
                    : "Select a Group" })),
        React.createElement("div", { className: "form-group col-12", hidden: isSingleCompany && !isDelphieorSuperUser && !isGroupAdmin, "data-test": "company-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["Company.company", "name"]))),
            React.createElement(CompanyDropdownContainer, { className: "mb-2 mb-md-0", selectedValue: companyId, groupId: groupId, onChange: handleChangeCompany, allowClear: allowClearCompany &&
                    (isDelphiSuperOrGroupUser || isMultiCompanyAdmin || !isCompanyAdmin), allowEmpty: !isCompanyAdmin, siteAdminHandler: handleChangeSite, haveDefaultCompanyHandler: isDelphieorSuperUser &&
                    (haveDefaultCompanyHandler || haveDefaultSiteHandler), placeHolder: customPlaceHolder && !isEmpty(groupId)
                    ? `All Companies`
                    : "Select a Company" })),
        React.createElement("div", { className: "form-group col-12", hidden: isSingleSiteAdmin || (isSingleSite && isExternalUser), "data-test": "site-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["Site.site", "name"]))),
            React.createElement(SiteDropdownContainer, { className: "mb-2 mb-md-0", groupId: groupId, companyId: companyId, selectedValue: siteId, onChange: handleChangeSite, haveDefaultSiteHandler: haveDefaultSiteHandler && haveDefaultSiteHandler, allowClear: allowClearSite && (!isSiteAdmin || !isSingleSiteAdmin), allowEmpty: !isSiteAdmin, watchlistAllowClear: (isWatchlist === null || isWatchlist === void 0 ? void 0 : isWatchlist.current) && !isSingleSiteAdmin, placeHolder: customPlaceHolder && !isEmpty(companyId)
                    ? `All Sites`
                    : "Select a Site" })),
        React.createElement("div", { className: "form-group col-12", hidden: !(isWatchlist === null || isWatchlist === void 0 ? void 0 : isWatchlist.current), "data-test": "category-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["TrackedItem.category", "name"]))),
            React.createElement(CategoryDropdownContainer, { className: "mb-2 mb-md-0", companyId: companyId, siteId: siteId, selectedValue: categoryId, onChange: handleChangeCategory, haveDefaultSiteHandler: haveDefaultSiteHandler && haveDefaultSiteHandler, allowClear: allowClearSite, placeHolder: customPlaceHolder && !isEmpty(siteId)
                    ? `All Categories`
                    : "Select a Category" })),
        React.createElement("div", { className: "form-group col-12", hidden: !(isWatchlist === null || isWatchlist === void 0 ? void 0 : isWatchlist.current), "data-test": "subcategory-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["TrackedItem.subCategory", "name"]))),
            React.createElement(SubCategoryDropdownContainer, { className: "mb-2 mb-md-0", companyId: companyId, siteId: siteId, categoryId: categoryId, selectedValue: subcategoryId, onChange: handleChangeSubCategory, haveDefaultSiteHandler: haveDefaultSiteHandler && haveDefaultSiteHandler, allowClear: allowClearSite, placeHolder: customPlaceHolder && !isEmpty(categoryId)
                    ? `All Categories`
                    : "Select a Category" })),
        React.createElement("div", { className: "form-group col-12", hidden: !(isWatchlist === null || isWatchlist === void 0 ? void 0 : isWatchlist.current), "data-test": "trackedItem-dropdown" },
            React.createElement("div", { className: "ant-col ant-form-item-label" },
                React.createElement("label", null, localize(["TrackedItem.trackedItem", "name"]))),
            React.createElement(TrackedItemDropdownContainer, { className: "mb-2 mb-md-0", siteId: getSiteId(groupId, companyId, siteId), categoryId: categoryId, subcategoryId: subcategoryId, selectedValue: trackedItemId, onChange: handleChangeTrackedItem, haveDefaultSiteHandler: haveDefaultSiteHandler && haveDefaultSiteHandler, allowClear: allowClearSite, placeHolder: customPlaceHolder && !isEmpty(subcategoryId)
                    ? `All Tracked Items`
                    : "Select a Tracked Item" }))));
};
export default React.memo(ListingPermissionDropdown);
