import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "report";
export class ReportsApi {
    static getFinancialReport(data, abortSignal = undefined) {
        return httpPost(`${controllerEndpoint}/getFinancialReportsData`, {
            abortSignal,
            body: data,
        });
    }
    static getFiscalYearRange(id) {
        return httpGet(`admin/site/fiscalYear/${id}`);
    }
    static isTrackedItemTypeNameUnique(name) {
        return httpGet(`${controllerEndpoint}/validateReportTypeName/${name}`, {
            silent: true,
            area: "trackedItemNameCheck"
        });
    }
    static isTrackedItemNameUnique(name, id) {
        return httpGet(`${controllerEndpoint}/validateReportName/${name}/${id}`, {
            silent: true,
            area: "trackedItemNameCheck"
        });
    }
    static isTrackedItemTypeUnique(type) {
        return httpGet(`${controllerEndpoint}/validateReportType/${type}`, {
            silent: true,
            area: "trackedItemTypeCheck"
        });
    }
}
