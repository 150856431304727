export class TrackedItemsVariables {
}
TrackedItemsVariables.PROFIT_AND_LOSS_TRACKED_ITEM_TYPE = 4;
TrackedItemsVariables.ONLY_YEAR_LENGTH = 4;
TrackedItemsVariables.MONTHLY = "MONTHLY";
TrackedItemsVariables.ANNUAL = "ANNUAL";
TrackedItemsVariables.MINIMUM_VALUE_TEXT = 0;
TrackedItemsVariables.MINIMUM_VALUE = 0.0;
TrackedItemsVariables.MAXIMUM_VALUE_TEXT = 100;
TrackedItemsVariables.MAXIMUM_VALUE = 1.0;
TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE = 0;
TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER = 1;
TrackedItemsVariables.TRACKED_ITEM_VALUE_RATIO = 2;
TrackedItemsVariables.TRACKED_ITEM_WEIGHT = 1;
TrackedItemsVariables.DEFAULT_REPORT_WEIGHT = 1;
TrackedItemsVariables.DownwardoptimalValue = 0;
TrackedItemsVariables.UpwardoptimalValue = 1;
TrackedItemsVariables.FinancialCategoryCode = 2;
TrackedItemsVariables.UnrateableCode = 0;
TrackedItemsVariables.BENCHMARK_UPWARD_DIRECTION = 0;
TrackedItemsVariables.BENCHMARK_DOWNWARD_DIRECTION = 1;
TrackedItemsVariables.BENCHMARK_MIDDLE_DIRECTION = 2;
TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND = "5000";
TrackedItemsVariables.USER_TRACKED_ITEM = '1';
TrackedItemsVariables.SYSTEM_TRACKED_ITEM = '2';
TrackedItemsVariables.HIDDEN_TRACKED_ITEM = '3';
export class TrackedItemTypebenchmark {
}
TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_DELPHI = 1;
TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_CUSTOM = 0;
