import { TrackedItemsVariables, TrackedItemTypebenchmark, } from "../../../../core/constants/TrackedItemsConstants";
export const initialTrackedItem = {
    id: 0,
    groupId: undefined,
    companyId: undefined,
    name: "",
    description: "",
    itemCategoryId: undefined,
    itemSubCategoryId: undefined,
    itemRiskLevelId: 1,
    chartUnit: 1,
    decimalPlaces: undefined,
    disabled: false,
    format: undefined,
    statusColorId: undefined,
    statusColorText: undefined,
    reportTypeId: undefined,
    siteId: undefined,
    benchmarkCode: undefined,
    // minRating: undefined,
    // maxRating: undefined,
    // optimalLevel: undefined,
    // concernLevel: undefined,
    // criticalLevel: undefined,
    // lowerConcernLevel: undefined,
    // upperConcernLevel: undefined,
    // lowerCriticalLevel: undefined,
    // upperCriticalLevel: undefined,
    // lowerOptimalLevel: undefined,
    // upperOptimalLevel: undefined,
    gradeRanges: [],
    dataFrequency: undefined,
    shortTermMovingAveragePeriod: undefined,
    midTermMovingAverage: undefined,
    longTermMovingAverage: undefined,
    projectionAverageUsed: undefined,
    projectionPoint: undefined,
    requiredBenchmarkDirection: undefined,
    reactionTime: undefined,
    projectionRequired: true,
    weight: TrackedItemsVariables.TRACKED_ITEM_WEIGHT,
    companyWeight: TrackedItemsVariables.TRACKED_ITEM_WEIGHT,
    groupWeight: TrackedItemsVariables.TRACKED_ITEM_WEIGHT,
    displayValue: undefined,
    reportTypeFilter: -1,
    currencyFormat: false,
    ratingScaleA: undefined,
    ratingScaleB: undefined,
    ratingScaleC: undefined,
    ratingScaleD: undefined,
    systemCreated: false,
};
export const initialTrackedItemType = {
    id: 0,
    type: "",
    name: "",
    description: "",
    itemCategoryId: undefined,
    itemSubCategoryId: undefined,
    itemRiskLevelId: 1,
    disabled: false,
    format: undefined,
    dataFrequency: undefined,
    shortTermMovingAveragePeriod: undefined,
    midTermMovingAverage: undefined,
    longTermMovingAverage: undefined,
    projectionAverageUsed: undefined,
    projectionPoint: undefined,
    requiredBenchmarkDirection: null,
    reactionTime: undefined,
    valueType: 1,
    chartUnit: 1,
    decimalPlaces: 2,
    yaxisLabel: "",
    projectionRequired: true,
    weight: TrackedItemsVariables.DEFAULT_REPORT_WEIGHT,
    companyWeight: TrackedItemsVariables.DEFAULT_REPORT_WEIGHT,
    groupWeight: TrackedItemsVariables.DEFAULT_REPORT_WEIGHT,
    displayValue: TrackedItemsVariables.DEFAULT_REPORT_WEIGHT,
    systemCreated: false,
    reportTypeFilter: -1,
    currencyFormat: false,
    ratingScaleA: undefined,
    ratingScaleB: undefined,
    ratingScaleC: undefined,
    ratingScaleD: undefined,
    isHidden: false,
    benchmarkList: [
        {
            id: undefined,
            benchmarkCode: TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_DELPHI,
            minRating: undefined,
            maxRating: undefined,
            optimalLevel: undefined,
            criticalLevel: undefined,
            concernLevel: undefined,
            benchmarkName: undefined,
            lowerCriticalLevel: undefined,
            upperCriticalLevel: undefined,
            lowerConcernLevel: undefined,
            upperConcernLevel: undefined,
            lowerOptimalLevel: undefined,
            upperOptimalLevel: undefined,
            gradeRanges: [],
        },
    ],
    associatedTrackedItems: [],
};
