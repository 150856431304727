var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { trackedItemsListingRoute } from "../../../../../../../core/constants/routesPath";
import { isUniqueTrackedItemName } from "../../../../../../../core/validations/trackedItemNameValidation";
import TrackedItemInformation from "./TrackedItemInformation";
import MovingAverages from "./MovingAverages";
import Projections from "./Projections";
import BenchmarkSelection from "./BenchmarkSelection";
import { isUnique } from "../../../../../../../core/validations/uniqueFieldValidations";
import localize from "../../../../../../../core/utils/translation-files";
import { inputDefaultLength, maxTextAreaLength, } from "../../../../../../../core/utils/default-length";
import { TrackedItemsVariables, TrackedItemTypebenchmark, } from "../../../../../../../core/constants/TrackedItemsConstants";
import NotificationRules from "./NotificationRules";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import UsePrintPage from "../../../../../../../core/hooks/usePrintPage";
import ChartVariables from "./chartVariables";
import PromptComponent from "../../../../../../../layout/components/prompt-message";
import FormActionButtons from "../../../../../../../layout/components/common/FormsActionButtons";
import { useHistory } from "react-router-dom";
import DefaultRelations from "./defaultRelations";
import { BenchmarkDirectionType } from "../../../../../../../core/enums/benchmarkDirection";
import { getRatingValues } from "../../../../../../../layout/components/numericColoredRanges/utils";
import { ReportsApi } from "../../../../../../../core/api/reportsApi";
import { useSelector } from "react-redux";
const TrackedItemDefinitionForm = ({ intialTrackedItem, onSubmit, trackedItems, 
// trackedItemTypes,
setSelectedGroup, setBenchmarkDropdownOptions, benchmarkDropdownOptions, isClientAdmin, notificationsList = null, setNotificationsList = null, filterNotificationsListHandler, notificationsChanged, isSystemCreatedEdit, isSystemCreated, userBandOptions, setOverWrittenNotificationList, overWrittenNotificationList, resetDefaultClientNotifications, allowGroupNaItem = false, bandScales, setBandScales, isHidden, }) => {
    const [key, setKey] = useState(0);
    const [isHoldingCompany, setIsHoldingCompany] = useState();
    const [direction, setDirection] = useState(intialTrackedItem.requiredBenchmarkDirection);
    const [rangeErrors, setRangeErrors] = useState(undefined);
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm, setErrors, setFieldValue, dirty, setFieldTouched, setValues, validateForm, } = useFormik({
        validationSchema: Yup.object({
            reportTypeId: Yup.number().required(localize(["TrackedItem.trackedItem", "type", "name", "isRequired!"])),
            groupId: Yup.number().when("name", {
                is: (x) => isSystemCreatedEdit === false || isSystemCreatedEdit === undefined,
                then: Yup.number()
                    .required(localize(["Group.group", "isRequired!"]))
                    .nullable(),
            }),
            companyId: Yup.number().when("name", {
                is: (x) => isSystemCreatedEdit === false || isSystemCreatedEdit === undefined,
                then: Yup.number()
                    .required(localize(["TrackedItem.site", "isRequired!"]))
                    .nullable(),
            }),
            siteId: Yup.number().when("name", {
                is: (x) => isSystemCreatedEdit === false || isSystemCreatedEdit === undefined,
                then: Yup.number()
                    .required(localize(["TrackedItem.company", "isRequired!"]))
                    .nullable(),
            }),
            name: Yup.string()
                .trim()
                .required(localize(["TrackedItem.trackedItem", "name", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .test("check", localize([
                "TrackedItem.trackedItem",
                "name",
                "TrackedItem.alreadyExistInTheSelected",
                "TrackedItem.category",
            ]), function (value) {
                return isUniqueTrackedItemName(value, trackedItems, values.itemCategoryId, intialTrackedItem);
            })
                // .matches(trackedItemNameRegex.regex, trackedItemNameRegex.message)
                .test("check", localize(["TrackedItem.trackedItem", "name", "alreadyExist!"]), function (value) {
                return isUnique(value, trackedItems, intialTrackedItem);
            }),
            description: Yup.string()
                .trim()
                .test("len", localize([
                "mustBe",
                "lessThan",
                `${maxTextAreaLength}`,
                "characters",
            ]), (val) => (val ? val.length <= maxTextAreaLength : 0 < 1))
                .nullable(),
            // .matches(trackedItemNameRegex.regex, trackedItemNameRegex.message),
            itemCategoryId: Yup.number().required(localize(["TrackedItem.category", "isRequired!"])),
            itemSubCategoryId: Yup.number().required(localize(["TrackedItem.subCategory", "isRequired!"])),
            itemRiskLevelId: Yup.number().required(localize(["TrackedItem.levelOfRisk", "isRequired!"])),
            chartUnit: Yup.number().when("valueType", {
                is: (valueType) => valueType !== TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE,
                then: Yup.number().required(localize(["TrackedItem.label", "isRequired!"])),
            }),
            decimalPlaces: Yup.number().required(localize(["TrackedItem.decimalPlaces", "isRequired!"])),
            dataFrequency: Yup.number().required(localize(["TrackedItem.dataFrequency", "isRequired!"])),
            shortTermMovingAveragePeriod: Yup.number()
                .positive()
                .label(localize(["TrackedItem.shortTerm"]))
                .min(1)
                .required(localize([
                "TrackedItem.shortTerm",
                "TrackedItem.movingAverage",
                "isRequired!",
            ]))
                .when(["midTermMovingAverage"], (midTermMovingAverage, schema) => {
                if (midTermMovingAverage !== undefined) {
                    return schema.max(midTermMovingAverage - 0.1, localize([
                        "TrackedItem.shortTerm",
                        "mustBe",
                        "lessThan",
                        "TrackedItem.midTerm",
                    ]));
                }
            }),
            midTermMovingAverage: Yup.number()
                .positive()
                .label(localize(["TrackedItem.midTerm"]))
                .min(1)
                .required(localize(["TrackedItem.midTerm", "isRequired!"]))
                .when(["longTermMovingAverage"], (longTermMovingAverage, schema) => {
                if (longTermMovingAverage !== undefined) {
                    return schema.max(longTermMovingAverage - 0.1, localize([
                        "TrackedItem.midTerm",
                        "mustBe",
                        "lessThan",
                        "TrackedItem.longTerm",
                    ]));
                }
            }),
            longTermMovingAverage: Yup.number()
                .positive()
                .min(1)
                .label(localize(["TrackedItem.longTerm"]))
                .required(localize(["TrackedItem.longTerm", "isRequired!"])),
            projectionAverageUsed: Yup.number()
                .nullable()
                .when("projectionRequired", {
                is: (projectionRequired) => projectionRequired === true,
                then: Yup.number().required(localize([
                    "TrackedItem.projection",
                    "TrackedItem.used",
                    "isRequired!",
                ])),
            }),
            projectionPoint: Yup.number()
                .nullable()
                .label(localize(["TrackedItem.projection", "TrackedItem.point"]))
                .when("projectionRequired", {
                is: (projectionRequired) => projectionRequired === true,
                then: Yup.number()
                    .positive()
                    .min(1)
                    .required(localize([
                    "TrackedItem.projection",
                    "TrackedItem.point",
                    "isRequired!",
                ])),
            }),
            reactionTime: Yup.number()
                .nullable()
                .label(localize(["TrackedItem.reactionTime"]))
                .when("projectionRequired", {
                is: (projectionRequired) => projectionRequired === true,
                then: Yup.number()
                    .positive()
                    .min(1)
                    .required(localize(["TrackedItem.reactionTime", "isRequired!"])),
            }),
            requiredBenchmarkDirection: Yup.number().required(localize([
                "TrackedItem.required",
                "TrackedItem.benchmark",
                "TrackedItem.direction",
                "isRequired!",
            ])),
            weight: Yup.number().required(localize(["TrackedItem.weight", "isRequired!"])),
            companyWeight: Yup.number().when("isSystemCreated", {
                is: !isSystemCreated,
                then: Yup.number().required(localize(["Company.company", "TrackedItem.weight", "isRequired!"])),
            }),
            groupWeight: Yup.number().when("isSystemCreated", {
                is: !isSystemCreated,
                then: Yup.number().required(localize(["Group.group", "TrackedItem.weight", "isRequired!"])),
            }),
            displayValue: Yup.number().required(localize(["TrackedItem.displayValue", "isRequired!"])),
            ratingScaleA: Yup.string()
                .nullable()
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
            ratingScaleB: Yup.string()
                .nullable()
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
            ratingScaleC: Yup.string()
                .nullable()
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
            ratingScaleD: Yup.string()
                .nullable()
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .required(localize(["TrackedItem.ratingScale", "isRequired!"])),
            benchmarkCode: Yup.string().required(localize(["TrackedItem.benchmark", "isRequired!"])),
            gradeRanges: [
                BenchmarkDirectionType.Upwards,
                BenchmarkDirectionType.Downwards,
            ].includes(direction)
                ? Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(4, localize(["TrackedItem.needAtLeast", "4", "TrackedItem.grades"]))
                : Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(7, localize(["TrackedItem.needAtLeast", "7", "TrackedItem.grades"])),
        }),
        initialValues: intialTrackedItem,
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            const tempValues = JSON.parse(JSON.stringify(values));
            const isValid = yield validateName(values.name);
            if (!rangeErrors && isValid) {
                if (tempValues.benchmarkCode !==
                    TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_CUSTOM) {
                    tempValues.minRating = null;
                    tempValues.maxRating = null;
                    // tempValues.criticalLevel = null;
                    // tempValues.concernLevel = null;
                    // tempValues.optimalLevel = null;
                    tempValues.gradeRanges = [];
                }
                onSubmit(tempValues, setErrors, handleResetForm);
            }
        }),
    });
    const handleIdValues = () => {
        const { groupId, companyId, siteId } = values;
        if (siteId !== -1) {
            return siteId;
        }
        else if (siteId === -1 && companyId !== -1) {
            return companyId;
        }
        else if (groupId !== -1 && companyId === -1 && siteId === -1) {
            return groupId;
        }
    };
    const history = useHistory();
    const handleResetForm = () => {
        resetForm();
        setOverWrittenNotificationList([], true);
    };
    const trackedItemTypeNameChangeHandler = (value, onChangeHandlerValue) => {
        if (isEmpty(value)) {
            //reset form on initial on changing group, company or site values
            const { groupId, companyId, siteId } = intialTrackedItem, restInitialTrackedItem = __rest(intialTrackedItem, ["groupId", "companyId", "siteId"]);
            setValues((prev) => (Object.assign(Object.assign(Object.assign({}, prev), restInitialTrackedItem), onChangeHandlerValue)));
            setDirection(intialTrackedItem.requiredBenchmarkDirection);
            setBenchmarkDropdownOptions([]);
            return;
        }
        const filteredTrackedItemType = onChangeHandlerValue;
        // let filteredTrackedItemType = trackedItemTypes.filter(
        //   (item) => parseInt(item.id) === parseInt(value)
        // )[0];
        const ratingScale = Object.entries(filteredTrackedItemType)
            .map(([key, value]) => {
            if (key.includes("ratingScale"))
                return {
                    bandName: key[key.length - 1],
                    title: value,
                };
            else
                return null;
        })
            .filter((value) => !isEmpty(value));
        setBandScales(ratingScale);
        const updatedValues = Object.assign({}, values);
        setValues(Object.assign(Object.assign({}, updatedValues), { reportTypeId: value, name: filteredTrackedItemType.name, format: filteredTrackedItemType.format, itemSubCategoryId: filteredTrackedItemType.itemSubCategoryId, itemCategoryId: filteredTrackedItemType.itemCategoryId, itemRiskLevelId: filteredTrackedItemType.itemRiskLevelId, dataFrequency: filteredTrackedItemType.dataFrequency, description: filteredTrackedItemType.description, shortTermMovingAveragePeriod: filteredTrackedItemType.shortTermMovingAveragePeriod, midTermMovingAverage: filteredTrackedItemType.midTermMovingAverage, longTermMovingAverage: filteredTrackedItemType.longTermMovingAverage, projectionAverageUsed: filteredTrackedItemType.projectionAverageUsed, projectionPoint: filteredTrackedItemType.projectionPoint, requiredBenchmarkDirection: filteredTrackedItemType.requiredBenchmarkDirection, reactionTime: filteredTrackedItemType.reactionTime, projectionRequired: filteredTrackedItemType.projectionRequired, benchmarkCode: undefined, 
            // minRating: undefined,
            // maxRating: undefined,
            // concernLevel: undefined,
            // criticalLevel: undefined,
            // optimalLevel: undefined,
            // lowerCriticalLevel: undefined,
            // lowerConcernLevel: undefined,
            // upperCriticalLevel: undefined,
            // upperConcernLevel: undefined,
            // lowerOptimalLevel: undefined,
            // upperOptimalLevel: undefined,
            gradeRanges: [], valueType: filteredTrackedItemType.valueType, chartUnit: filteredTrackedItemType.chartUnit, decimalPlaces: filteredTrackedItemType.decimalPlaces, weight: filteredTrackedItemType.weight, companyWeight: isSystemCreated
                ? 0
                : filteredTrackedItemType.companyWeight, groupWeight: isSystemCreated ? 0 : filteredTrackedItemType.groupWeight, displayValue: filteredTrackedItemType.displayValue, currencyFormat: filteredTrackedItemType.currencyFormat, reportTypeFilter: filteredTrackedItemType.reportTypeFilter || -1, ratingScaleA: filteredTrackedItemType.ratingScaleA, ratingScaleB: filteredTrackedItemType.ratingScaleB, ratingScaleC: filteredTrackedItemType.ratingScaleC, ratingScaleD: filteredTrackedItemType.ratingScaleD }));
        setDirection(filteredTrackedItemType.requiredBenchmarkDirection);
        const tempBenchmarkList = filteredTrackedItemType.benchmarkList;
        setBenchmarkDropdownOptions([
            ...tempBenchmarkList,
            {
                benchmarkCode: 0,
                benchmarkName: "Custom",
                minRating: "",
                concernLevel: "",
                criticalLevel: "",
                optimallLevel: "",
                maxRating: "",
                gradeRanges: [],
            },
        ]);
    };
    const trackedItemTypes = useSelector((state) => { var _a; return (_a = state.trackedItemTypes) === null || _a === void 0 ? void 0 : _a.trackedItemBySites; });
    const onBenchmarkValueSelection = (value) => {
        var _a, _b;
        console.log({ benchmarkDropdownOptions, value });
        const filteredBenchmark = (_b = (_a = trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.filter((item) => parseInt(item.id) === parseInt(values.reportTypeId))[0]) === null || _a === void 0 ? void 0 : _a.benchmarkList.filter((benchmark) => parseInt(benchmark.benchmarkCode) === parseInt(value))[0]) !== null && _b !== void 0 ? _b : (value === TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_DELPHI
            ? benchmarkDropdownOptions === null || benchmarkDropdownOptions === void 0 ? void 0 : benchmarkDropdownOptions[0]
            : undefined);
        if (filteredBenchmark !== undefined) {
            setValues(Object.assign(Object.assign({}, values), { benchmarkCode: value, gradeRanges: getRatingValues(direction, filteredBenchmark) }));
        }
        else {
            setValues(Object.assign(Object.assign({}, values), { benchmarkCode: value, 
                // minRating: undefined,
                // maxRating: undefined,
                // optimalLevel: undefined,
                // concernLevel: undefined,
                // criticalLevel: undefined,
                // lowerConcernLevel: undefined,
                // upperConcernLevel: undefined,
                // lowerCriticalLevel: undefined,
                // upperCriticalLevel: undefined,
                // lowerOptimalLevel: undefined,
                // upperOptimalLevel: undefined,
                gradeRanges: [] }));
        }
    };
    const isFormDirty = dirty ? !dirty : !notificationsChanged;
    const { handlePrint, nodeRef } = UsePrintPage();
    const isSaveHover = useRef(null);
    useEffect(() => {
        const saveButtonHover = (event) => {
            let drawerElement = document.querySelector(".formsFooter[data-test='siteFormComponent']");
            // let crossIcon = document.querySelector(".cross-icon-wrapper");
            // if (crossIcon?.contains(event.target)) return;
            if (drawerElement &&
                !drawerElement.contains(event.target)
            // &&
            // !crossIcon.contains(event.target)
            ) {
                isSaveHover.current = true;
            }
            else {
                isSaveHover.current = false;
            }
        };
        // Bind the event listener
        document.addEventListener("click", saveButtonHover);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("click", saveButtonHover);
        };
    }, []);
    const [customError, setCustomError] = useState(undefined);
    const validateName = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const isEdited = isEmpty(intialTrackedItem === null || intialTrackedItem === void 0 ? void 0 : intialTrackedItem.name)
            ? true
            : (intialTrackedItem === null || intialTrackedItem === void 0 ? void 0 : intialTrackedItem.name.toLocaleLowerCase().trim()) !==
                (value === null || value === void 0 ? void 0 : value.toLocaleLowerCase().trim());
        const errorText = localize([
            "TrackedItem.trackedItem",
            "name",
            "alreadyExist!",
        ]);
        const id = values.companyId !== -1
            ? values.companyId
            : values.siteId !== -1
                ? values.siteId
                : values.groupId;
        if (!isEmpty(value) && value.length <= inputDefaultLength && isEdited) {
            const updatedValue = encodeURIComponent(value);
            const response = yield ReportsApi.isTrackedItemNameUnique(updatedValue, id);
            if (response === true) {
                setFieldTouched("name", true);
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: errorText })));
                setErrors(Object.assign(Object.assign({}, errors), { name: errorText }));
                return false;
            }
            else {
                setErrors(Object.assign(Object.assign({}, errors), { name: undefined }));
                setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: undefined })));
                return true;
            }
        }
        else {
            setErrors(Object.assign(Object.assign({}, errors), { name: undefined }));
            setCustomError((prev) => (Object.assign(Object.assign({}, prev), { name: undefined })));
            return true;
        }
    });
    const submitForm = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const formErrors = yield validateForm(values);
        if (!isEmpty(formErrors)) {
            validateName(values.name);
        }
        handleSubmit(e);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: !isFormDirty }),
        React.createElement("form", { "data-test": "tracked-item-form", className: "mb-1 customForm", name: "basic", onSubmit: submitForm, key: key, ref: nodeRef, id: "my-form" },
            React.createElement("div", { "data-test": "tracked-item-info-section" },
                React.createElement(TrackedItemInformation, { handleChange: handleChange, isSaveHover: isSaveHover, handleBlur: handleBlur, errors: errors, customError: customError, setCustomError: setCustomError, validateName: validateName, touched: touched, setFieldValue: setFieldValue, values: values, trackedItemTypeNameChangeHandler: trackedItemTypeNameChangeHandler, setSelectedGroup: setSelectedGroup, isClientAdmin: isClientAdmin, isSystemCreated: isSystemCreated, isSystemCreatedEdit: isSystemCreatedEdit, allowGroupNaItem: allowGroupNaItem, isHoldingCompany: isHoldingCompany, setIsHoldingCompany: setIsHoldingCompany, isHidden: isHidden })),
            React.createElement("div", { className: "moving-averages", "data-test": "moving-average-section" },
                React.createElement(MovingAverages, { handleChange: handleChange, handleBlur: handleBlur, errors: errors, touched: touched, setFieldValue: setFieldValue, values: values })),
            React.createElement("div", { className: "projections", "data-test": "projections-section" },
                React.createElement(Projections, { handleChange: handleChange, handleBlur: handleBlur, errors: errors, touched: touched, setFieldValue: setFieldValue, values: values, setDirection: setDirection, isClientAdmin: isClientAdmin, projectionRequired: values.projectionRequired })),
            React.createElement("div", { hidden: isSystemCreatedEdit, "data-test": "tracked-item-variables-section" },
                React.createElement(ChartVariables, { setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched })),
            React.createElement(DefaultRelations, { reportTypeId: values === null || values === void 0 ? void 0 : values.reportTypeId, siteId: handleIdValues() }),
            React.createElement("div", { className: "benchmark-selection", "data-test": "benchmark-selection-section" },
                React.createElement(BenchmarkSelection, { errors: errors, touched: touched, handleBlur: handleBlur, handleChange: handleChange, setFieldValue: setFieldValue, values: values, benchmarkDropdownOptions: benchmarkDropdownOptions, onBenchmarkValueSelection: onBenchmarkValueSelection, rangeErrors: rangeErrors, setRangeErrors: setRangeErrors, bandScales: bandScales })),
            React.createElement("div", { className: "notification-rules", "data-test": "notification-rules-section", hidden: isSystemCreatedEdit },
                React.createElement(NotificationRules, { setNotificationsList: setNotificationsList, notificationsList: notificationsList, filterNotificationsListHandler: filterNotificationsListHandler, userBandOptions: userBandOptions, overWrittenNotificationList: overWrittenNotificationList, setOverWrittenNotificationList: setOverWrittenNotificationList, resetDefaultClientNotifications: resetDefaultClientNotifications, isEdit: values.id > 0 })),
            React.createElement(FormActionButtons, { onCancel: () => history.push(trackedItemsListingRoute), fromId: "my-form", isEdit: isFormDirty || intialTrackedItem.id > 0, isDirty: isFormDirty, resetForm: handleResetForm, setKey: setKey, printOnClick: handlePrint, dataTest: "siteFormComponent" }))));
};
TrackedItemDefinitionForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intialTrackedItem: PropTypes.object.isRequired,
};
export default TrackedItemDefinitionForm;
