var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../../../../layout/components/notifications";
import { useHistory, useParams } from "react-router-dom";
import { groupsRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import { GroupApi } from "../../../../../core/api/groupApi";
import GroupForm from "../components/groupForm";
import { editGroup, getGroups } from "../../../../../redux/group/actions";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookups } from "../../../../../redux/lookup/actions";
import usePermissions from "../../../../../core/hooks/usePermissions";
const EditGroup = ({ groups, getGroups, editGroup, getLookups }) => {
    var _a, _b, _c, _d;
    const history = useHistory();
    const params = useParams();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { isDelphieorSuperUser } = usePermissions();
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        var _a, _b;
        getLookupByListing({
            LookupNames: [LookupNames.LKCompanyRegistrationType, LookupNames.LKItemCategory],
            getLookups,
        });
        if (((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.groupId) ||
            ((_b = history.location.state) === null || _b === void 0 ? void 0 : _b.groupId) === 0) {
            GroupApi.getSingleGroup(params.id).then((res) => {
                var _a;
                setSelectedGroup(Object.assign(Object.assign({}, res), { externalReference: (_a = res === null || res === void 0 ? void 0 : res.externalReference) === null || _a === void 0 ? void 0 : _a.toString() }));
            });
            if (groups.length === 0) {
                loadGroups();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadGroups = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getGroups().catch((error) => {
            throw error;
        });
    });
    const editGroupHandler = (group, resetForm) => {
        console.log({ group });
        editGroup(group).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                return;
            }
            else {
                resetForm();
                notifications.success(localize(["Group.group", "updated", "successfully"]));
                history.push(groupsRoute);
            }
        });
    };
    if (((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.groupId) !== 0) {
        if (!((_b = history.location.state) === null || _b === void 0 ? void 0 : _b.groupId)) {
            history.push(groupsRoute);
        }
    }
    return (React.createElement(React.Fragment, null, selectedGroup !== null && (React.createElement(GroupForm, { onCancel: () => history.go(-1), onSubmit: editGroupHandler, group: selectedGroup, submitText: "Save", isEdit: true, groups: groups, isDelphieorSuperUser: isDelphieorSuperUser, isReadOnly: (_d = (_c = history.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.isReadOnly }))));
};
function mapStateTopProps(state) {
    return {
        groups: state.groups,
    };
}
const mapDispatchToProps = {
    editGroup,
    getGroups,
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(EditGroup);
