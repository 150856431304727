var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TrackedItemDefinitionForm from "./components/TrackedItemDefinitionForm";
import { addTrackedItem, editTrackedItem, } from "../../../../../../redux/trackedItem/actions";
import { getTrackedItemsPerSite } from "../../../../../../redux/trackedItemsPerSite/actions";
import { notifications } from "../../../../../../layout/components/notifications";
import { ResponseStatusCodes } from "../../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../../core/utils/translation-files";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../../../redux/lookup/actions";
import { TrackedItemApi } from "../../../../../../core/api/trackedItemApi";
import { NotificationApi } from "../../../../../../core/api/notificationApi";
import useDidMount from "../../../../../../core/hooks/useDidMount";
import { TrackedItemsVariables } from "../../../../../../core/constants/TrackedItemsConstants";
import isActionAllowed from "../../../../../../core/authorization/userPermissions";
import { trackedItemsListingRoute } from "../../../../../../core/constants/routesPath";
import { REPORT_DEFAULTS_DELPHI_CONFIGURATION } from "../../../../../../core/constants/permissionConstants";
import { initialTrackedItem } from "../../defaultValues";
import { getBand } from "../../../../../../redux/band/actions";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { resetDefaultClientNotifications } from "../../../../../../redux/notification/client/actions";
import { getRatingValues } from "../../../../../../layout/components/numericColoredRanges/utils";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
const TrackedItemDefinitionFormContainer = ({ trackedItems, addTrackedItem, editTrackedItem, bands, getLookups, getBand, clearAllTrackedItemsPerSite, resetDefaultClientNotifications, }) => {
    var _a, _b;
    const isSystemCreated = SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB) ===
        TrackedItemsVariables.SYSTEM_TRACKED_ITEM;
    const isHidden = SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB) ===
        TrackedItemsVariables.HIDDEN_TRACKED_ITEM;
    const [trackedItem, setTrackedItem] = useState(Object.assign(Object.assign({}, initialTrackedItem), { systemCreated: isSystemCreated, isHidden: isHidden }));
    const [key, setKey] = useState(0);
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [selectedGroup, setSelectedGroup] = useState(undefined);
    const [benchmarkDropdownOptions, setBenchmarkDropdownOptions] = useState([]);
    const [notificationsList, setNotificationsList] = useState([]);
    const [notificationsChanged, setNotificationsChanged] = useState(false);
    const [overWrittenNotificationList, setOverWrittenNotificationList] = useState([]);
    const [bandScales, setBandScales] = useState(undefined);
    const userBandOptions = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKUserBand]) !== null && _a !== void 0 ? _a : []; });
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKReportType,
                LookupNames.LKReportTypeDataFrequency,
                LookupNames.LKReportTypeBenchmarkDirection,
                LookupNames.LKReportTypeProjectionAverage,
                LookupNames.LKItemCategory,
                LookupNames.LKItemRiskLevel,
                LookupNames.LKReportValueType,
                LookupNames.LKChartDisplayUnit,
                LookupNames.LKUserBand,
                LookupNames.LKReportWeight,
                LookupNames.LKDisplayValue,
            ],
            getLookups,
        });
        // if (trackedItemTypes?.length === 0) {
        //   const trackItemActions = isSystemCreated
        //     ? getSystemCreatedTrackedItemTypes
        //     : isHidden
        //     ? getHiddenTrackedItemTypes
        //     : getTrackedItemTypes;
        //   trackItemActions().catch((error) => {
        //     throw error;
        //   });
        // }
        !bands.length && getBand();
        if ((notificationsList === null || notificationsList === void 0 ? void 0 : notificationsList.length) === 0) {
            NotificationApi.getLocalNotifications().then((res) => {
                setNotificationsList(res === null || res === void 0 ? void 0 : res.map((noti) => (Object.assign(Object.assign({}, noti), { enabled: true, recipient: !isEmpty(noti.recipient) &&
                        noti.recipient !==
                            TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND
                        ? noti.recipient
                        : undefined }))));
            });
        }
    });
    const shouldFetch = useRef(true);
    const loadEditTrackedItem = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f;
        if (params.id &&
            params.id > 0 &&
            ((_c = location.state) === null || _c === void 0 ? void 0 : _c.trackedItemId) &&
            (notificationsList === null || notificationsList === void 0 ? void 0 : notificationsList.length) > 0 &&
            shouldFetch.current) {
            shouldFetch.current = false;
            const existingTrackedItem = yield TrackedItemApi.getSingleTrackedItem(params.id);
            let trackedItem = JSON.parse(JSON.stringify(existingTrackedItem));
            if (trackedItem) {
                const ratingScale = Object.entries(trackedItem)
                    .map(([key, value]) => {
                    if (key.includes("ratingScale")) {
                        return {
                            bandName: key[key.length - 1],
                            title: value,
                        };
                    }
                    else {
                        return null;
                    }
                })
                    .filter((value) => !isEmpty(value));
                setBandScales(ratingScale);
                // if (!location.state?.systemCreated) {
                trackedItem.siteId = (_d = existingTrackedItem["companyId"]) !== null && _d !== void 0 ? _d : -1;
                trackedItem.companyId = (_e = existingTrackedItem["siteId"]) !== null && _e !== void 0 ? _e : -1;
                // }
                const tempBenchmarkList = trackedItem.reportType.benchmarkList;
                setBenchmarkDropdownOptions([
                    ...tempBenchmarkList,
                    {
                        id: 0,
                        benchmarkCode: 0,
                        benchmarkName: "Custom",
                        minRating: "",
                        // concernLevel: "",
                        // criticalLevel: "",
                        // optimallLevel: "",
                        maxRating: "",
                    },
                ]);
                trackedItem.itemCategoryId = trackedItem.reportType.itemCategoryId;
                trackedItem.itemSubCategoryId =
                    trackedItem.reportType.itemSubCategoryId;
                trackedItem.projectionRequired =
                    trackedItem.reportType.projectionRequired;
                trackedItem.valueType = trackedItem.reportType.valueType;
                // if (
                //   trackedItem.benchmarkCode ===
                //   TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_CUSTOM
                // ) {
                trackedItem.gradeRanges = getRatingValues(trackedItem.requiredBenchmarkDirection, trackedItem);
                // }
                if (trackedItem.itemRiskLevelId === undefined) {
                    trackedItem.itemRiskLevelId = trackedItem.reportType.itemRiskLevelId;
                }
                if (!trackedItem.chartUnit) {
                    trackedItem.chartUnit = trackedItem.reportType.chartUnit;
                }
                if (trackedItem.decimalPlaces === undefined ||
                    trackedItem.decimalPlaces === null) {
                    trackedItem.decimalPlaces = trackedItem.reportType.decimalPlaces;
                }
                if ((_f = trackedItem.notificationInstances) === null || _f === void 0 ? void 0 : _f.length) {
                    const tempNotification = trackedItem.notificationInstances;
                    setNotificationsList(tempNotification);
                }
                setTrackedItem(trackedItem);
                setKey(Math.random());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [params.id, notificationsList]);
    useEffect(() => {
        loadEditTrackedItem();
    }, [loadEditTrackedItem]);
    const saveTrackedItemHandler = (trackedItem, setErrors, resetForm) => {
        const selectedNotifications = JSON.parse(JSON.stringify(overWrittenNotificationList));
        selectedNotifications.forEach((notification) => {
            var _a;
            if (isEmpty(notification.companyId)) {
                notification.id = 0;
            }
            notification.companyId =
                trackedItem.companyId !== -1
                    ? trackedItem.companyId
                    : trackedItem.siteId !== -1
                        ? trackedItem.siteId
                        : trackedItem.groupId;
            notification.referenceId = (params === null || params === void 0 ? void 0 : params.id) ? parseInt(params.id) : null;
            notification.recipient = !isEmpty(notification.recipient)
                ? (_a = notification.recipient) === null || _a === void 0 ? void 0 : _a.toString()
                : TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND;
        });
        trackedItem.notificationInstances = selectedNotifications;
        // clearAllTrackedItemsPerSite();
        if (trackedItem.id > 0) {
            const _a = Object.assign({}, trackedItem), { groupId, siteId, gradeRanges } = _a, editPayload = __rest(_a, ["groupId", "siteId", "gradeRanges"]);
            editPayload.companyId = _getCompanyId(editPayload.companyId, groupId, siteId);
            editPayload.systemCreated = isSystemCreated;
            editTrackedItem(editPayload).then((response) => {
                if (!(response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION)) {
                    notifications.success(localize(["TrackedItem.trackedItem", "updated", "successfully"]));
                    resetForm();
                    history.push(trackedItemsListingRoute);
                }
            });
            return false;
        }
        else {
            const _b = Object.assign({}, trackedItem), { groupId, siteId, gradeRanges } = _b, addPayload = __rest(_b, ["groupId", "siteId", "gradeRanges"]);
            addPayload.companyId = _getCompanyId(addPayload.companyId, groupId, siteId);
            addPayload.systemCreated = isSystemCreated;
            addTrackedItem(addPayload).then((response) => {
                if (response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                    setErrors({
                        name: localize(["TrackedItem.trackedItem", "alreadyExist!"]),
                    });
                }
                notifications.success(localize(["TrackedItem.trackedItem", "created", "successfully"]));
                resetForm();
                history.push(trackedItemsListingRoute);
            });
        }
    };
    const _getCompanyId = (companyId, groupId, siteId) => companyId === -1 ? (siteId === -1 ? groupId : siteId) : companyId;
    const overwittenNotificationListHandler = (notificationList, resetFormAction = false) => {
        setOverWrittenNotificationList(notificationList);
        setNotificationsChanged(!resetFormAction);
    };
    const filterNotificationsListHandler = (notificationId, defaultNotification) => {
        if (defaultNotification) {
            setNotificationsList([...notificationsList].filter((obj) => obj.id !== notificationId));
        }
        setOverWrittenNotificationList([...overWrittenNotificationList].filter((obj) => obj.id !== notificationId));
        setNotificationsChanged(true);
    };
    if ((params === null || params === void 0 ? void 0 : params.id) && !((_a = location.state) === null || _a === void 0 ? void 0 : _a.trackedItemId)) {
        history.push(trackedItemsListingRoute);
    }
    //group/company/site
    const isClientAdmin = !isActionAllowed([
        REPORT_DEFAULTS_DELPHI_CONFIGURATION,
    ]);
    return (React.createElement("div", null,
        React.createElement(TrackedItemDefinitionForm, { intialTrackedItem: trackedItem, key: key, onSubmit: saveTrackedItemHandler, trackedItems: trackedItems, 
            // trackedItemTypes={trackedItemTypes}
            selectedGroup: selectedGroup, setSelectedGroup: setSelectedGroup, benchmarkDropdownOptions: benchmarkDropdownOptions, setBenchmarkDropdownOptions: setBenchmarkDropdownOptions, isClientAdmin: isClientAdmin, notificationsList: notificationsList, setNotificationsList: setNotificationsList, filterNotificationsListHandler: filterNotificationsListHandler, notificationsChanged: notificationsChanged, isSystemCreatedEdit: (_b = location.state) === null || _b === void 0 ? void 0 : _b.systemCreated, isSystemCreated: isSystemCreated, userBandOptions: userBandOptions, setOverWrittenNotificationList: overwittenNotificationListHandler, overWrittenNotificationList: overWrittenNotificationList, resetDefaultClientNotifications: resetDefaultClientNotifications, allowGroupNaItem: true, bandScales: bandScales, setBandScales: setBandScales, isHidden: isHidden })));
};
TrackedItemDefinitionFormContainer.propTypes = {};
function mapStateTopProps(state, ownProps) {
    // const trackedItemReducer = state.trackedItemTypes;
    return {
        // trackedItemTypes:
        //   SessionStorage.getKey(
        //     SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB
        //   ) === TrackedItemsVariables.SYSTEM_TRACKED_ITEM
        //     ? trackedItemReducer?.systemCreatedTrackedItems
        //     : SessionStorage.getKey(
        //         SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB
        //       ) === TrackedItemsVariables.HIDDEN_TRACKED_ITEM
        //     ? trackedItemReducer?.hiddenTrackedItem
        //     : trackedItemReducer?.userCreatedTrackedItem,
        bands: state.bands,
    };
}
const mapDispatchToProps = {
    addTrackedItem,
    editTrackedItem,
    getBand,
    getLookups,
    getTrackedItemsPerSite,
    resetDefaultClientNotifications,
    // getTrackedItemTypes,
    // getHiddenTrackedItemTypes,
    // getSystemCreatedTrackedItemTypes,
};
export default connect(mapStateTopProps, mapDispatchToProps)(TrackedItemDefinitionFormContainer);
