import { Card } from "antd";
import React from "react";
import { formatValueByConf } from "../../../../../../../core/helpers/chartYAxisLabel";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
const WatchlistRatingColumnComponent = ({ row = null, cardText = null, data, currencyFormat = false, showAllCards = false, }) => {
    var _a, _b, _c, _d;
    const previous = data === null || data === void 0 ? void 0 : data.previousRatingInfoDTO;
    const current = data === null || data === void 0 ? void 0 : data.currentRatingInfoDTO;
    if ((previous === null || previous === void 0 ? void 0 : previous.previousStatus) === null && (current === null || current === void 0 ? void 0 : current.currentStatus) === null) {
        return (React.createElement("div", { className: "bold-text content-center-aligned no-data watchlistContainer" }, "No Rating"));
    }
    return (React.createElement("div", { "data-test": "rating-card", style: { textAlign: "center" } },
        React.createElement("div", { className: "row align-items-center watchlistContainer" },
            React.createElement(React.Fragment, null,
                (previous === null || previous === void 0 ? void 0 : previous.previousStatus) &&
                    !isEmpty(previous === null || previous === void 0 ? void 0 : previous.previousStatusMovingAverage) && (React.createElement("div", { className: "previousRatingCard", style: { margin: "0px 5px" } },
                    React.createElement("h6", null,
                        React.createElement("span", null, "Previous "),
                        React.createElement("span", { style: { fontSize: "10px" } }, (previous === null || previous === void 0 ? void 0 : previous.daysUntilPreviousStatus)
                            ? `(${previous === null || previous === void 0 ? void 0 : previous.daysUntilPreviousStatus})`
                            : "")),
                    React.createElement(Card, { className: `${!showAllCards && "d-none"} ratingCard watchlistCard`, style: {
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            margin: "0px 2px",
                        } },
                        React.createElement("p", { className: "watchlistStatus", style: {
                                fontSize: "9px",
                                display: "flex",
                                flexWrap: "wrap",
                                width: "65px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "normal",
                                letterSpacing: "0.2px",
                            } }, cardText),
                        React.createElement("p", { className: "watchlistRating", style: {
                                backgroundColor: `${(previous === null || previous === void 0 ? void 0 : previous.previousStatusRgb)
                                    ? previous === null || previous === void 0 ? void 0 : previous.previousStatusRgb
                                    : "lightgray"}`,
                                fontSize: `${(data === null || data === void 0 ? void 0 : data.decimalPlaces) > 4
                                    ? "10px"
                                    : "clamp(8px, 0.8vw, 10px)"}`,
                            } }, !isEmpty(previous === null || previous === void 0 ? void 0 : previous.previousActualValue)
                            ? `${formatValueByConf(previous === null || previous === void 0 ? void 0 : previous.previousActualValue, data === null || data === void 0 ? void 0 : data.valueType, (_a = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _a !== void 0 ? _a : 2, currencyFormat)}`
                            : "-")),
                    React.createElement(Card, { className: "ratingCard watchlistCard", style: {
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            margin: "0px 2px",
                        } },
                        React.createElement("p", { className: "watchlistStatus" },
                            (previous === null || previous === void 0 ? void 0 : previous.previousStatus)
                                ? (data === null || data === void 0 ? void 0 : data.weight) !== 0
                                    ? // && !data?.weightFlag
                                        previous === null || previous === void 0 ? void 0 : previous.previousStatus
                                    : "-"
                                : "X",
                            React.createElement("p", { style: {
                                    fontSize: "9px",
                                    fontWeight: "normal",
                                    letterSpacing: "0.2px",
                                } }, (data === null || data === void 0 ? void 0 : data.weight) !== 0 ? "Item Rating" : "Unratable")),
                        React.createElement("p", { className: "watchlistRating", style: {
                                backgroundColor: `${(previous === null || previous === void 0 ? void 0 : previous.previousStatusRgb)
                                    ? previous === null || previous === void 0 ? void 0 : previous.previousStatusRgb
                                    : "lightgray"}`,
                                fontSize: `${(data === null || data === void 0 ? void 0 : data.decimalPlaces) > 4
                                    ? "10px"
                                    : "clamp(8px, 0.8vw, 10px)"}`,
                            } }, !isEmpty(previous === null || previous === void 0 ? void 0 : previous.previousBandPercentage)
                            ? (data === null || data === void 0 ? void 0 : data.weight) !== 0
                                ? `${Math.trunc(+(previous === null || previous === void 0 ? void 0 : previous.previousBandPercentage))}%`
                                : `${formatValueByConf(previous === null || previous === void 0 ? void 0 : previous.previousStatusMovingAverage, data === null || data === void 0 ? void 0 : data.valueType, (_b = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _b !== void 0 ? _b : 2)}`
                            : "-")))),
                React.createElement("div", { className: "currentRatingCard", style: { margin: "0px 5px" } },
                    React.createElement("h6", null,
                        React.createElement("span", null, "Current "),
                        React.createElement("span", { style: { fontSize: "10px" } }, (current === null || current === void 0 ? void 0 : current.daysUntilCurrentStatus)
                            ? `(${current === null || current === void 0 ? void 0 : current.daysUntilCurrentStatus})`
                            : " ")),
                    React.createElement(Card, { className: `${!showAllCards && "d-none"} ratingCard watchlistCard`, style: {
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            margin: "0px 2px",
                        } },
                        React.createElement("p", { className: "watchlistStatus", style: {
                                fontSize: "9px",
                                display: "flex",
                                flexWrap: "wrap",
                                width: "65px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "normal",
                                letterSpacing: "0.2px",
                            } }, cardText),
                        React.createElement("p", { className: "watchlistRating", style: {
                                backgroundColor: `${(current === null || current === void 0 ? void 0 : current.currentStatusRgb)
                                    ? current === null || current === void 0 ? void 0 : current.currentStatusRgb
                                    : "lightgray"}`,
                                fontSize: `${(data === null || data === void 0 ? void 0 : data.decimalPlaces) > 4 ? "10px" : "clamp(8px, 0.8vw, 10px)"}`,
                            } }, !isEmpty(current === null || current === void 0 ? void 0 : current.currentActualValue) && (current === null || current === void 0 ? void 0 : current.currentStatus)
                            ? `${formatValueByConf(current === null || current === void 0 ? void 0 : current.currentActualValue, data.valueType, (_c = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _c !== void 0 ? _c : 2, currencyFormat)}`
                            : "-")),
                    React.createElement(Card, { className: "ratingCard watchlistCard", style: {
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            margin: "0px 2px",
                        } },
                        React.createElement("p", { className: "watchlistStatus" },
                            (current === null || current === void 0 ? void 0 : current.currentStatus)
                                ? (data === null || data === void 0 ? void 0 : data.weight) !== 0
                                    ? // && !data?.weightFlag
                                        current === null || current === void 0 ? void 0 : current.currentStatus
                                    : "-"
                                : "X",
                            React.createElement("p", { style: {
                                    fontSize: "9px",
                                    fontWeight: "normal",
                                    letterSpacing: "0.2px",
                                } }, (data === null || data === void 0 ? void 0 : data.weight) !== 0 ? "Item Rating" : "Unratable")),
                        React.createElement("p", { className: "watchlistRating", style: {
                                backgroundColor: `${(current === null || current === void 0 ? void 0 : current.currentStatusRgb)
                                    ? current === null || current === void 0 ? void 0 : current.currentStatusRgb
                                    : "lightgray"}`,
                                fontSize: `${(data === null || data === void 0 ? void 0 : data.decimalPlaces) > 4 ? "10px" : "clamp(8px, 0.8vw, 10px)"}`,
                            } }, !isEmpty(current === null || current === void 0 ? void 0 : current.currentBandPercentage) &&
                            (current === null || current === void 0 ? void 0 : current.currentStatus)
                            ? (data === null || data === void 0 ? void 0 : data.weight) !== 0
                                ? `${Math.trunc(+(current === null || current === void 0 ? void 0 : current.currentBandPercentage))}%`
                                : `${formatValueByConf(current === null || current === void 0 ? void 0 : current.currentStatusMovingAverage, data === null || data === void 0 ? void 0 : data.valueType, (_d = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _d !== void 0 ? _d : 2)}`
                            : "-")))))));
};
export default WatchlistRatingColumnComponent;
