import { Input, Switch } from "antd";
import React from "react";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import localize from "../../../../../../../core/utils/translation-files";
import DecimalPlacesDropdown from "../../../../../../../layout/components/select/DecimalPlacesDropdown";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import Asterik from "../../../../../shared/helper-components/Asterik";
export const TrackedItemVariables = ({ setFieldValue, values, handleBlur, handleChange, errors, touched, isSystemCreatedEdit, isSystemCreated, handlSetFieldValue, onValueTypeChange, onWeightChange, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.chartVariables"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null, localize(["TrackedItem.yaxisLabel"]))),
                                React.createElement(Input, { type: "text", name: "yaxisLabel", placeholder: localize(["TrackedItem.yaxisLabel"]), onBlur: handleBlur, onChange: handleChange, value: values.yaxisLabel, 
                                    // disabled={values.id > 0}
                                    className: `${errors.yaxisLabel && touched.yaxisLabel
                                        ? "input-error"
                                        : null}` }),
                                errors.yaxisLabel && touched.yaxisLabel && (React.createElement("p", { className: "error" }, errors.yaxisLabel)))),
                        React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.valueType"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.valueType, inputName: "valueType", customOnChangeHandler: onValueTypeChange, localizationKey: ["value", "type"], lookUpType: LookupNames.LKReportValueType, disabled: values.id > 0, error: errors.valueType && touched.valueType
                                        ? errors.valueType
                                        : null })))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-3", hidden: isSystemCreated },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.groupWeight"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.groupWeight, onChange: setFieldValue, inputName: "groupWeight", localizationKey: ["Group.group", "TrackedItem.weight"], lookUpType: LookupNames.LKReportWeight, error: errors.groupWeight && touched.groupWeight
                                        ? errors.groupWeight
                                        : null }))),
                        React.createElement("div", { className: "col-md-3", hidden: isSystemCreated },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.companyWeight"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.companyWeight, onChange: setFieldValue, inputName: "companyWeight", localizationKey: ["Company.company", "TrackedItem.weight"], lookUpType: LookupNames.LKReportWeight, error: errors.companyWeight && touched.companyWeight
                                        ? errors.companyWeight
                                        : null }))),
                        React.createElement("div", { className: "col-md-3" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.weight"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.weight, onChange: setFieldValue, inputName: "weight", localizationKey: ["TrackedItem.site", "TrackedItem.weight"], lookUpType: LookupNames.LKReportWeight, error: errors.weight && touched.weight ? errors.weight : null }))),
                        React.createElement("div", { className: "col-md-3" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null, localize(["TrackedItem.displayValue"]))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.displayValue, onChange: setFieldValue, inputName: "displayValue", localizationKey: ["TrackedItem.displayValue"], lookUpType: LookupNames.LKDisplayValue, error: errors.displayValue && touched.displayValue
                                        ? errors.displayValue
                                        : null })))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-3", hidden: values.valueType ===
                                TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE ||
                                isSystemCreatedEdit },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.labels"]),
                                        React.createElement(Asterik, { hidden: values.valueType ===
                                                TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE }))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.chartUnit, onChange: setFieldValue, inputName: "chartUnit", localizationKey: ["TrackedItem.label"], lookUpType: LookupNames.LKChartDisplayUnit, error: errors.chartUnit && touched.chartUnit
                                        ? errors.chartUnit
                                        : null }))),
                        React.createElement("div", { className: "col-md-3", hidden: isSystemCreatedEdit },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.decimalPlaces"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(DecimalPlacesDropdown, { selectedValue: values.decimalPlaces, setSelectedValue: (value) => setFieldValue("decimalPlaces", value) }),
                                errors.decimalPlaces && touched.decimalPlaces && (React.createElement("p", { className: "error" }, errors.decimalPlaces)))),
                        React.createElement("div", { className: "col-3", hidden: values.valueType !==
                                TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER },
                            React.createElement("div", { className: `currencyFormatCol form-group` },
                                React.createElement("label", null, localize(["TrackedItem.currencyNumberFormat"])),
                                React.createElement(Switch, { style: { verticalAlign: "top" }, className: `ml-1 mb-1 `, checked: values.currencyFormat, onChange: (checked, event) => setFieldValue("currencyFormat", checked) })))))))));
};
export default TrackedItemVariables;
