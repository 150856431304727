import { Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { addTrackedItemTypeRoute } from "../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../core/utils/translation-files";
import Header from "../../../../../../layout/components/common/header";
import PrintButton from "../../../../../../layout/components/common/PrintButton";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import ListingSearch from "../../../../../../layout/components/listingSearch";
import { getLookups } from "../../../../../../redux/lookup/actions";
import SystemCreatedTypesListingContainer from "./system-created-tracked-item-type";
import UserCreatedTypesListingContainer from "./user-created-tracked-item-type";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { TrackedItemsVariables } from "../../../../../../core/constants/TrackedItemsConstants";
const ManageTrackedItemTypesContainer = ({ benchmarkListOptions, getLookups, }) => {
    const { TabPane } = Tabs;
    const [selectedTab, setSelectedTab] = useState(SessionStorage.getKey(SessionVariables.DEFAULTS_ACTIVE_TAB) || "1");
    // const hist = useHistory();
    // const { browserHistory } = useContext(browserHistoryMemoryContext);
    // const history = useMemo(() => {
    //   return browserHistory ?? hist;
    // }, [browserHistory, hist]);
    const handleChange = (activeKey) => {
        if (activeKey !== selectedTab) {
            setSelectedTab(activeKey);
            SessionStorage.setKey(SessionVariables.DEFAULTS_ACTIVE_TAB, activeKey);
            setSelectedTab(activeKey);
        }
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKReportTypeBenchmark],
            getLookups,
        });
    });
    useEffect(() => {
        setTimeout(() => {
            const storageActiveTab = SessionStorage.getKey(SessionVariables.DEFAULTS_ACTIVE_TAB);
            if (storageActiveTab !== selectedTab && !isEmpty(storageActiveTab)) {
                setSelectedTab(storageActiveTab);
            }
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isHidden = selectedTab === TrackedItemsVariables.HIDDEN_TRACKED_ITEM;
    const buttonText = () => {
        if (selectedTab === TrackedItemsVariables.USER_TRACKED_ITEM) {
            return localize(["add", "TrackedItem.default"]);
        }
        else if (selectedTab === TrackedItemsVariables.SYSTEM_TRACKED_ITEM) {
            return localize([
                "add",
                "TrackedItem.indexCategory",
                "TrackedItem.default",
            ]);
        }
        else {
            return localize([
                "add",
                // "TrackedItem.indexCategory",
                "TrackedItem.hiddenTrackedItem",
                "TrackedItem.default",
            ]);
        }
    };
    const { handlePrint, nodeRef } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Link, { to: {
                        pathname: addTrackedItemTypeRoute,
                    } },
                    React.createElement(Button, { htmlType: "button", type: "primary" },
                        React.createElement(RenderSvg, { icon: "AddDefault" }),
                        buttonText()))) }),
        React.createElement(Tabs, { className: 'defaults-tabs-container', defaultActiveKey: selectedTab, activeKey: selectedTab, onChange: handleChange },
            React.createElement(TabPane, { tab: "Tracked Items Default", key: TrackedItemsVariables.USER_TRACKED_ITEM, id: "tracked-item-definitions" }, selectedTab === TrackedItemsVariables.USER_TRACKED_ITEM && (React.createElement(UserCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions, isHidden: isHidden }))),
            React.createElement(TabPane, { tab: "Index/Category Defaults", key: TrackedItemsVariables.SYSTEM_TRACKED_ITEM, id: "tracked-item-type-defaults" },
                " ",
                selectedTab === TrackedItemsVariables.SYSTEM_TRACKED_ITEM && (React.createElement(SystemCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions }))),
            React.createElement(TabPane, { tab: "Tracked Item Components Default", key: TrackedItemsVariables.HIDDEN_TRACKED_ITEM, id: "hidden-tracked-item" },
                " ",
                selectedTab === TrackedItemsVariables.HIDDEN_TRACKED_ITEM && (React.createElement(UserCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions, isHidden: isHidden }))))));
};
function mapStateTopProps(state) {
    var _a, _b;
    return {
        benchmarkListOptions: (_b = (_a = state.lookups[LookupNames.LKReportTypeBenchmark]) === null || _a === void 0 ? void 0 : _a.map(({ text }) => text)) !== null && _b !== void 0 ? _b : [],
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(ManageTrackedItemTypesContainer);
