import { DEFAULT_SETTINGS } from "./settings/settings";
import { DEFAULT_CONTEXT } from "./context/context";
import { DEFAULT_ROOTVIEW } from "./rootView/rootView";
export const initialState = {
    settings: DEFAULT_SETTINGS,
    context: DEFAULT_CONTEXT,
    rootView: DEFAULT_ROOTVIEW,
    groups: [],
    companies: [],
    sites: [],
    roles: [],
    permissions: [],
    users: [],
    auditLogs: [],
    lookups: {
        LKNucleusModule: [],
        LKProfession: [],
        LKUserType: [],
        LKExternalUserStatus: [],
        LKReportParentGroup: [],
        LKItemCategory: [],
        LKReportitemSubCategory: [],
        LKItemRiskLevel: [],
        LKChartDisplayUnit: [],
        LKReportWeight: [],
        LKDisplayValue: [],
        LKChartDataFilter: [],
        LKReportDataFilters: [],
        LKReportType: [],
        LKTitle: [],
        LKRegisteredActivities: [],
        LKReportStatusColor: [],
        LKFinancialActivityHead: [],
        LKFinancialActivity: [],
        LKMonth: [],
        LKFinancialSystem: [],
        LKFinancialPnLHead: [],
        LKReportTypeBenchmark: [],
        LKReportTypeProjectionAverage: [],
        LKReportTypeBenchmarkDirection: [],
        LKReportTypeDataFrequency: [],
        LKReportValueType: [],
        LKReportHistoryFrequency: [],
        LKCompanyRegistrationType: [],
        LKPermissionCategory: [],
        LKUserBand: [],
        LKNotificationTimeFilter: [],
        LKNotificationReferenceType: [],
        LKUserReportType: [],
        LKUserReportName: [],
        LKNotificationCategory: [],
        LKWatchlistFilter: [],
        LKCollectiveNotificationFrequency: [],
        LKCollectiveNotificationSort: [],
        LKAuditActivityFilter: [],
        LKReportsTypeFilter: [],
    },
    pendingUsers: [],
    trackedItems: [],
    userCreatedtrackedItems: [],
    systemCreatedtrackedItems: [],
    hiddenTrackedItems: [],
    trackedItemsPerSite: [],
    trackedItemMenu: {},
    bands: [],
    togglePrint: {
        status: false,
        resolveHandler: (value) => undefined,
    },
    searchInput: null,
};
