import { GET_TRACKED_ITEM_TYPES_SUCCESS, ADD_TRACKED_ITEM_TYPE_SUCCESS, UPDATE_TRACKED_ITEM_TYPE_SUCCESS, INACTIVATE_TRACKED_ITEM_TYPE_SUCCESS, ACTIVATE_TRACKED_ITEM_TYPE_SUCCESS, EMPTY_TRACKED_ITEM_TYPE_SUCCESS, GET_SYSTEM_CREATED_TRACKED_ITEM_TYPES_SUCCESS, GET_TRACKED_ITEM_TYPE_BY_SITE_SUCCESS, GET_HIDDEN_TRACKED_ITEM_TYPES_SUCCESS, } from "./types";
const initialState = {
    systemCreatedTrackedItems: [],
    userCreatedTrackedItem: [],
    trackedItemBySites: [],
    hiddenTrackedItems: [],
};
export const trackedItemTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRACKED_ITEM_TYPES_SUCCESS: {
            return Object.assign(Object.assign({}, state), { userCreatedTrackedItem: action.payload });
        }
        case GET_SYSTEM_CREATED_TRACKED_ITEM_TYPES_SUCCESS: {
            return Object.assign(Object.assign({}, state), { systemCreatedTrackedItems: action.payload });
        }
        case GET_HIDDEN_TRACKED_ITEM_TYPES_SUCCESS: {
            return Object.assign(Object.assign({}, state), { hiddenTrackedItems: action.payload });
        }
        case GET_TRACKED_ITEM_TYPE_BY_SITE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { trackedItemBySites: action.payload });
        }
        case UPDATE_TRACKED_ITEM_TYPE_SUCCESS: {
            const payload = Object.assign({}, action.payload);
            const trackedItemType = (payload === null || payload === void 0 ? void 0 : payload.systemCreated)
                ? "systemCreatedTrackedItems"
                : (payload === null || payload === void 0 ? void 0 : payload.isHidden) ? "hiddenTrackedItems" : "userCreatedTrackedItem";
            const editedTrackedItemType = state[trackedItemType].map((el) => el.id !== payload.id ? el : payload);
            return Object.assign(Object.assign({}, state), { [trackedItemType]: editedTrackedItemType });
        }
        case ADD_TRACKED_ITEM_TYPE_SUCCESS: {
            const payload = Object.assign({}, action.payload);
            return (payload === null || payload === void 0 ? void 0 : payload.systemCreated) ? Object.assign(Object.assign({}, state), { systemCreatedTrackedItems: [
                    action.payload,
                    ...state.systemCreatedTrackedItems,
                ] }) : (payload === null || payload === void 0 ? void 0 : payload.isHidden) ? Object.assign(Object.assign({}, state), { hiddenTrackedItems: [
                    action.payload,
                    ...state.hiddenTrackedItems,
                ] }) : Object.assign(Object.assign({}, state), { userCreatedTrackedItem: [
                    action.payload,
                    ...state.userCreatedTrackedItem,
                ] });
        }
        case INACTIVATE_TRACKED_ITEM_TYPE_SUCCESS: {
            const payload = Object.assign({}, action.payload);
            const trackedItemType = (payload === null || payload === void 0 ? void 0 : payload.systemCreated)
                ? "systemCreatedTrackedItems"
                : (payload === null || payload === void 0 ? void 0 : payload.isHidden) ? "hiddenTrackedItems" : "userCreatedTrackedItem";
            const trackedItems = state[trackedItemType].map((el) => el.id !== payload.id ? el : Object.assign(Object.assign({}, el), { disabled: true }));
            return Object.assign(Object.assign({}, state), { [trackedItemType]: trackedItems });
        }
        case ACTIVATE_TRACKED_ITEM_TYPE_SUCCESS: {
            const payload = Object.assign({}, action.payload);
            const trackedItemType = (payload === null || payload === void 0 ? void 0 : payload.systemCreated)
                ? "systemCreatedTrackedItems"
                : (payload === null || payload === void 0 ? void 0 : payload.isHidden) ? "hiddenTrackedItems" : "userCreatedTrackedItem";
            const trackedItems = state[trackedItemType].map((el) => el.id !== payload.id ? el : Object.assign(Object.assign({}, el), { disabled: false }));
            return Object.assign(Object.assign({}, state), { [trackedItemType]: trackedItems });
        }
        case EMPTY_TRACKED_ITEM_TYPE_SUCCESS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
