var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { connect } from "react-redux";
import { ResponseStatusCodes } from "../../../../../../../core/constants/responseStatusCodes";
import { editTrackedItemTypeRoute } from "../../../../../../../core/constants/routesPath";
import localize from "../../../../../../../core/utils/translation-files";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import { notifications } from "../../../../../../../layout/components/notifications";
import { getTrackedItemTypes, inActivateTrackedItemType, activateTrackedItemType, getHiddenTrackedItemTypes, } from "../../../../../../../redux/tracked-item-type/actions";
import { getTrackedItemsPerSite } from "../../../../../../../redux/trackedItemsPerSite/actions";
import { UserCreatedtrackedItemsTypeTable } from "./components/userCreatedtrackedItemsTypeTable";
import useDidMount from "../../../../../../../core/hooks/useDidMount";
import UseCustomPagination from "../../../../../../../layout/components/hooks/useCustomPagination";
import { SessionStorage } from "../../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../../core/constants/SessionVariables";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
const UserCreatedTypesListingContainer = ({ getTrackedItemTypes, inActivateTrackedItemType, activateTrackedItemType, trackedItemTypes, benchmarkListOptions, getHiddenTrackedItemTypes, isPrintMode, isHidden, }) => {
    var _a;
    const history = useHistory();
    const _b = UseCustomPagination({
        totalRecords: (_a = trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    useDidMount(() => {
        if (trackedItemTypes.length === 0) {
            isHidden ? getHiddenTrackedItemTypes().catch((error) => {
                throw error;
            }) : getTrackedItemTypes().catch((error) => {
                throw error;
            });
        }
    });
    const inActivateTrackedItemTypeHandler = (data) => {
        inActivateTrackedItemType(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                notifications.success(localize(["TrackedItem.default", "disabled", "successfully"]));
            }
        });
    };
    const activateTrackedItemTypeHandler = (data) => {
        activateTrackedItemType(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                notifications.success(localize(["TrackedItem.default", "enabled", "successfully"]));
            }
        });
    };
    const editTrackedItemTypeRedirect = (trackedItem) => {
        history.push(editTrackedItemTypeRoute + trackedItem.id, {
            reportTypeId: trackedItem.id,
        });
    };
    const trackedItemTypeActions = (trackedItemType) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "TrackedItem.default"],
                onClick: editTrackedItemTypeRedirect.bind({}, trackedItemType),
                svgIcon: "Edit",
            },
            {
                priority: 1,
                title: trackedItemType.disabled === true
                    ? ["unArchive", "TrackedItem.default"]
                    : ["archive", "TrackedItem.default"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${trackedItemType.disabled === true ? ["unarchive"] : ["Archive"]}`,
                    "TrackedItem.thisDefault?",
                ],
                onConfirm: trackedItemType.disabled === true
                    ? activateTrackedItemTypeHandler.bind({}, trackedItemType)
                    : inActivateTrackedItemTypeHandler.bind({}, trackedItemType),
                children: (React.createElement(Switch, { className: "ml-1", checked: trackedItemType.disabled === false })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement(UserCreatedtrackedItemsTypeTable, { data: trackedItemTypes, actions: trackedItemTypeActions, benchmarkListOptions: benchmarkListOptions, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode })));
};
function mapStateTopProps(state) {
    var _a, _b, _c;
    const isHidden = SessionStorage.getKey(SessionVariables.DEFAULTS_ACTIVE_TAB) === TrackedItemsVariables.HIDDEN_TRACKED_ITEM;
    return {
        trackedItemTypes: isHidden ? (_a = state.trackedItemTypes) === null || _a === void 0 ? void 0 : _a.hiddenTrackedItems : (_b = state.trackedItemTypes) === null || _b === void 0 ? void 0 : _b.userCreatedTrackedItem,
        isPrintMode: (_c = state.togglePrint) === null || _c === void 0 ? void 0 : _c.status,
    };
}
const mapDispatchToProps = {
    getTrackedItemTypes,
    inActivateTrackedItemType,
    activateTrackedItemType,
    getTrackedItemsPerSite,
    getHiddenTrackedItemTypes
};
UserCreatedTypesListingContainer.propTypes = {};
export default connect(mapStateTopProps, mapDispatchToProps)(UserCreatedTypesListingContainer);
