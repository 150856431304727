import { Button, Tabs, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addTrackedItemsRoute, viewTrackedItemChartRoute, } from "../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../core/utils/translation-files";
import UserCreatedTrackedItemsListingContainer from "./user-created-tracked-items";
import SystemCreatedTrackedItemsListingContainer from "./system-created-tracked-items";
import isActionAllowed from "../../../../../../core/authorization/userPermissions";
import { REPORT_CREATION } from "../../../../../../core/constants/permissionConstants";
import UsePersistCustomFilters from "../../../../../../core/hooks/usePersistCustomFilters";
import FiltersDropdownMenu from "../../../../../../layout/components/select/FiltersDropdownMenu";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import SelectedListingFilters from "../../../../../../layout/components/selectedListingFilters";
import PrintButton from "../../../../../../layout/components/common/PrintButton";
import Header from "../../../../../../layout/components/common/header";
import ListingSearch from "../../../../../../layout/components/listingSearch";
import { useDispatch } from "react-redux";
import { clearSearchInputSuccess } from "../../../../../../redux/listingSearchInput/actions";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { TrackedItemsVariables } from "../../../../../../core/constants/TrackedItemsConstants";
const ManageTrackedItemContainer = (props) => {
    const { TabPane } = Tabs;
    const { setFilters, filters } = UsePersistCustomFilters({
        additionalPersistRoutes: [viewTrackedItemChartRoute],
        defaultValue: {},
    });
    // const hist = useHistory();
    // const { browserHistory } = useContext(browserHistoryMemoryContext);
    // const history = useMemo(() => {
    //   return browserHistory ?? hist;
    // }, [browserHistory, hist]);
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB) ||
        "1");
    const [isSubmitAction, setIsSubmitAction] = useState(false);
    const handleChange = (activeKey) => {
        if (activeKey !== selectedTab) {
            dispatch(clearSearchInputSuccess());
            setSelectedTab(activeKey);
            SessionStorage.setKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB, activeKey);
            setSelectedTab(activeKey);
        }
    };
    const handleChangeFilters = (filterValues) => {
        setFilters(Object.assign(Object.assign({}, filters), { trackedItem_filters: filterValues }));
    };
    const tabFilterValue = useCallback((tabKey) => { var _a; return (_a = filters === null || filters === void 0 ? void 0 : filters["trackedItem_filters"]) !== null && _a !== void 0 ? _a : {}; }, [filters]);
    const handleApplyFilter = () => {
        setIsSubmitAction(true);
    };
    const { handlePrint, nodeRef } = UsePrintPage();
    useEffect(() => {
        setTimeout(() => {
            const storageActiveTab = SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB);
            if (storageActiveTab !== selectedTab && !isEmpty(storageActiveTab)) {
                setSelectedTab(storageActiveTab);
            }
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isHidden = selectedTab === TrackedItemsVariables.HIDDEN_TRACKED_ITEM;
    const buttonText = () => {
        if (selectedTab === TrackedItemsVariables.USER_TRACKED_ITEM) {
            return localize(["add", "TrackedItem.trackedItem"]);
        }
        else if (selectedTab === TrackedItemsVariables.SYSTEM_TRACKED_ITEM) {
            return localize(["add", "TrackedItem.indexCategory"]);
        }
        else {
            return localize(["add", "TrackedItem.hiddenTrackedItem"]);
        }
    };
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { leftComponent: React.createElement(SelectedListingFilters, { filters: tabFilterValue(selectedTab) }), rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(FiltersDropdownMenu, { groupOptionValues: tabFilterValue("trackedItem_filters"), onChangeGroupOptions: handleChangeFilters, allowClearCompany: true, allowClearSite: true, onApply: handleApplyFilter }),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Link, { to: {
                        pathname: addTrackedItemsRoute,
                    }, hidden: !isActionAllowed([REPORT_CREATION]) },
                    React.createElement(Button, { htmlType: "button", type: "primary" },
                        React.createElement(RenderSvg, { icon: "AddUserTrackItem" }),
                        buttonText()))) }),
        React.createElement(Tabs, { className: "definition-tabs-container", defaultActiveKey: selectedTab, activeKey: selectedTab, onChange: handleChange },
            React.createElement(TabPane, { tab: "Tracked Items", key: TrackedItemsVariables.USER_TRACKED_ITEM, id: "tracked-item-definitions" }, selectedTab === TrackedItemsVariables.USER_TRACKED_ITEM && (React.createElement(UserCreatedTrackedItemsListingContainer, { filters: tabFilterValue("trackedItem_filters"), isSubmitAction: isSubmitAction &&
                    selectedTab === TrackedItemsVariables.USER_TRACKED_ITEM, setIsSubmitAction: setIsSubmitAction, isHidden: isHidden }))),
            React.createElement(TabPane, { tab: "Indices/Categories", key: TrackedItemsVariables.SYSTEM_TRACKED_ITEM, id: "tracked-item-types" }, selectedTab === TrackedItemsVariables.SYSTEM_TRACKED_ITEM && (React.createElement(SystemCreatedTrackedItemsListingContainer, { filters: tabFilterValue("trackedItem_filters"), isSubmitAction: isSubmitAction &&
                    selectedTab === TrackedItemsVariables.SYSTEM_TRACKED_ITEM, setIsSubmitAction: setIsSubmitAction }))),
            React.createElement(TabPane, { tab: React.createElement(Tooltip, { title: localize(["TrackedItem.hiddenItemsTooltip"]) }, "Tracked Item Components"), key: TrackedItemsVariables.HIDDEN_TRACKED_ITEM, id: "hidden-tracked-item-definition" }, selectedTab === TrackedItemsVariables.HIDDEN_TRACKED_ITEM && (React.createElement(UserCreatedTrackedItemsListingContainer, { filters: tabFilterValue("trackedItem_filters"), isSubmitAction: isSubmitAction &&
                    selectedTab === TrackedItemsVariables.HIDDEN_TRACKED_ITEM, setIsSubmitAction: setIsSubmitAction, isHidden: isHidden }))))));
};
export default ManageTrackedItemContainer;
