import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "notification";
export class NotificationSettingsApi {
    static setNotificationPreferences(area) {
        return httpPost(`${controllerEndpoint}/addOrupdate/userPreference`, {
            body: area,
        });
    }
    static getNotificationSettings() {
        return httpGet(`${controllerEndpoint}/getuserPreference`);
    }
}
