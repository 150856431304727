import React, { memo, useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Bar, ReferenceLine, } from "recharts";
import moment from "moment";
import { defaultDateFormat, defaultDateFormatForTinyChart, } from "../../../../core/constants/DateTime";
import HistoricalChartPopup from "./HistoricalChartPopup";
import HistoricalChartScale from "./HistoricalChartScale";
import RenderSvg from "../../icons/RenderSvg";
import isEmpty from "../../../../core/helpers/isEmpty";
import { convertDateToUnix, formatChartDate, } from "../../../../core/helpers/dateFormatter";
import { chartDataMinMaxPercentage, ChartYAxisLabel, } from "../../../../core/helpers/chartYAxisLabel";
import { useHistory } from "react-router-dom";
import { TrackedItemsVariables } from "../../../../core/constants/TrackedItemsConstants";
import { browserHistoryMemoryContext } from "../../../../core/contexts/browserHistoryMemoryContext";
import { DashboardApi } from "../../../../core/api/dashboardApi";
import { viewTrackedItemChart } from "../../../../core/helpers/dasboardRoute";
import { setTimeout } from "timers";
const HistoricalChart = ({ row = null, defaultData = null, data, isPopup = false, popupChartClass = "", frequency = -1, initialData = null, popupChartData = null, isDashboard = false, isListing = true, isDashboardViewAllowed = false, isFinancialViewAllowed = false, graphTitle = "Graph Detail", weight, weightFlag, }) => {
    var _a, _b, _c, _d;
    const [updatedData, setUpdatedData] = useState([]);
    const [chartData, setChartData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showScale, setShowScale] = useState(false);
    const [xaxisMaximum, setXaxisMaximum] = useState(null);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const hist = useHistory();
    const history = browserHistory !== null && browserHistory !== void 0 ? browserHistory : hist;
    // const {
    //   isSinglSite,
    //   isSingleCompany,
    //   isSingleCompanyAndSite,
    //   CompanyByGroup,
    //   SiteByCompany,
    // } = useDashboardHierarchy();
    const openDetailGraph = (e) => {
        var _a, _b, _c;
        const filters = {
            groupId: (_a = e.FinancialSheetFlag) === null || _a === void 0 ? void 0 : _a.parentGroupId,
            companyId: (_b = e.FinancialSheetFlag) === null || _b === void 0 ? void 0 : _b.parentCompanyId,
            siteId: (_c = e.FinancialSheetFlag) === null || _c === void 0 ? void 0 : _c.trackedItemCompanyId,
        };
        viewTrackedItemChart(e.itemId, filters, filters === null || filters === void 0 ? void 0 : filters.siteId, history, 
        // CompanyByGroup,
        // SiteByCompany,
        // isSingleCompanyAndSite,
        // isSinglSite,
        // isSingleCompany,
        undefined, undefined, undefined, undefined, undefined, isDashboardViewAllowed, isFinancialViewAllowed);
        // history.push(viewDashboardChartRoute + e.itemId, {
        //   filters: filters,
        //   trackedItemId: e.itemId,
        //   isSummary: false,
        // });
    };
    const dateFormatter = (value, dateFormat = "DD-MMM") => {
        let format = moment(value).format(dateFormat);
        return format;
    };
    const [opacity, setStrockOpacity] = useState({
        shortTermMA: 0,
        midTermMA: 0,
        longTermMA: 0,
        actualValue: 1,
        projectionLine: 1,
    });
    function useWindowSize() {
        const [size, setSize] = useState();
        useEffect(() => {
            const handleResize = () => {
                setSize(window.innerWidth);
            };
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);
        return size;
    }
    const width = useWindowSize();
    const [scrSize, setScrSize] = useState();
    const [showChart, setShowChart] = useState(true);
    useEffect(() => {
        if (!isPopup) {
            setTimeout(() => {
                setShowChart(false);
                setShowChart(true);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrSize]);
    useEffect(() => {
        setScrSize(width);
    }, [width]);
    useEffect(() => {
        if (isPopup) {
            setTimeout(() => {
                setShowScale(true);
            }, 600);
        }
        if (!isPopup && (data === null || data === void 0 ? void 0 : data.itemId)) {
            if (!isEmpty(defaultData)) {
                setShowScale(true);
                setChartData(defaultData);
                return;
            }
            setIsLoading(true);
            setShowScale(false);
            setChartData({});
            DashboardApi.getHistoricalChartData(data === null || data === void 0 ? void 0 : data.itemId, frequency, true).then((res) => {
                setChartData(res);
                setIsLoading(false);
                setTimeout(() => {
                    setShowScale(true);
                }, 600);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data === null || data === void 0 ? void 0 : data.itemId, frequency, row, defaultData]);
    useEffect(() => {
        var _a, _b, _c;
        if (!isEmpty(chartData === null || chartData === void 0 ? void 0 : chartData.data) && !isPopup) {
            const firstElemProjection = (_a = chartData === null || chartData === void 0 ? void 0 : chartData.projection) === null || _a === void 0 ? void 0 : _a.shift();
            const lastElemMA = (_b = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _b === void 0 ? void 0 : _b.pop();
            const mergedPoint = Object.assign(Object.assign({}, lastElemMA), firstElemProjection);
            const newArr = [...chartData === null || chartData === void 0 ? void 0 : chartData.data, mergedPoint];
            (_c = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _c === void 0 ? void 0 : _c.push(lastElemMA);
            setUpdatedData([...newArr, ...chartData === null || chartData === void 0 ? void 0 : chartData.projection]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);
    useEffect(() => {
        if (isPopup) {
            setUpdatedData(popupChartData);
            setChartData(initialData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const conData = updatedData === null || updatedData === void 0 ? void 0 : updatedData.map((value) => (Object.assign(Object.assign({}, value), { dataPointDate: convertDateToUnix(value === null || value === void 0 ? void 0 : value.dataPointDate), actualDate: value === null || value === void 0 ? void 0 : value.dataPointDate })));
    useEffect(() => {
        var _a;
        setXaxisMaximum((conData === null || conData === void 0 ? void 0 : conData.length) &&
            convertDateToUnix((_a = conData[conData.length - 1]) === null || _a === void 0 ? void 0 : _a.actualDate, defaultDateFormat));
    }, [conData]);
    const [movingAverageOptions] = useState([
        { name: "actualValue", color: "#F1C40F", value: "Actual Value" },
        { name: "shortTermMA", color: "#1089F9", value: "Short Term" },
        { name: "midTermMA", color: "#8E44AD", value: "Medium Term" },
        { name: "longTermMA", color: "#EA5455", value: "Long Term" },
    ]);
    const getMAValues = useCallback((code = null) => {
        var _a;
        const index = code !== null && code !== void 0 ? code : (chartData === null || chartData === void 0 ? void 0 : chartData.selectedMovingAverage) + 1;
        return ((_a = movingAverageOptions[index]) !== null && _a !== void 0 ? _a : {
            name: null,
            color: "black",
        });
    }, [chartData === null || chartData === void 0 ? void 0 : chartData.selectedMovingAverage, movingAverageOptions]);
    useEffect(() => {
        var _a;
        const selectedMA = (_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name;
        if (selectedMA) {
            setStrockOpacity((prev) => (Object.assign(Object.assign({}, prev), { [selectedMA]: 1 })));
        }
    }, [getMAValues]);
    const [visiblity, setVisibility] = useState(false);
    const chartPopupOpen = (event) => {
        event.stopPropagation();
        setVisibility(true);
    };
    const chartPopupCancel = (event) => {
        event.stopPropagation();
        setVisibility(false);
    };
    const CustomTooltip = memo(({ active, payload, label }) => {
        var _a;
        if (isPopup && active && payload && (payload === null || payload === void 0 ? void 0 : payload.length)) {
            const { payload: { dataPointDate, actualValue, shortTermMA, longTermMA, midTermMA, noOfDays, projectionLine, }, } = payload[0];
            const showProjectionTooltip = opacity.projectionLine &&
                !isEmpty(projectionLine) &&
                isEmpty(shortTermMA) &&
                isEmpty(midTermMA) &&
                isEmpty(longTermMA);
            return (React.createElement("div", { className: "historicalChart-tooltip tinyChart-tooltip" },
                React.createElement("div", null,
                    dateFormatter(dataPointDate, "DD-MMM-YYYY"),
                    " "),
                opacity.actualValue && !isEmpty(actualValue) ? (React.createElement("div", { style: { color: (_a = getMAValues(0)) === null || _a === void 0 ? void 0 : _a.color }, className: "mt-2" },
                    "Actual Value :",
                    " ",
                    ChartYAxisLabel(actualValue, chartData === null || chartData === void 0 ? void 0 : chartData.valueType, chartData === null || chartData === void 0 ? void 0 : chartData.chartUnit, chartData === null || chartData === void 0 ? void 0 : chartData.decimalPlaces, (chartData === null || chartData === void 0 ? void 0 : chartData.valueType) !==
                        TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null,
                opacity.actualValue && !isEmpty(actualValue) ? (React.createElement("div", { className: "mt-2" }, movingAverageOptions.map(({ name, color, value }) => {
                    var _a;
                    return ((_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name) === name &&
                        !isEmpty(eval(name)) &&
                        !showProjectionTooltip ? (React.createElement("div", { style: { color: color }, className: "mt-2" },
                        value,
                        " :",
                        " ",
                        ChartYAxisLabel(eval(name), chartData === null || chartData === void 0 ? void 0 : chartData.valueType, chartData === null || chartData === void 0 ? void 0 : chartData.chartUnit, chartData === null || chartData === void 0 ? void 0 : chartData.decimalPlaces, (chartData === null || chartData === void 0 ? void 0 : chartData.valueType) !==
                            TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null;
                }))) : null,
                showProjectionTooltip ? (React.createElement("div", { className: "mt-2" },
                    "Days: +",
                    noOfDays)) : null,
                showProjectionTooltip ? (React.createElement("div", { className: "mt-2" },
                    "Projection Line :",
                    " ",
                    ChartYAxisLabel(projectionLine, chartData === null || chartData === void 0 ? void 0 : chartData.valueType, chartData === null || chartData === void 0 ? void 0 : chartData.chartUnit, chartData === null || chartData === void 0 ? void 0 : chartData.decimalPlaces, (chartData === null || chartData === void 0 ? void 0 : chartData.valueType) !==
                        TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null));
        }
        return null;
    });
    const xAxisTickFormat = (props) => {
        var _a;
        const { x, y, payload, index, visibleTicksCount } = props;
        const chartFrequency = (_a = chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO) === null || _a === void 0 ? void 0 : _a.frequency;
        return (React.createElement("g", { transform: `translate(${x},${y})`, style: { fontWeight: "bold" }, className: "axis-font" },
            React.createElement("text", { x: 0, y: 0, dy: 5, textAnchor: "middle", fill: "#666", transform: "rotate(0)" }, isPopup
                ? chartFrequency === 2
                    ? formatChartDate(payload.value, "MMM-YY")
                    : // : chartFrequency === 1
                        // ? formatChartDate(payload.value, "MMM")
                        formatChartDate(payload.value, "DD-MMM")
                : (payload.value === miniChartXAxisTicks[0] ||
                    // payload.value ===
                    //   miniChartXAxisTicks[miniChartXAxisTicks.length - 1]
                    index === visibleTicksCount - 1) &&
                    (chartData === null || chartData === void 0 ? void 0 : chartData.sizeOfData) >= 6
                    ? formatChartDate(payload.value, "MMM-YY")
                    : formatChartDate(payload.value, "MMM"))));
    };
    const getMonthDifference = (startDate, endDate) => {
        return ((endDate === null || endDate === void 0 ? void 0 : endDate.getMonth()) -
            (startDate === null || startDate === void 0 ? void 0 : startDate.getMonth()) +
            12 * ((endDate === null || endDate === void 0 ? void 0 : endDate.getFullYear()) - (startDate === null || startDate === void 0 ? void 0 : startDate.getFullYear())));
    };
    const xaxisTicks = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        const reversedLabelsArray = (_c = (_b = (_a = chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO) === null || _a === void 0 ? void 0 : _a.labels) === null || _b === void 0 ? void 0 : _b.slice()) === null || _c === void 0 ? void 0 : _c.reverse();
        let ticks = [];
        let numOfMonths;
        if ((_e = (_d = chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO) === null || _d === void 0 ? void 0 : _d.labels) === null || _e === void 0 ? void 0 : _e.length) {
            numOfMonths =
                getMonthDifference(new Date(reversedLabelsArray[0]), new Date((_f = chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO) === null || _f === void 0 ? void 0 : _f.labels[0])) + 1;
        }
        return reversedLabelsArray === null || reversedLabelsArray === void 0 ? void 0 : reversedLabelsArray.map((rec) => {
            var _a;
            if (!isPopup &&
                !(ticks === null || ticks === void 0 ? void 0 : ticks.includes(new Date(rec).getMonth() + "/" + new Date(rec).getFullYear()))) {
                ticks = [
                    ...ticks,
                    new Date(rec).getMonth() + "/" + new Date(rec).getFullYear(),
                ];
                return ((_a = chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO) === null || _a === void 0 ? void 0 : _a.frequency) < 1 && numOfMonths > 2
                    ? new Date(rec).getDate() < 18
                        ? convertDateToUnix(rec, defaultDateFormatForTinyChart)
                        : ""
                    : convertDateToUnix(rec, defaultDateFormatForTinyChart);
            }
            else if (isPopup) {
                return convertDateToUnix(rec, defaultDateFormatForTinyChart);
            }
            return "";
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData === null || chartData === void 0 ? void 0 : chartData.chartLabelsDTO]);
    const miniChartXAxisTicks = xaxisTicks === null || xaxisTicks === void 0 ? void 0 : xaxisTicks.filter((value) => typeof value === "number");
    const customizedXLabel = (props) => {
        return (React.createElement("text", { offset: "0", y: isPopup ? "112%" : "105%", className: "recharts-text recharts-label", textAnchor: "center", fontSize: 16, fontWeight: "bold" },
            React.createElement("tspan", { x: "50%", dy: isPopup ? "-1em" : "1.8em" }, "Date")));
    };
    const yAxisLabelText = useMemo(() => {
        var _a;
        const unitText = (chartData === null || chartData === void 0 ? void 0 : chartData.chartUnit) === 1
            ? ``
            : (chartData === null || chartData === void 0 ? void 0 : chartData.chartUnitText) && `(${(_a = chartData === null || chartData === void 0 ? void 0 : chartData.chartUnitText) !== null && _a !== void 0 ? _a : ``})`;
        const finalYLabel = `${chartData === null || chartData === void 0 ? void 0 : chartData.yaxisLabel}  ${unitText}`.length >= 25 && !isPopup
            ? `${chartData === null || chartData === void 0 ? void 0 : chartData.yaxisLabel}  ${unitText}`.slice(0, 20) + "..."
            : `${chartData === null || chartData === void 0 ? void 0 : chartData.yaxisLabel}  ${unitText}`;
        return (chartData === null || chartData === void 0 ? void 0 : chartData.yaxisLabel) && (chartData === null || chartData === void 0 ? void 0 : chartData.yaxisLabel) !== "NULL"
            ? finalYLabel
            : `Y-axis ${unitText}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);
    const chartPadding = useMemo(() => {
        return {
            bottom: chartDataMinMaxPercentage(chartData, 7),
            top: chartDataMinMaxPercentage(chartData, 7),
        };
    }, [chartData]);
    const yAxisTickArray = () => {
        var _a, _b, _c, _d, _e;
        let array = [];
        let lowEnd = (_b = (_a = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList[0]) === null || _a === void 0 ? void 0 : _a.startPoint) !== null && _b !== void 0 ? _b : 0;
        let highEnd = (_e = (_d = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList[((_c = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList) === null || _c === void 0 ? void 0 : _c.length) - 1]) === null || _d === void 0 ? void 0 : _d.endPoint) !== null && _e !== void 0 ? _e : 1;
        if (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated)
            return isPopup
                ? [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
                : [0, 0.25, 0.5, 0.75, 1.0];
        if (lowEnd !== highEnd) {
            let margin = (highEnd - lowEnd) / 6;
            for (var i = lowEnd; i <= highEnd + margin * 0.01;) {
                array.push(i.toFixed(2));
                i = i + margin;
            }
        }
        // else {
        //   array = [0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0];
        // }
        return array;
    };
    const yAxisFormat = (props) => {
        const { x, y, payload } = props;
        return (React.createElement("g", { transform: `translate(${x},${y})`, className: "axis-font" },
            React.createElement("text", { x: 0, y: 0, dy: 5, textAnchor: "end", fill: "#666" }, (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated)
                ? ChartYAxisLabel(payload.value, 0, 1, chartData === null || chartData === void 0 ? void 0 : chartData.decimalPlaces)
                : ChartYAxisLabel(payload.value, chartData === null || chartData === void 0 ? void 0 : chartData.valueType, chartData === null || chartData === void 0 ? void 0 : chartData.chartUnit, chartData === null || chartData === void 0 ? void 0 : chartData.decimalPlaces
                // 2
                ))));
    };
    const freqText = {
        0: "Daily",
        1: "Weekly",
        3: "Monthly",
        4: "Yearly",
        5: "Quaterly",
    };
    const MAText = {
        0: "Short",
        1: "Medium",
        2: "Long",
    };
    const daysText = {
        0: "Day",
        1: "Week",
        3: "Month",
        4: "Year",
        5: "Quarter",
    };
    if (isEmpty(chartData === null || chartData === void 0 ? void 0 : chartData.data) && !isPopup) {
        return (React.createElement("div", { className: `content-center-aligned bold-text no-data` }, isLoading ? React.createElement(RenderSvg, { icon: "Loader" }) : "No Data"));
    }
    return (React.createElement(React.Fragment, null,
        showChart && (React.createElement("div", { className: `${popupChartClass} historicalChart` },
            React.createElement("div", { className: "row", style: {
                    alignItems: "stretch",
                    position: "relative",
                    height: "inherit",
                } },
                !isPopup && (React.createElement("div", { className: "content-center-aligned viewLargeGraph bold-text cursor-pointer", onClick: chartPopupOpen },
                    React.createElement(RenderSvg, { icon: "viewLargeGraph", style: { marginRight: "15px" } }),
                    "View Large Graph")),
                React.createElement("div", { className: "", style: {
                        position: "relative",
                        zIndex: 2,
                        width: `calc(100% - ${isPopup ? "35px" : isListing ? "28px" : "80px"})`,
                        minHeight: "120px",
                        marginLeft: isListing ? 0 : "32px",
                        // height: "120px",
                    } },
                    React.createElement(ResponsiveContainer, { height: "100%", width: "100%" },
                        React.createElement(ComposedChart, { data: conData, margin: { top: 0, right: 0, bottom: 0, left: 0 }, style: { cursor: isPopup ? "default" : "pointer" } },
                            React.createElement(CartesianGrid, { horizontal: true, vertical: true, stroke: "rgba(255,255,255,0.08)", fill: "#2b3b4a" }),
                            React.createElement(XAxis, { type: "number", dataKey: "dataPointDate", domain: ["dataMin", xaxisMaximum], 
                                // minTickGap={-80}
                                tick: Array.isArray(xaxisTicks) && (xaxisTicks === null || xaxisTicks === void 0 ? void 0 : xaxisTicks.length) > 0
                                    ? xAxisTickFormat
                                    : null, ticks: xaxisTicks, interval: !isPopup && (miniChartXAxisTicks === null || miniChartXAxisTicks === void 0 ? void 0 : miniChartXAxisTicks.length) > 17
                                    ? parseInt(((miniChartXAxisTicks === null || miniChartXAxisTicks === void 0 ? void 0 : miniChartXAxisTicks.length) / 8).toString())
                                    : (miniChartXAxisTicks === null || miniChartXAxisTicks === void 0 ? void 0 : miniChartXAxisTicks.length) > 8
                                        ? 1
                                        : 0, 
                                // interval={0}
                                padding: { left: 10, right: 10 }, height: 1, axisLine: false, tickLine: isPopup ? true : false, label: isPopup ? customizedXLabel : null }),
                            React.createElement(YAxis, { type: "number", interval: (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated) ? 0 : "preserveStartEnd", 
                                // height={isPopup ? 295 : 390}
                                domain: (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated)
                                    ? [0, 1]
                                    : [
                                        (_a = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList[0]) === null || _a === void 0 ? void 0 : _a.startPoint,
                                        (_c = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList[((_b = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList) === null || _b === void 0 ? void 0 : _b.length) - 1]) === null || _c === void 0 ? void 0 : _c.endPoint,
                                    ], 
                                // ticks={chartData?.systemCreated ? null : yAxisTickArray()}
                                padding: {
                                    top: isPopup ? 20 : 10,
                                    bottom: isPopup ? 20 : 10,
                                }, ticks: yAxisTickArray(), tick: yAxisFormat, minTickGap: 5, tickCount: (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated) && !isPopup && 5, axisLine: false, tickLine: false, width: isPopup ? 30 : 70, label: {
                                    value: yAxisLabelText,
                                    angle: -90,
                                    position: "middleLeft",
                                    dx: isPopup ? -65 : -40,
                                    dy: 15,
                                }, allowDataOverflow: false, fontSize: isPopup ? 14 : 8 }),
                            React.createElement(Bar, { dataKey: "actualValue", type: "linear", strokeWidth: 0, stroke: "#fff", fillOpacity: 1, width: 1, fill: "#fff", barSize: 2, minPointSize: 3 }),
                            movingAverageOptions.map(({ name, color }) => {
                                var _a;
                                return ((_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name) === name && (React.createElement(Line, { type: "linear", dataKey: name, strokeWidth: isPopup ? 2 : 1, stroke: "#00F0FF", fillOpacity: 1, dot: false, activeDot: isPopup, key: `${data === null || data === void 0 ? void 0 : data.itemId}-MA` }));
                            }),
                            React.createElement(Line, { type: "linear", dataKey: "projectionLine", strokeWidth: isPopup ? 2 : 1, stroke: "#FFC700", fillOpacity: 1, dot: false, activeDot: isPopup, strokeDasharray: "2 2 2 2", key: `${data === null || data === void 0 ? void 0 : data.itemId}-projection` }),
                            React.createElement(ReferenceLine, { stroke: "rgba(255,255,255,0.2)", strokeWidth: isPopup ? 3 : 2, y: (chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated)
                                    ? 0
                                    : (_d = chartData === null || chartData === void 0 ? void 0 : chartData.colorCodeList[0]) === null || _d === void 0 ? void 0 : _d.startPoint }),
                            React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null), cursor: isPopup })))),
                React.createElement("div", { className: " historicalChartScale", style: { width: `${isPopup ? "40px" : "30px"}` } }, showScale && (React.createElement(HistoricalChartScale, { data: chartData, itemData: data, isPopup: isPopup, showScale: showScale, percentageValuMinMax: chartPadding, weight: weight, weightFlag: weightFlag })))),
            React.createElement("div", { className: "historicalChartLabels row align-items-center justify-content-center" },
                React.createElement("p", null,
                    "Frequency : ",
                    React.createElement("span", null, freqText[chartData === null || chartData === void 0 ? void 0 : chartData.dataFrequency])),
                React.createElement("p", null,
                    "Moving Average :",
                    React.createElement("span", null,
                        " ",
                        MAText[chartData === null || chartData === void 0 ? void 0 : chartData.selectedMovingAverage],
                        " (", chartData === null || chartData === void 0 ? void 0 :
                        chartData.selectedMAPeriod,
                        " ",
                        daysText[chartData === null || chartData === void 0 ? void 0 : chartData.dataFrequency],
                        (chartData === null || chartData === void 0 ? void 0 : chartData.selectedMAPeriod) > 1 && "s",
                        ")"))))),
        React.createElement(HistoricalChartPopup, { initialData: chartData, openDetailGraph: openDetailGraph, visible: visiblity, popupChartData: updatedData, graphTitle: data === null || data === void 0 ? void 0 : data.itemName, chartPopupCancel: chartPopupCancel, frequency: frequency, data: data, row: row, showGraphLink: !(chartData === null || chartData === void 0 ? void 0 : chartData.systemCreated) && !isDashboard, isDashboardViewAllowed: isDashboardViewAllowed, isFinancialViewAllowed: isFinancialViewAllowed, weight: weight, weightFlag: weightFlag })));
};
export default HistoricalChart;
