import { GET_TRACKED_ITEMS_SUCCESS, GET_USER_CREATED_TRACKED_ITEMS_SUCCESS, GET_SYSTEM_CREATED_TRACKED_ITEMS_SUCCESS, ADD_TRACKED_ITEM_SUCCESS, UPDATE_TRACKED_ITEM_SUCCESS, INACTIVATE_TRACKED_ITEM_SUCCESS, ACTIVATE_TRACKED_ITEM_SUCCESS, EMPTY_TRACKED_ITEM_SUCCESS, GET_HIDDEN_TRACKED_ITEMS_SUCCESS, } from "./types";
const initialState = [];
export const trackedItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRACKED_ITEMS_SUCCESS: {
            return [...action.payload];
        }
        case GET_USER_CREATED_TRACKED_ITEMS_SUCCESS: {
            return [...action.payload];
        }
        case GET_HIDDEN_TRACKED_ITEMS_SUCCESS: {
            return [...action.payload];
        }
        case GET_SYSTEM_CREATED_TRACKED_ITEMS_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_TRACKED_ITEM_SUCCESS: {
            const editedTrackedItem = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedTrackedItem];
        }
        case ADD_TRACKED_ITEM_SUCCESS: {
            return [Object.assign({}, action.payload), ...state];
        }
        case INACTIVATE_TRACKED_ITEM_SUCCESS: {
            const trackedItems = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { disabled: true }));
            return [...trackedItems];
        }
        case ACTIVATE_TRACKED_ITEM_SUCCESS: {
            const trackedItems = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { disabled: false }));
            return [...trackedItems];
        }
        case EMPTY_TRACKED_ITEM_SUCCESS: {
            return [...initialState];
        }
        default: {
            return state;
        }
    }
};
