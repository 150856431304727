import React, { useEffect, useRef } from "react";
import { dashboardNavigation } from "../../../../../core/helpers/dasboardRoute";
const RoadMapBreadcrumbs = ({ parentNode, breadCrumbList, categoryElement = undefined, containerClass = ``, }) => {
    const startIndex = useRef(0);
    const data = breadCrumbList !== null && breadCrumbList !== void 0 ? breadCrumbList : dashboardNavigation;
    useEffect(() => {
        if (parentNode) {
            startIndex.current = data.findIndex(({ category }) => {
                var _a;
                return (_a = parentNode === null || parentNode === void 0 ? void 0 : parentNode.split(" ")[1]) === null || _a === void 0 ? void 0 : _a.includes(category === null || category === void 0 ? void 0 : category.split(" ")[0].toLowerCase());
            });
        }
    }, [parentNode, data]);
    return (React.createElement(React.Fragment, null, React.createElement("div", { className: `roadmapBreadcrumbsContainer ${containerClass}` },
        React.createElement("div", { className: "breadcrum-bg mt-2" }, data === null || data === void 0 ? void 0 : data.map((breadCrumbItem, index) => {
            var _a;
            return ((parentNode === null || parentNode === void 0 ? void 0 : parentNode.includes("subcategory"))
                ? index > startIndex.current
                : index >= startIndex.current) && (React.createElement("div", { className: "breadcrumb-item" },
                React.createElement("div", { className: "watchlist-breadcrumb-text" },
                    React.createElement("span", { style: {
                            background: breadCrumbItem === null || breadCrumbItem === void 0 ? void 0 : breadCrumbItem.bandColor,
                            padding: "3px 8px",
                            fontWeight: "bold",
                            borderRadius: "4px",
                            marginLeft: "0px",
                        } },
                        React.createElement("span", { className: "breadcrmb-item-grade", style: (breadCrumbItem === null || breadCrumbItem === void 0 ? void 0 : breadCrumbItem.bandName) === "E"
                                ? { color: breadCrumbItem === null || breadCrumbItem === void 0 ? void 0 : breadCrumbItem.bandColor }
                                : { color: "white" } }, breadCrumbItem === null || breadCrumbItem === void 0 ? void 0 : breadCrumbItem.bandName)),
                    React.createElement("span", { className: "breadcrumb-label" }, (_a = categoryElement === null || categoryElement === void 0 ? void 0 : categoryElement(breadCrumbItem, index)) !== null && _a !== void 0 ? _a : breadCrumbItem === null || breadCrumbItem === void 0 ? void 0 : breadCrumbItem.category)),
                React.createElement("div", { className: "watchlist-breadcrumb-arrow-container" },
                    React.createElement("span", { className: "arrow" }))));
        })))));
};
export default RoadMapBreadcrumbs;
