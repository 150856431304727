import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
const AreaLoader = ({ area, customStyle, loadingText = ``, className = "", wrapperClassName = "" }) => {
    const { promiseInProgress } = usePromiseTracker({ area });
    if (!promiseInProgress)
        return React.createElement(React.Fragment, null);
    // return <>{loadingText || <Skeleton.Button active style={customStyle} />}</>;
    return (React.createElement(React.Fragment, null, loadingText || (React.createElement(Spin, { className: className, wrapperClassName: wrapperClassName, indicator: React.createElement(LoadingOutlined
        // style={{ fontSize: 12, color: customStyle.color }}
        , { 
            // style={{ fontSize: 12, color: customStyle.color }}
            spin: true }) }))));
};
export default AreaLoader;
