import store from "../../redux/configureStore";
import { SessionVariables } from "../constants/SessionVariables";
import { CompanyType } from "../enums/CompanyType";
import { SessionStorage } from "../helpers/localStorage/sessionStorage";
const isActionAllowed = (requiredPermissions, company = null) => {
    var _a;
    const { companyPermissionDTOs } = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.context;
    const companiesData = SessionStorage.getKey(SessionVariables.COMPANIES_LISTING_DATA);
    var userPermissions = [];
    if (company) {
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Site) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => {
                var _a, _b, _c;
                return x.companyId === (company === null || company === void 0 ? void 0 : company.id) ||
                    x.companyId === (company === null || company === void 0 ? void 0 : company.parentId) ||
                    x.companyId ===
                        (((_b = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.companies.find((x) => x.id === (company === null || company === void 0 ? void 0 : company.parentId))) === null || _b === void 0 ? void 0 : _b.parentId) ||
                            ((_c = companiesData.find((x) => x.id === (company === null || company === void 0 ? void 0 : company.parentId))) === null || _c === void 0 ? void 0 : _c.parentId)) ||
                    x.companyId === 0;
            });
        }
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Company) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => x.companyId === (company === null || company === void 0 ? void 0 : company.id) ||
                x.companyId === (company === null || company === void 0 ? void 0 : company.parentId) ||
                x.companyId === 0);
        }
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Group) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => x.companyId === (company === null || company === void 0 ? void 0 : company.id) || x.companyId === 0);
        }
        return userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.some((x) => requiredPermissions === null || requiredPermissions === void 0 ? void 0 : requiredPermissions.includes(x.permission));
    }
    return companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.some((x) => requiredPermissions === null || requiredPermissions === void 0 ? void 0 : requiredPermissions.includes(x.permission));
};
export default isActionAllowed;
