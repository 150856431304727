var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Empty } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import { reportsRoute } from "../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import RelatedNotificationsPopup from "../components/relatedNotificationsPopup";
import { flattenDeep } from "lodash";
// import useDashboardHierarchy from "../../../../../core/hooks/useDashboardHeirarchy";
import AddRemoveWatchListModal from "./components/addRemoveWatchList";
import TableTree from "./TableTree";
import { ReportsVariables } from "../../../../../core/constants/ReportConstants";
const { getKey, setKey, removeKey } = SessionStorage;
const updateTreeData = (list, key, children) => {
    return list === null || list === void 0 ? void 0 : list.map((node) => {
        if (node.key === key) {
            return Object.assign(Object.assign({}, node), { children });
        }
        if (node.children) {
            return Object.assign(Object.assign({}, node), { children: updateTreeData(node.children, key, children) });
        }
        return node;
    });
};
const updateTreeNode = (updatedNode, children, list) => {
    return list === null || list === void 0 ? void 0 : list.map((node) => {
        if (node.key === updatedNode.key) {
            return Object.assign(Object.assign({}, updatedNode), { children });
        }
        if (node.children) {
            return Object.assign(Object.assign({}, node), { children: updateTreeNode(updatedNode, children, node.children) });
        }
        return node;
    });
};
const RoadmapTree = ({ treeData, setTreeData, notificationCountListId, getNotificationCountBIdList, setNotificationCountListId, getSummaryFlagByTrackItemListAPI, 
// ids,
bandData, setBandData, count, setCount, roadmapData, financialData, summarySheetResponse, }) => {
    const [notificationModelVisibility, setnotificationModelVisibility] = useState(false);
    const [selectedRoadmapItem, setSelectedRoadmapItem] = useState({});
    const [selectedWatchlistModalItem, setWatchListModalItem] = useState({});
    const [expandedKeys, setExpandedKeys] = useState([]);
    // const { browserHistory } = useContext(browserHistoryMemoryContext);
    const history = useHistory();
    const { pathname } = useLocation();
    // const isExpandAll = useRef(false);
    const handleToggleWatchListModal = (param) => {
        setWatchListModalItem((prev) => ((param === null || param === void 0 ? void 0 : param.key) ? param : {}));
    };
    const openNotificationModal = (item) => {
        setSelectedRoadmapItem(item);
        setnotificationModelVisibility(true);
    };
    const closeNotificationModel = () => setnotificationModelVisibility(false);
    // const isExpandedAll = useRef(false);
    const onExpand = (expandedKeys, { node, expanded, nativeEvent }) => {
        expanded === true
            ? setExpandedKeys((prev) => [...prev, ...expandedKeys])
            : setExpandedKeys((prev) => prev.filter((e) => e !== (node === null || node === void 0 ? void 0 : node.key)));
        if ((node === null || node === void 0 ? void 0 : node.children) && expanded) {
            getNotificationCountBIdList(node === null || node === void 0 ? void 0 : node.children);
            if ((node === null || node === void 0 ? void 0 : node.category) === "Company") {
                getSummaryFlagByTrackItemList(node === null || node === void 0 ? void 0 : node.children);
            }
        }
    };
    const expandTrackItem = useRef(false);
    // const collapseAll = (e) => {
    //   setExpandedKeys([]);
    //   // expandTrackItem.current = false;
    // };
    // const loadAllData = async (data) => {
    //   data?.length &&
    //     (await data?.forEach(async (item) => {
    //       onExpand([item?.key], {
    //         node: item,
    //         expanded: true,
    //         nativeEvent: null,
    //       });
    //       !("children" in item)
    //         ? await onLoadData(item).then((res) => {
    //             loadAllData(res);
    //           })
    //         : loadAllData(item?.children);
    //     }));
    // };
    // const expandAll = async (e) => {
    //   if (expandTrackItem.current === false) {
    //     const resp = await onLoadData(treeData);
    //     loadAllData(resp);
    //     expandTrackItem.current = true;
    //   }
    //   setExpandedKeys(getAllKeys());
    //   isExpandAll.current = true;
    //   isExpandedAll.current = true;
    // };
    const getSummaryFlagByTrackItemList = (list, isExpandedAll = false) => {
        if (isExpandedAll) {
            list.forEach((current) => __awaiter(void 0, void 0, void 0, function* () {
                if (current.category === "Company" && current.children.length) {
                    getSummaryFlagByTrackItemList(current.children, false);
                    return;
                }
                else {
                    return getSummaryFlagByTrackItemList(current.children, isExpandedAll);
                }
            }));
        }
        else {
            getSummaryFlagByTrackItemListAPI(list);
        }
    };
    const onLoadData = ({ key, children, category, companyId, categoryId, subCategoryId, }) => {
        if (children) {
            return new Promise((resolve) => {
                resolve();
                return;
            });
        }
        if (category === "Subcategory") {
            return DashboardApi.getRoadMapTrackeditems(companyId, subCategoryId, true).then((res) => {
                //when some of tree expanded and click expand all tree data => 1 and notification list => 3
                if (treeData.length !== Object.keys(notificationCountListId).length) {
                    getNotificationCountBIdList(treeData, false);
                }
                // if (categoryId === TrackedItemsVariables.FinancialCategoryCode) {
                getSummaryFlagByTrackItemList(res);
                // }
                getNotificationCountBIdList(res);
                setTreeData((origin) => updateTreeData(origin, key, res));
            });
        }
        //group
        return DashboardApi.getRoadMapData(key, true).then((res) => {
            getNotificationCountBIdList(res, false);
            getSummaryFlagByTrackItemList(res, false);
            setTreeData((origin) => updateTreeData(origin, key, res));
            return res;
        });
    };
    const viewFinancialModule = (item, summarySheetData) => {
        var _a;
        if (!isEmpty(item && (item === null || item === void 0 ? void 0 : item.companyId))) {
            let companyFilter, siteFilter = undefined;
            if (summarySheetData.reportType === "Company") {
                companyFilter = item.companyId;
            }
            else if (summarySheetData.reportType !== "Company" &&
                summarySheetData.reportType !== "Group") {
                companyFilter = summarySheetData === null || summarySheetData === void 0 ? void 0 : summarySheetData.parentCompanyId;
                siteFilter = item.companyId;
            }
            SessionStorage.setKey(SessionVariables.FINANCIAL_MODULE, {
                filterValues: {
                    groupId: summarySheetData.reportType === "Group"
                        ? item.companyId
                        : summarySheetData === null || summarySheetData === void 0 ? void 0 : summarySheetData.parentGroupId,
                    companyId: companyFilter,
                    siteId: siteFilter,
                },
                tab: (summarySheetData === null || summarySheetData === void 0 ? void 0 : summarySheetData.reportTypeFilter.toString()) ===
                    ReportsVariables.BUDGET_VS_ACTUAL_FILTER
                    ? ReportsVariables.BUDGET_VS_ACTUAL_TAB
                    : "4",
                // summarySheetData.type === 3
                //   ? "1"
                //   : summarySheetData.type === 1
                //   ? "4"
                //   : summarySheetData.type === 4
                //   ? "3"
                //   : summarySheetData.type.toString(),
                tabFilter: (summarySheetData === null || summarySheetData === void 0 ? void 0 : summarySheetData.reportTypeFilter.toString()) ||
                    ReportsVariables.FINANCIAL_FILTER,
                type: summarySheetData.reportType,
                parentKey: summarySheetData.parentKey &&
                    ((_a = summarySheetData.parentKey) !== null && _a !== void 0 ? _a : undefined),
            });
            history.push(reportsRoute);
        }
    };
    const roadmapActionButton = (item) => {
        var _a;
        const isSummaryItem = !!((_a = summarySheetResponse[item.key]) === null || _a === void 0 ? void 0 : _a.isSummaryItem);
        const btnList = [
            {
                priority: 1,
                title: ["Dashboard.addRemoveWatchlist"],
                svgIcon: (item === null || item === void 0 ? void 0 : item.hasWatchList) ? "AddedToWatchlist" : "WatchlistButton",
                onClick: (e) => handleToggleWatchListModal(item),
                iconStyle: { color: "#1B998F" },
            },
            {
                priority: 2,
                title: ["Dashboard.viewFinancialSheet"],
                svgIcon: "FinancialSheet",
                onClick: (e) => viewFinancialModule(item, summarySheetResponse[item.key]),
                isAllowed: isSummaryItem,
            },
        ];
        return (React.createElement(TableActions, { actionArray: btnList }));
    };
    // const getAllKeys = (data = treeData) => {
    //   const nestedKeys = data?.map((node) => {
    //     let childKeys = [];
    //     if (node.children) {
    //       childKeys = getAllKeys(node.children);
    //     }
    //     return [childKeys, node.key];
    //   });
    //   return flattenDeep(nestedKeys);
    // };
    const getAllParents = (key, groupId, compId, tree = treeData) => {
        var _a, _b;
        let parentObj;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentObj = {
                        path: node.category === "Category"
                            ? `/${node.categoryId}`
                            : node.category === "Subcategory"
                                ? `/${node.subCategoryId}`
                                : node.category === "Tracked Item"
                                    ? `/${node.key}`
                                    : `/${node.companyId}`,
                        name: node.title,
                        category: `${node.category} ${node.className !== "trackedItemNode" ? "Dashboard" : ""}`,
                        bandName: !(node === null || node === void 0 ? void 0 : node.weightFlag) && (node === null || node === void 0 ? void 0 : node.weight) !== 0 ? (_a = node.band) !== null && _a !== void 0 ? _a : "X" : "E",
                        bandColor: (_b = node.bandColor) !== null && _b !== void 0 ? _b : "darkgray",
                        parentPath: getRouteByCategory(node, groupId, compId),
                        key: node.key,
                        companyId: node.companyId,
                        // weight : node.weight,
                        // weightFlag : node.weightFlag,
                    };
                    if (!node.className.includes("parent")) {
                        return flattenDeep([
                            getAllParents(node.key, groupId, compId),
                            parentObj,
                        ]);
                    }
                }
                else if (getAllParents(key, groupId, compId, node.children)) {
                    parentObj = getAllParents(key, groupId, compId, node.children);
                }
            }
        }
        return parentObj;
    };
    // const {
    //   isSinglSite,
    //   isSingleCompany,
    //   isSingleCompanyAndSite,
    //   CompanyByGroup,
    //   SiteByCompany,
    // } = useDashboardHierarchy();
    const getRouteByCategory = (v, groupId, companyId) => {
        // const grpId = groupId ?? (v.category === "Group" && v.companyId);
        // const compId =
        //   companyId ??
        //   (v.category === "Company"
        //     ? v.companyId
        //     : v.category === "Group" && CompanyByGroup(v.companyId)?.companyId);
        // const siteId = SiteByCompany(grpId, compId)?.companyId;
        return v.category === "Tracked Item"
            ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}/${v.subCategoryId}/${v.key}`
            : v.category === "Subcategory"
                ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}/${v.subCategoryId}`
                : v.category === "Category"
                    ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}`
                    : v.category === "Site"
                        ? `/${groupId}/${companyId}/${v.companyId}`
                        : v.category === "Company"
                            ? `/${groupId}/${companyId}`
                            : v.category === "Group" && `/${v.companyId}`;
    };
    const addBreadcrumb = (v, groupId, companyId) => {
        var _a, _b;
        return {
            path: `/${v.category === "Category"
                ? v.categoryId
                : v.category === "Subcategory"
                    ? v.subCategoryId
                    : v.className === "trackedItemNode"
                        ? v.key
                        : +v.companyId}`,
            name: v.title,
            category: `${v.category} ${v.className !== "trackedItemNode" ? "Dashboard" : ""}`,
            bandName: !(v === null || v === void 0 ? void 0 : v.weightFlag) && (v === null || v === void 0 ? void 0 : v.weight) !== 0 ? (_a = v.band) !== null && _a !== void 0 ? _a : "X" : "E",
            bandColor: (_b = v.bandColor) !== null && _b !== void 0 ? _b : "darkgray",
            parentPath: getRouteByCategory(v, groupId, companyId),
            key: v.key,
            companyId: v === null || v === void 0 ? void 0 : v.companyId,
            // weightFlag : v.weightFlag,
        };
    };
    useEffect(() => {
        const data = getKey(SessionVariables.TREE_EXPANDED_STATE, sessionStorage);
        const keys = getKey(SessionVariables.EXPANDED_KEYS, sessionStorage);
        if (!data && expandedKeys.length === 0 && keys) {
            DashboardApi.getRoadMapData(keys[0], true).then((res) => {
                // getNotificationCountBIdList(res, false);
                // getSummaryFlagByTrackItemList(res, false);
                setTreeData((origin) => updateTreeData(origin, keys[0], res));
                setExpandedKeys(keys);
                getNotificationCountBIdList(res);
                // if (res[0]?.category === "Company") {
                getSummaryFlagByTrackItemList(res);
                // }
                getDataForExpandedKeys(res, keys);
                return res;
            });
        }
        if (data && expandedKeys.length === 0) {
            setTreeData(data);
            keys && setExpandedKeys(keys);
            getNotificationCountBIdList(data);
            // if (data[0]?.category === "Company") {
            getSummaryFlagByTrackItemList(data);
            // }
            getDataForExpandedKeys(data, keys);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedKeys]);
    useEffect(() => {
        if (expandedKeys && expandedKeys.length !== 0) {
            removeKey(SessionVariables.TREE_EXPANDED_STATE, sessionStorage);
            removeKey(SessionVariables.EXPANDED_KEYS, sessionStorage);
        }
        SessionStorage.setKey(SessionVariables.LAST_EXPANDED_KEY, expandedKeys[expandedKeys.length - 1], sessionStorage);
    }, [expandedKeys]);
    // const onBackButtonEvent = (e) => {
    //   if (history.location.pathname.includes("dashboard")) {
    //     e?.preventDefault();
    //     const urlPath = window.location.pathname;
    //     const parts = urlPath
    //       .split("/")
    //       .filter((part) => !isNaN(Number(part)) && part !== "");
    //     const urlToBeCompared = `/${parts.join("/")}`;
    //     const breadCrumbArrays = SessionStorage.getKey(
    //       SessionVariables.BREADCRUMB_ARRAYS,
    //       sessionStorage
    //     );
    //     let matchingBreadcrumb;
    //     for (let i = 0; i < breadCrumbArrays.length; i++) {
    //       const breadcrumbArray = breadCrumbArrays[i];
    //       if (breadcrumbArray.length > 0) {
    //         const lastElement = breadcrumbArray[breadcrumbArray.length - 1];
    //         if (lastElement.parentPath === urlToBeCompared) {
    //           matchingBreadcrumb = breadcrumbArray;
    //           break; // Exit the loop once a match is found
    //         }
    //       }
    //     }
    //     if (matchingBreadcrumb) {
    //       SessionStorage.setKey(
    //         SessionVariables.BREADCRUMB,
    //         matchingBreadcrumb,
    //         sessionStorage
    //       );
    //     }
    //   }
    // };
    // useEffect(() => {
    //   window.addEventListener("popstate", onBackButtonEvent);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   return () => {
    //     setTimeout(() => {
    //       window.removeEventListener("popstate", onBackButtonEvent);
    //     }, 500);
    //   };
    // }, []);
    const getDataForExpandedKeys = (data, keys) => {
        (data === null || data === void 0 ? void 0 : data.length) &&
            (data === null || data === void 0 ? void 0 : data.forEach((item) => {
                if (keys.includes(item === null || item === void 0 ? void 0 : item.key)) {
                    if (item === null || item === void 0 ? void 0 : item.children) {
                        getNotificationCountBIdList(item === null || item === void 0 ? void 0 : item.children);
                        // if (item?.category === "Company") {
                        getSummaryFlagByTrackItemList(item === null || item === void 0 ? void 0 : item.children);
                        // }
                    }
                    if ((item === null || item === void 0 ? void 0 : item.category) === "Subcategory") {
                        return DashboardApi.getRoadMapTrackeditems(item === null || item === void 0 ? void 0 : item.companyId, item === null || item === void 0 ? void 0 : item.subCategoryId, true).then((res) => {
                            //when some of tree expanded and click expand all tree data => 1 and notification list => 3
                            if (treeData.length !== Object.keys(notificationCountListId).length) {
                                getNotificationCountBIdList(treeData, false);
                            }
                            getSummaryFlagByTrackItemList(res);
                            getNotificationCountBIdList(res);
                            setTreeData((origin) => updateTreeData(origin, item === null || item === void 0 ? void 0 : item.key, res));
                        });
                    }
                }
                getDataForExpandedKeys(item === null || item === void 0 ? void 0 : item.children, keys);
            }));
    };
    const navigateToDashboard = (v, holdingCompanyRecord) => {
        expandedKeys.length &&
            setKey(SessionVariables.TREE_EXPANDED_STATE, treeData, sessionStorage);
        // setKey(SessionVariables.EXPANDED_KEYS, expandedKeys, sessionStorage);
        // console.log(v.companyId, "v.companyId");
        DashboardApi.getParentData([v.companyId]).then((res) => {
            var _a;
            const { groupId, companyId } = res[v.companyId];
            const allParents = getAllParents(v.key, groupId, companyId);
            const parents = Array.isArray(allParents)
                ? allParents
                : allParents
                    ? Array(allParents)
                    : [];
            const breadCrumb = addBreadcrumb(v, groupId, companyId);
            if (!isEmpty(holdingCompanyRecord) && breadCrumb.parentPath) {
                holdingCompanyRecord.parentPath = breadCrumb.parentPath.concat(holdingCompanyRecord.parentPath);
            }
            const breadCrumbList = [
                ...parents,
                breadCrumb,
                holdingCompanyRecord,
            ].filter(Boolean);
            SessionStorage.setKey(SessionVariables.BREADCRUMB, breadCrumbList, sessionStorage);
            SessionStorage.setKey(SessionVariables.BUTTON_TEXT, "Overview", sessionStorage);
            // const ids = SessionStorage.getKey(
            //   SessionVariables.BREADCRUMB,
            //   sessionStorage
            // ).map((item) => parseInt(item.key));
            expandedKeys.length &&
                setKey(SessionVariables.EXPANDED_KEYS, 
                // ids?.slice(0, -1),
                expandedKeys, sessionStorage);
            history.push(`${pathname}${getRouteByCategory(v, groupId, companyId)}${(_a = holdingCompanyRecord === null || holdingCompanyRecord === void 0 ? void 0 : holdingCompanyRecord.path) !== null && _a !== void 0 ? _a : ``}`);
        });
    };
    const handleUpdateItemHasWatchList = (list, selectedTrackItemId, hasWatchList) => {
        return list.map((record) => {
            var _a;
            if ((record === null || record === void 0 ? void 0 : record.key) === selectedTrackItemId) {
                record.hasWatchList = hasWatchList;
            }
            else if (((_a = record.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                record.children = handleUpdateItemHasWatchList(record.children, selectedTrackItemId, hasWatchList);
            }
            return record;
        });
    };
    return (React.createElement("div", { className: "Roadmap" }, !(treeData === null || treeData === void 0 ? void 0 : treeData.length) ? (React.createElement(Empty
    // image={Empty.PRESENTED_IMAGE_SIMPLE}
    , null)) : (React.createElement("div", { style: { marginTop: "20px" } },
        React.createElement(TableTree, { data: [...treeData], onLoadDataTable: onLoadData, bandData: bandData, 
            // ids={ids}
            count: count, onExpandTable: onExpand, expandedKeys: [...expandedKeys], navigateToDashboard: navigateToDashboard, notificationCountListId: notificationCountListId, openNotificationModal: openNotificationModal, roadmapActionButton: roadmapActionButton, browserHistory: history, expandTrackItem: expandTrackItem, handleToggleWatchListModal: handleToggleWatchListModal, financialData: financialData }),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: handleToggleWatchListModal, selectedTrackItemId: selectedWatchlistModalItem === null || selectedWatchlistModalItem === void 0 ? void 0 : selectedWatchlistModalItem.key, onAddRemoveWatchList: (selectedTrackItemId, status) => {
                const treeDataState = JSON.parse(JSON.stringify(treeData));
                setTreeData(handleUpdateItemHasWatchList(treeDataState, selectedTrackItemId, status));
            } }),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedRoadmapItem === null || selectedRoadmapItem === void 0 ? void 0 : selectedRoadmapItem.key, visible: notificationModelVisibility, onClose: closeNotificationModel, selectedItem: selectedRoadmapItem, setSelectedItem: setSelectedRoadmapItem, browserHistory: history, updateTreeNode: updateTreeNode, setTreeData: setTreeData, setNotificationCountListId: setNotificationCountListId })))));
};
export default RoadmapTree;
