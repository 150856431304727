import dayjs from "dayjs";
import ActionsContainer from "./components/ActionsContainer";
import WatchlistPredictionChart from "../../../../../../layout/components/rechart/tiny-chart/WatchlistPredictionChart";
import CustomTable from "../../../../../../layout/components/customTable";
import HistoricalChart from "../../../../../../layout/components/rechart/tiny-chart/HistoricalChart";
import React from "react";
import WatchlistRatingColumnComponent from "./components/WatchlistRatingColumnComponent";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const WatchlistTable = ({ data, expandedRowParentRecord = null, handleCustomTablePropChange = null, isPrintMode = false, openNotificationModal = null, actions = null, predictionBandInfo = null, predictionBandResponse = null, isDashboardViewAllowed, isFinancialViewAllowed, }) => {
    const columns = [
        // {
        //   fieldName: "itemName",
        //   title: ["name"],
        //   className: "w-10 watchlistTitleColum ",
        //   width: 13,
        //   defaultSortOrder: "ascend",
        //   sortable: false,
        //   render: (row, data) => <WatchlistTitleContainer row={row} data={data} />,
        // },
        {
            fieldName: "data",
            title: ["Dashboard.currentRating"],
            className: "w-15 watchlistRatingColum",
            width: 25,
            align: "center",
            render: (row, data) => {
                var _a, _b, _c, _d, _e, _f, _g;
                return (React.createElement(WatchlistRatingColumnComponent, { data: expandedRowParentRecord, currencyFormat: !!((_a = data === null || data === void 0 ? void 0 : data.historicalData) === null || _a === void 0 ? void 0 : _a.currencyFormat), showAllCards: ((_b = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _b === void 0 ? void 0 : _b.trackedItem) ? true : false, cardText: ((_c = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _c === void 0 ? void 0 : _c.subCategory)
                        ? "Tracked Item Value"
                        : ((_d = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _d === void 0 ? void 0 : _d.category)
                            ? "SubCategory Value"
                            : ((_e = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _e === void 0 ? void 0 : _e.site)
                                ? "Category Value"
                                : ((_f = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _f === void 0 ? void 0 : _f.company)
                                    ? "Site Value"
                                    : ((_g = data === null || data === void 0 ? void 0 : data.breadCrumbs) === null || _g === void 0 ? void 0 : _g.group)
                                        ? "Company Value"
                                        : "Group Value" }));
            }
        },
        {
            fieldName: "historicalData",
            title: ["Dashboard.historicalPerformance"],
            className: "w-30 watchlistChartColum",
            width: 27,
            align: "center",
            render: (row, data) => {
                var _a;
                return (React.createElement(HistoricalChart, { data: Object.assign({ itemId: expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.itemId, itemName: (_a = expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.displayName) !== null && _a !== void 0 ? _a : expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.itemName }, data), row: row, defaultData: row, isDashboardViewAllowed: isDashboardViewAllowed, isFinancialViewAllowed: isFinancialViewAllowed, weight: row === null || row === void 0 ? void 0 : row.weight, weightFlag: row === null || row === void 0 ? void 0 : row.weightFlag }));
            },
        },
        {
            fieldName: "predictedBandInfo",
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "bold-content" }, "RodaBI"),
                "Prediction")),
            className: "w-30 watchlistPredictionColum",
            width: 27,
            align: "center",
            render: (data, row) => {
                var _a, _b, _c;
                return (React.createElement(React.Fragment, null,
                    React.createElement(WatchlistPredictionChart, { data: data, defaultData: data, row: Object.assign({}, expandedRowParentRecord), isWatchlist: true, currencyFormat: !!((_a = row === null || row === void 0 ? void 0 : row.historicalData) === null || _a === void 0 ? void 0 : _a.currencyFormat), weight: (_b = row === null || row === void 0 ? void 0 : row.historicalData) === null || _b === void 0 ? void 0 : _b.weight, weightFlag: (_c = row === null || row === void 0 ? void 0 : row.historicalData) === null || _c === void 0 ? void 0 : _c.weightFlag })));
            },
        },
        {
            fieldName: "historicalData",
            title: ["Dashboard.criticalNotifications"],
            className: "w-15 watchlistActionColum",
            width: 12,
            align: "center",
            render: (row, data) => (React.createElement(ActionsContainer, { row: row, data: Object.assign(Object.assign({}, expandedRowParentRecord), { historicalData: row }), openMessageModal: openNotificationModal })),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        className: "w-5",
        width: 5,
        align: "center",
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    // const { promiseInProgress } = usePromiseTracker({ area: "LoadMore" });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "customListing watchlistTable", "data-test": "watchlist-table" },
            React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: "any", tableWrapperClassName: "watchlist-table-wrapper", scroll: false, 
                // scrollPosition={45}
                clientPagination: false, rowClickHandler: undefined, customTablePropChange: handleCustomTablePropChange, searchAllColumn: false }))));
};
export default WatchlistTable;
