import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import isEmpty from "../../../../../core/helpers/isEmpty";
import usePermissions from "../../../../../core/hooks/usePermissions";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import ListingPermissionDropdown from "../../../../../layout/components/select/ListingPermissionDropdown";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
const { getKey, removeKey } = SessionStorage;
const ReportsFiltersModel = ({ visible, onClose, onSubmit, filters, setFilters, }) => {
    const { groupOptions } = usePermissions();
    const id = !SessionStorage.getKey(SessionVariables.FINANCIAL_MODULE) &&
        getKey(SessionVariables.LAST_EXPANDED_KEY, sessionStorage);
    const [filterValues, setFilterValues] = useState();
    // console.log(filters,filterValues,"dropdown filters")
    const getParentIdsByReportId = (itemId) => {
        if (itemId && itemId !== "undefined") {
            DashboardApi.getParentIdsByReportId(itemId, true).then((response) => {
                const updatedFilters = {
                    groupId: response === null || response === void 0 ? void 0 : response.groupId,
                    companyId: (response === null || response === void 0 ? void 0 : response.companyId) !== -1 ? response === null || response === void 0 ? void 0 : response.companyId : undefined,
                    siteId: (response === null || response === void 0 ? void 0 : response.siteId) !== -1 ? response === null || response === void 0 ? void 0 : response.siteId : undefined,
                };
                SessionStorage.setKey(SessionVariables.REPORTS_TAB_FILTER, updatedFilters, sessionStorage);
                setFilters(updatedFilters);
                // setFilters({
                //   groupId: response?.groupId,
                //   companyId:
                //     response?.companyId !== -1
                //       ? response?.companyId
                //       : groupOptions[0]?.listSimpleDataDTO[0]?.id,
                //   siteId:
                //     response?.siteId !== -1
                //       ? response?.siteId
                //       : groupOptions[0]?.listSimpleDataDTO[0]?.listSimpleDataDTO[0]?.id,
                // });
            });
        }
        removeKey(SessionVariables.LAST_EXPANDED_KEY, sessionStorage);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        if (SessionStorage.getKey(SessionVariables.FINANCIAL_MODULE))
            removeKey(SessionVariables.LAST_EXPANDED_KEY, sessionStorage);
        else {
            !isEmpty(id) && getParentIdsByReportId(id);
            const tabFilters = SessionStorage.getKey(SessionVariables.REPORTS_TAB_FILTER, sessionStorage);
            if ((groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) &&
                isEmpty(filters === null || filters === void 0 ? void 0 : filters.groupId) &&
                (id === null || id === "undefined")) {
                if (tabFilters === null) {
                    setFilters({
                        groupId: (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.id,
                        companyId: (_c = (_b = groupOptions[0]) === null || _b === void 0 ? void 0 : _b.listSimpleDataDTO[0]) === null || _c === void 0 ? void 0 : _c.id,
                        siteId: (_f = (_e = (_d = groupOptions[0]) === null || _d === void 0 ? void 0 : _d.listSimpleDataDTO[0]) === null || _e === void 0 ? void 0 : _e.listSimpleDataDTO[0]) === null || _f === void 0 ? void 0 : _f.id,
                    });
                }
                else {
                    setFilters(tabFilters);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupOptions, filters]);
    useEffect(() => {
        // console.log("2nd condition run initially",isEmpty(filterValues), isEmpty(filterValues?.groupId),!isEmpty(filters?.groupId))
        if ((isEmpty(filterValues) || isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId)) &&
            !isEmpty(filters === null || filters === void 0 ? void 0 : filters.groupId)) {
            // console.log("2nd condition run",filterValues,filters)
            SessionStorage.setKey(SessionVariables.REPORTS_TAB_FILTER, filters, sessionStorage);
            setFilterValues(filters);
        }
    }, [filterValues, filters]);
    const handleCancel = (event) => {
        onClose();
        setFilterValues(filters);
    };
    const checkIsHolding = (groupId) => {
        const matchingGroup = groupOptions.find((item) => item.id === groupId);
        if (matchingGroup) {
            return matchingGroup.isHoldingCompany;
        }
        return false;
    };
    const handleSelectedValue = (companyId, groupId, siteId) => {
        if (!isEmpty(groupId) && !isEmpty(companyId) && isEmpty(siteId)) {
            return companyId;
        }
        else if (!isEmpty(groupId) && !isEmpty(companyId) && !isEmpty(siteId)) {
            return siteId;
        }
        else if (!isEmpty(groupId) && checkIsHolding(groupId)) {
            return groupId;
        }
    };
    const handleApplyFilter = () => {
        const { groupId, companyId, siteId } = filterValues;
        const selectedOption = handleSelectedValue(companyId, groupId, siteId);
        onSubmit(selectedOption);
        SessionStorage.setKey(SessionVariables.REPORTS_TAB_FILTER, filterValues, sessionStorage);
        setFilters(filterValues);
    };
    useEffect(() => {
        // console.log("filter applied initially",JSON.stringify(filters),JSON.stringify(filterValues),JSON.stringify(filters) !== JSON.stringify(filterValues))
        if (JSON.stringify(filters) !== JSON.stringify(filterValues) && !isEmpty(filterValues)) {
            // console.log("run")
            setFilterValues(filters);
        }
    }, [filters]);
    return (React.createElement(CustomModal, { visible: visible, onCancel: handleCancel, maskClosable: false, destroyOnClose: true, footer: null, title: ["Report.changeSite"] },
        React.createElement("div", { className: "reportsFilter" },
            React.createElement(ListingPermissionDropdown, { onChangeGroupOptions: setFilterValues, groupOptionValues: filterValues, haveDefaultCompanyHandler: true }),
            React.createElement("div", { className: "d-flex justify-content-between settings-actions col-12 popup-button-list" },
                React.createElement(Button, { type: "default", onClick: handleCancel },
                    React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", onClick: handleApplyFilter, disabled: isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId) ||
                        (!isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId) &&
                            !checkIsHolding(filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId) &&
                            isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.companyId) &&
                            isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.siteId)) },
                    React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                    localize(["Button.submit"]))))));
};
export default ReportsFiltersModel;
