import React, { useState } from "react";
import { TrackedItemApi } from "../../../../../../../core/api/trackedItemApi";
import useDidMount from "../../../../../../../core/hooks/useDidMount";
import TrackedItemBandScale from "./trackedItemBandScale";
export default function TrackedItemBandScaleContainer({ visible, onClose, subCategory = 0, dashboardBandScale = undefined, trackedItemId = null, }) {
    const [bandScales, setBandScales] = useState(undefined);
    useDidMount(() => {
        if (dashboardBandScale === undefined &&
            bandScales === undefined) {
            TrackedItemApi.getTrackedItemScaleById(trackedItemId).then((res) => {
                setBandScales(res);
            });
        }
    });
    return (React.createElement(TrackedItemBandScale, { bandScales: dashboardBandScale !== null && dashboardBandScale !== void 0 ? dashboardBandScale : bandScales, visible: visible, onClose: onClose, subCategory: subCategory }));
}
