import { InfoCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
const RatingCount = ({ grades, data, level, hidden = false }) => {
    return (React.createElement(Space, { className: "rating-count", hidden: hidden },
        React.createElement("span", { className: "rating-title" },
            `${level} Items Rating`,
            React.createElement(InfoCircleOutlined, { style: {
                    marginLeft: "7px",
                } }),
            " "),
        grades &&
            (grades === null || grades === void 0 ? void 0 : grades.map(({ bandName, rgbColor }, index) => {
                var _a;
                return (React.createElement("div", { className: "rating-count-lable", style: {
                        borderColor: rgbColor + "33" || "lightGrey",
                        color: rgbColor !== null && rgbColor !== void 0 ? rgbColor : "lightGrey",
                    } },
                    React.createElement("span", { className: "ratingGradeBg", style: { background: rgbColor !== null && rgbColor !== void 0 ? rgbColor : "lightGrey" } }, ((_a = data[0]) === null || _a === void 0 ? void 0 : _a.weight) !== 0
                        ? // && !data[0]?.weightFlag
                            bandName
                        : ""),
                    React.createElement("span", { style: { fontWeight: "bold" } }, data[0] ? data[0][bandName] : 0)));
            })))
    //   <div className="selected_filter_name">
    //   <span className="ratingGradeBg">A</span>
    //   <span style={{ fontWeight: "bold" }}>
    //     {/* {totalData.darkGreen} */}133333
    //   </span>
    // </div>
    // <div className="selected_filter_name">
    //   <span className="ratingGradeBg">B</span>
    //   <span style={{ fontWeight: "bold" }}>{data[0]?.green}</span>
    // </div>
    // <div className="selected_filter_name">
    //   <span className="ratingGradeBg">C</span>
    //   <span style={{ fontWeight: "bold" }}>{data[0]?.amber}</span>
    // </div>
    // <div className="selected_filter_name">
    //   <span className="ratingGradeBg">D</span>
    //   <span style={{ fontWeight: "bold" }}>{data[0]?.red}</span>
    // </div>
    // <div className="row" style={{ marginTop: "10px" }}>
    //   <div className="col-lg-6 col-md-6">
    //     <div className="row">
    //       <div className="col-sm-3">
    //         <div className="rating-lable">
    //           <span style={{ fontWeight: "bold" }}>
    //             {`${level} Items Rating`}
    //             {/* <RenderSvg icon="Info" /> */}
    //             <InfoCircleOutlined
    //               style={{
    //                 // fontSize: "1.1rem",
    //                 marginLeft: "7px",
    //                 // ...(isViewChartInfoOpen && { color: `#17a99a` }),
    //               }}
    //             />
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-sm-1">
    //         <div className="rating-count-lable">
    //           <span className="ratingGradeBg">A</span>
    //           <span style={{ fontWeight: "bold" }}>
    //             {/* {totalData.darkGreen} */}133333
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-sm-1">
    //         <div className="rating-count-lable">
    //           <span className="ratingGradeBg">B</span>
    //           <span style={{ fontWeight: "bold" }}>
    //             {/* {totalData.green} */}1
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-sm-1">
    //         <div className="rating-count-lable">
    //           <span className="ratingGradeBg">C</span> &nbsp;{" "}
    //           <span style={{ fontWeight: "bold" }}>
    //             {/* {totalData.amber} */}0
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-sm-1">
    //         <div className="rating-count-lable">
    //           <span className="ratingGradeBg">D</span> &nbsp;{" "}
    //           <span style={{ fontWeight: "bold" }}>
    //             {/* {totalData.red} */}1
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-lg-6 col-md-4"></div>
    // </div>
    );
};
export default RatingCount;
