var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notifications } from "../../../../../layout/components/notifications";
import { CompanyType } from "../../../../../core/enums/CompanyType";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import { AddressType } from "../../../../../core/enums/AddressTypes";
import { useHistory } from "react-router-dom";
import { groupsRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import GroupForm from "../components/groupForm";
import { addGroup, getGroups } from "../../../../../redux/group/actions";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookups } from "../../../../../redux/lookup/actions";
import { defaultSiteDetail } from "../../../../../core/contracts/site/siteContracts";
const AddGroup = ({ addGroup, groups, getGroups, getLookups }) => {
    const history = useHistory();
    useEffect(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKCompanyRegistrationType],
            getLookups,
        });
        if (groups.length === 0) {
            loadGroups();
        }
    });
    const loadGroups = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getGroups().catch((error) => {
            throw error;
        });
    });
    const initialGroup = {
        id: 0,
        parentId: -1,
        name: "",
        type: CompanyType.Group,
        registrationNumber: "",
        companyRegistrationType: undefined,
        // companyRegistrationTypeText: "",
        contactName: "",
        primaryTelephoneType: TelephoneType.Mobile,
        primaryTelephoneNumber: "",
        secondaryTelephoneType: TelephoneType.Mobile,
        secondaryTelephoneNumber: "",
        emailAddress: "",
        siteDetail: defaultSiteDetail,
        addresses: [
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.RegisteredAddress,
            },
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.ContactAddress,
            },
        ],
        active: true,
        holdingCompany: false,
        externalReference: "",
    };
    const addGroupHandler = (group, resetForm) => {
        console.log({ group });
        addGroup(group).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                return;
            }
            else {
                resetForm();
                notifications.success(localize(["Group.group", "added", "successfully"]));
                history.push(groupsRoute);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(GroupForm, { onCancel: () => history.push(groupsRoute), onSubmit: addGroupHandler, submitText: "Save", group: initialGroup, groups: groups })));
};
function mapStateTopProps(state) {
    return {
        groups: state.groups,
    };
}
const mapDispatchToProps = {
    addGroup,
    getGroups,
    getLookups,
};
AddGroup.propTypes = {
    addGroup: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(AddGroup);
