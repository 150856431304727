import React, { useState, useEffect } from "react";
import { DashboardApi } from "../../../../core/api/dashboardApi";
import isEmpty from "../../../../core/helpers/isEmpty";
import "../../../../App.scss";
import RenderSvg from "../../icons/RenderSvg";
import { formatValueByConf } from "../../../../core/helpers/chartYAxisLabel";
import localize from "../../../../core/utils/translation-files";
const WatchlistPredictionChart = ({ data = null, defaultData = null, row = null, isWatchlist = false, isListing = true, weight, weightFlag, currencyFormat = false, }) => {
    var _a, _b;
    const [bandInfo, setBandInfo] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            !isWatchlist && setShowLoader(false);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getPredictedBandInfo = (list) => {
        const mapId = [list.itemId];
        if (mapId) {
            setShowLoader(true);
            DashboardApi.getPredictedBandInfo(mapId, true).then((response) => {
                setBandInfo(response);
                setShowLoader(false);
            });
        }
    };
    useEffect(() => {
        if (!isEmpty(defaultData) && !isEmpty(row === null || row === void 0 ? void 0 : row.itemId)) {
            setShowLoader(false);
            setBandInfo({ [row === null || row === void 0 ? void 0 : row.itemId]: defaultData });
        }
    }, [defaultData, row === null || row === void 0 ? void 0 : row.itemId]);
    useEffect(() => {
        if (isEmpty(defaultData)) {
            row && isWatchlist && getPredictedBandInfo(row);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultData]);
    // const previous = row?.previousRatingInfoDTO;
    const current = row === null || row === void 0 ? void 0 : row.currentRatingInfoDTO;
    const predicted = isWatchlist
        ? bandInfo === null || bandInfo === void 0 ? void 0 : bandInfo[row.itemId]
        : row === null || row === void 0 ? void 0 : row.predictedBandInfoDTO;
    var svgName, predictionColor;
    var predictionBand = (predicted === null || predicted === void 0 ? void 0 : predicted.predictedRating) && (predicted === null || predicted === void 0 ? void 0 : predicted.predictedRating.slice(-1));
    if ((predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) === "Possible") {
        predictionColor = "rgb(0, 176, 80)";
    }
    else if ((predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) === "Highly Likely") {
        predictionColor = "rgb(255, 0, 0)";
    }
    else if ((predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) === "Likely") {
        predictionColor = "rgb(255, 192, 0)";
    }
    if ((current === null || current === void 0 ? void 0 : current.currentStatus) !== null && predictionBand !== null) {
        svgName = `${current === null || current === void 0 ? void 0 : current.currentStatus}to${predictionBand}`;
    }
    else {
        svgName = "CtoC";
    }
    if (!predicted && showLoader) {
        return (React.createElement("div", { className: "predictionLoader" },
            React.createElement(RenderSvg, { icon: "Loader" })));
    }
    else if (isEmpty(predicted === null || predicted === void 0 ? void 0 : predicted.predictedRating)) {
        return (React.createElement("div", { className: "bold-text row justify-content-center align-items-center content-center-aligned no-data" },
            React.createElement("div", { style: {
                    whiteSpace: "break-spaces",
                    padding: "20px",
                    maxWidth: "400px",
                } }, localize(["TrackedItem.cannotIdentifyTrend"]))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row justify-content-center", style: isListing
                ? { alignItems: "stretch" }
                : { width: "fit-content", margin: 0 } },
            React.createElement("div", { className: "predictionChartContainer" },
                React.createElement("div", { className: "predictionSmallCardContainer row justify-content-between" },
                    React.createElement("div", { className: "currentRatingContainer predictionSmallCard" },
                        React.createElement("p", null, (current === null || current === void 0 ? void 0 : current.currentStatus) ? current === null || current === void 0 ? void 0 : current.currentStatus : "-"),
                        React.createElement("p", { style: {
                                backgroundColor: `${(current === null || current === void 0 ? void 0 : current.currentStatusRgb)
                                    ? current === null || current === void 0 ? void 0 : current.currentStatusRgb
                                    : "lightgray"}`,
                            } }, !isEmpty(current === null || current === void 0 ? void 0 : current.currentBandPercentage)
                            ? weight !== 0 && !weightFlag
                                ? `${Math.trunc(+(current === null || current === void 0 ? void 0 : current.currentBandPercentage))}%`
                                : `${formatValueByConf(current === null || current === void 0 ? void 0 : current.currentStatusMovingAverage, data === null || data === void 0 ? void 0 : data.valueType, (_a = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _a !== void 0 ? _a : 2, currencyFormat)}`
                            : "-")),
                    React.createElement("div", { className: "predictedRatingContainer predictionSmallCard" },
                        React.createElement("p", null, predictionBand ? predictionBand : "-"),
                        React.createElement("p", { style: {
                                backgroundColor: `${(predicted === null || predicted === void 0 ? void 0 : predicted.rgbColor) ? predicted === null || predicted === void 0 ? void 0 : predicted.rgbColor : "lightgray"}`,
                            } }, !isEmpty(predicted === null || predicted === void 0 ? void 0 : predicted.predictedBandPercentage)
                            ? weight !== 0 && !weightFlag
                                ? `${Math.trunc(+(predicted === null || predicted === void 0 ? void 0 : predicted.predictedBandPercentage))}%`
                                : `${formatValueByConf(predicted === null || predicted === void 0 ? void 0 : predicted.predictedValue, data === null || data === void 0 ? void 0 : data.valueType, (_b = data === null || data === void 0 ? void 0 : data.decimalPlaces) !== null && _b !== void 0 ? _b : 2, currencyFormat)}`
                            : "-"))),
                React.createElement(RenderSvg, { icon: svgName, style: { width: "100%", height: "100%" }, className: !(weight !== 0 && !weightFlag) && "specificClass" }),
                React.createElement("p", { className: "nextDays" },
                    "Next",
                    " ",
                    (current === null || current === void 0 ? void 0 : current.currentStatus) === predictionBand &&
                        isEmpty(predicted === null || predicted === void 0 ? void 0 : predicted.daysUntilPredicatedStatus)
                        ? (row === null || row === void 0 ? void 0 : row.reactionTime) * (predicted === null || predicted === void 0 ? void 0 : predicted.multiple)
                        : predicted === null || predicted === void 0 ? void 0 : predicted.daysUntilPredicatedStatus,
                    " ",
                    (predicted === null || predicted === void 0 ? void 0 : predicted.daysUntilPredicatedStatus) === 1 ? "Day" : "Days")),
            React.createElement("div", { className: "predictionRatings bold-text row align-items-center justify-content-center" },
                React.createElement("div", null,
                    React.createElement("div", { className: "bold-text" }, "Probability"),
                    (predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) && (React.createElement("div", { className: "predictionCheckIcon row align-items-center justify-content-center", style: {
                            backgroundColor: (predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus)
                                ? predictionColor
                                : "white",
                        } },
                        React.createElement(RenderSvg, { icon: "CheckMark", style: {} }))),
                    React.createElement("div", { style: {
                            color: (predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) ? predictionColor : "black",
                        } }, (predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus) ? predicted === null || predicted === void 0 ? void 0 : predicted.strengthStatus : "-"))))));
};
export default WatchlistPredictionChart;
