import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import isEmpty from "../../../core/helpers/isEmpty";
import isActionAllowed from "../../../core/authorization/userPermissions";
import { DASHBOARD_VIEWER, SITE_EDIT, } from "../../../core/constants/permissionConstants";
import { CompanyType } from "../../../core/enums/CompanyType";
import SingleSelectNew from "./SingleSelectNew";
const CompanyDropdownContainer = ({ onChange, selectedValue, defaultValue, className, labelInValue, groupId, allowClear = false, allowEmpty = false, disabled = false, haveDefaultCompanyHandler = false, siteAdminHandler, placeHolder, error = null, }) => {
    const [companyOptions, setCompanyOptions] = useState([]);
    const groupOptions = useSelector((state) => state.groupOptions);
    const handleChange = (selectedValue, e) => {
        var _a, _b, _c;
        onChange(selectedValue, e);
        if (!isActionAllowed([SITE_EDIT], {
            type: CompanyType.Company,
            id: selectedValue,
            parentId: groupId,
        }) &&
            !isActionAllowed([DASHBOARD_VIEWER])) {
            const site = (_c = (_b = (_a = groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.find(({ id }) => id === groupId)) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === selectedValue)) === null || _c === void 0 ? void 0 : _c.listSimpleDataDTO[0];
            siteAdminHandler(site === null || site === void 0 ? void 0 : site.id, site === null || site === void 0 ? void 0 : site.name, selectedValue);
        }
    };
    useEffect(() => {
        var _a, _b;
        if (!isEmpty(groupId)) {
            const companyOptions = (_a = groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.find(({ id }) => id === groupId)) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO;
            setCompanyOptions(companyOptions);
            if (haveDefaultCompanyHandler && isEmpty(selectedValue) && !allowEmpty) {
                const { id, name } = (_b = companyOptions === null || companyOptions === void 0 ? void 0 : companyOptions[0]) !== null && _b !== void 0 ? _b : {};
                handleChange(id, { name });
            }
        }
        else {
            setCompanyOptions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, groupOptions]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "company-filter-dropdown", disabled: disabled, options: companyOptions, selectedValue: selectedValue, onChange: handleChange, defaultValue: defaultValue, valueProperty: "id", textProperty: "name", placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a Company", key: "companySelect", error: error, className: className, labelInValue: labelInValue, allowClear: allowClear })));
};
export default CompanyDropdownContainer;
