import React, { useEffect, useState, useMemo } from "react";
import WatchlistTable from "./watchlistTable/watchlistTable";
import { WatchlistApi } from "../../../../../core/api/watchlistApi";
import localize from "../../../../../core/utils/translation-files";
import isEmpty from "../../../../../core/helpers/isEmpty";
import "./watchlist.scss";
import { useHistory } from "react-router-dom";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import { notifications } from "../../../../../layout/components/notifications";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { DASHBOARD_VIEWER, REPORT_CATEGORY_CARE_FINANCIALS, USER_REPORTS, USER_REPORTS_FINANCIAL, } from "../../../../../core/constants/permissionConstants";
import { reportsRoute, unAuthorizedRoute, } from "../../../../../core/constants/routesPath";
import RelatedNotificationsPopup from "../../../shared/dashboard/components/relatedNotificationsPopup";
import PageDescription from "../../../../../layout/components/common/pageDescription";
import RoadMapBreadcrumbs from "../../../shared/dashboard/DashboardRoadmap/roadMapBreadcrumbs";
import { Tooltip } from "antd";
import { ReportsVariables } from "../../../../../core/constants/ReportConstants";
function WatchlistContainer({ isPrintMode, activeWatchListTab, expandedRowParentRecord, setParentData, navigateToWatchlistDashboard, parentDataLength, allData, }) {
    var _a;
    const [selectedItem, setSelectedItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const history = useHistory();
    const isReportViewAllowed = isActionAllowed([USER_REPORTS]);
    const isFinancialDataViewAllowed = isActionAllowed([USER_REPORTS_FINANCIAL]);
    const isDashboardViewAllowed = isActionAllowed([DASHBOARD_VIEWER]);
    const isFinancialViewAllowed = isActionAllowed([
        REPORT_CATEGORY_CARE_FINANCIALS,
    ]);
    const [myData, setMyData] = useState(undefined);
    const [breadCrumbData, setBreadCrumbData] = useState(undefined);
    const trackItemId = expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.itemId;
    useEffect(() => {
        if (!isEmpty(trackItemId)) {
            WatchlistApi.getWatchlistExpandedData(trackItemId, -1).then((res) => {
                if (!isEmpty(res === null || res === void 0 ? void 0 : res.historicalData)) {
                    setMyData([res]);
                }
                if (!isEmpty(res === null || res === void 0 ? void 0 : res.breadCrumbs)) {
                    setBreadCrumbData(res === null || res === void 0 ? void 0 : res.breadCrumbs);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackItemId]);
    const openNotificationModal = (item) => {
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    const removeTableAction = () => {
        document
            .querySelectorAll(".hideOnScroll")
            .forEach((item) => { var _a, _b; return (_b = (_a = item === null || item === void 0 ? void 0 : item.parentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add("d-none"); });
    };
    useEffect(() => {
        window.addEventListener("wheel", removeTableAction);
        return () => {
            window.removeEventListener("wheel", removeTableAction, false);
        };
    }, []);
    const removeFromWatchlist = (item) => {
        if (trackItemId) {
            // setData((origin) => origin.filter((el) => el.itemId !== item.itemId));
            WatchlistApi.removeFromWatchlist(trackItemId, activeWatchListTab.id).then((res) => {
                if (!res.error) {
                    setParentData((prev) => {
                        var _a;
                        return ({
                            totalRecords: (prev === null || prev === void 0 ? void 0 : prev.totalRecords) - 1,
                            dataList: (_a = prev === null || prev === void 0 ? void 0 : prev.dataList) === null || _a === void 0 ? void 0 : _a.filter(({ itemId }) => itemId !== trackItemId),
                        });
                    });
                    notifications.success(localize([
                        "Dashboard.removedFrom",
                        "Dashboard.watchList",
                        "successfully",
                    ]));
                    // let { dataList, totalRecords } = { ...myData };
                    // dataList = dataList.filter(({ itemId }) => itemId !== item.itemId);
                    // totalRecords -= 1;
                    // setMyData({ dataList, totalRecords });
                    // return notifications.success(
                    //   localize([
                    //     "Dashboard.removedFrom",
                    //     "Dashboard.watchList",
                    //     "successfully",
                    //   ])
                    // );
                }
            });
        }
    };
    const viewFinancialModule = (item) => {
        var _a, _b;
        const financialResponseData = item === null || item === void 0 ? void 0 : item.FinancialSheetFlag;
        if (!isEmpty(item && (financialResponseData === null || financialResponseData === void 0 ? void 0 : financialResponseData.trackedItemCompanyId))) {
            const { parentGroupId, parentCompanyId, trackedItemCompanyId, 
            // type,
            reportType, parentKey, } = financialResponseData;
            let companyFilter, siteFilter = undefined;
            if (reportType === "Company") {
                companyFilter = trackedItemCompanyId;
            }
            else if (reportType !== "Company" && reportType !== "Group") {
                companyFilter = parentCompanyId;
                siteFilter = trackedItemCompanyId;
            }
            SessionStorage.setKey(SessionVariables.FINANCIAL_MODULE, {
                filterValues: {
                    groupId: reportType === "Group" ? trackedItemCompanyId : parentGroupId,
                    companyId: companyFilter,
                    siteId: siteFilter,
                },
                tab: ((_a = item === null || item === void 0 ? void 0 : item.FinancialSheetFlag) === null || _a === void 0 ? void 0 : _a.reportTypeFilter.toString()) ===
                    ReportsVariables.BUDGET_VS_ACTUAL_FILTER
                    ? ReportsVariables.BUDGET_VS_ACTUAL_TAB
                    : "4",
                tabFilter: ((_b = item === null || item === void 0 ? void 0 : item.FinancialSheetFlag) === null || _b === void 0 ? void 0 : _b.reportTypeFilter.toString()) ||
                    ReportsVariables.FINANCIAL_FILTER,
                type: reportType,
                parentKey: parentKey !== null && parentKey !== void 0 ? parentKey : undefined,
            });
            isReportViewAllowed && isFinancialDataViewAllowed
                ? history.push(reportsRoute)
                : history.push(unAuthorizedRoute, {
                    subText: "permission",
                });
        }
    };
    const watchlistActionButtons = (item) => {
        var _a;
        const isSummaryItem = (_a = item === null || item === void 0 ? void 0 : item.FinancialSheetFlag) === null || _a === void 0 ? void 0 : _a.isSummaryItem;
        const isRemoevable = 
        // parentDataLength > 1 &&
        activeWatchListTab.itemsCanBeAddorRemoved;
        const actionArray = [
            {
                priority: 1,
                title: ["Dashboard.removeFromWatchlist"],
                svgIcon: "AddedToWatchlist",
                onClick: (e) => removeFromWatchlist(item),
                isAllowed: isRemoevable,
            },
            {
                priority: 2,
                title: ["Dashboard.viewFinancialSheet"],
                svgIcon: "FinancialSheet",
                onClick: (e) => viewFinancialModule(item),
                isAllowed: isSummaryItem,
            },
        ];
        return isRemoevable || isSummaryItem === true ? (React.createElement(TableActions, { actionArray: actionArray, wrapperClass: "watchlistActionButtons" })) : null;
    };
    const breadCrumbList = useMemo(() => {
        var _a, _b;
        if (myData === null || myData === void 0 ? void 0 : myData.length) {
            const breadCrumbCategoryObject = {
                Group: {
                    text: `Group Dashboard`,
                    priority: 1,
                },
                Company: {
                    text: `Company Dashboard`,
                    priority: 2,
                },
                Site: {
                    text: `Site Dashboard`,
                    priority: 3,
                },
                Category: {
                    text: `Category Dashboard`,
                    priority: 4,
                },
                Subcategory: {
                    text: `Sub Category Dashboard`,
                    priority: 5,
                },
                TrackedItem: {
                    text: `Tracked Item`,
                    priority: 6,
                },
            };
            let breadCrumbMapData = (_b = Object.entries((_a = myData[0]) === null || _a === void 0 ? void 0 : _a.breadCrumbs)) === null || _b === void 0 ? void 0 : _b.map((item) => ({
                category: item[1].category,
                categoryItemText: item[1].name,
                categoryText: breadCrumbCategoryObject[item[1].category.replace(/\s/g, "").toString()].text,
                bandName: item[1].weight !== 0
                    ? item[1].bandName // && !item[1].weightFlag
                    : "E",
                bandColor: item[1].rgbColor,
                weight: item[1].weight,
                weightFlag: item[1].weightFlag,
            }));
            breadCrumbMapData = [...breadCrumbMapData].sort((a, b) => breadCrumbCategoryObject[a.category.replace(/\s/g, "").toString()]
                .priority >
                breadCrumbCategoryObject[b.category.replace(/\s/g, "").toString()]
                    .priority
                ? 1
                : -1);
            return breadCrumbMapData;
            // return [...breadCrumbMapData]
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myData === null || myData === void 0 ? void 0 : myData.length]);
    const watchlistNavigationHandler = (val) => {
        const matchedObj = breadCrumbData.find((item) => item.category === val);
        const { id, category, companyId } = matchedObj;
        const record = {
            itemId: id,
            itemName: category,
            trackedItemCompanyId: companyId,
        };
        navigateToWatchlistDashboard(record);
    };
    return (React.createElement("div", { style: { margin: 0 }, className: "ant-table-expanded-row-custom-wrapper" },
        React.createElement("div", { className: "expanded-row-wrapper-content-container" },
            React.createElement(PageDescription, { description: expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.description }),
            React.createElement(RoadMapBreadcrumbs, { breadCrumbList: breadCrumbList, 
                // parentNode="parentNode groupNode"
                containerClass: "watchlist-breadcrumb-container", categoryElement: ({ category, categoryText, categoryItemText }, index) => (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "watchlist-breadcrumb-item", onClick: () => {
                            watchlistNavigationHandler(category);
                        } },
                        React.createElement(Tooltip, { overlayClassName: `breadcrumb-navigation-tooltip level-${index}`, title: React.createElement(React.Fragment, null,
                                " ",
                                React.createElement("div", null, categoryText),
                                React.createElement("div", null, categoryItemText)), placement: "top" },
                            React.createElement("div", null, categoryText),
                            React.createElement("div", null, categoryItemText))))) }),
            React.createElement(WatchlistTable, { key: (_a = expandedRowParentRecord === null || expandedRowParentRecord === void 0 ? void 0 : expandedRowParentRecord.notificationCount) === null || _a === void 0 ? void 0 : _a.toString(), data: myData !== null && myData !== void 0 ? myData : [], isPrintMode: isPrintMode, openNotificationModal: openNotificationModal, actions: watchlistActionButtons && watchlistActionButtons, expandedRowParentRecord: expandedRowParentRecord, isDashboardViewAllowed: isDashboardViewAllowed, isFinancialViewAllowed: isFinancialViewAllowed }),
            React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: history, setListData: setParentData }))));
}
export default WatchlistContainer;
