import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutActions } from "../../../redux/context/actions";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { ResetPasswordContainer } from "../../../views/global/ResetPassword/resetPasswordContainer";
import { taskRoute } from "../../../core/constants/routesPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import Logout from "../Logout";
import NotificationSettingsContainer from "../../../views/global/NotificationSettings/NotificationSettingsContainer";
import { LookupNames } from "../../../core/constants/lookup";
import { getLookups } from "../../../redux/lookup/actions";
import useDidMount from "../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../core/helpers/lookupHanlder";
import isActionAllowed from "../../../core/authorization/userPermissions";
const SettingsDropdown = ({ getLookups }) => {
    var _a, _b, _c, _d;
    const [currentKey] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [key] = useState(0);
    const [notificationSettingsModal, setNotificationSettingsModal] = useState(false);
    const [taskCount] = useState(undefined);
    const context = useSelector((state) => {
        return state.context;
    });
    const handleClick = () => {
        dispatch(logoutActions(history));
    };
    const onChangePassword = () => {
        setChangePasswordModal(true);
    };
    const changePasswordModalHandler = () => {
        setChangePasswordModal(false);
    };
    const notificationSettingsModalClose = () => {
        setNotificationSettingsModal(false);
    };
    const notificationSettingsModalOpen = () => {
        setNotificationSettingsModal(true);
    };
    const tabOptions = (_b = (_a = useSelector((state) => state.lookups[LookupNames.LKItemCategory])) === null || _a === void 0 ? void 0 : _a.filter(({ code, text, permissionCode }) => 
    // code !== "5" &&
    isActionAllowed(permissionCode)).sort((a, b) => a.code - b.code)) !== null && _b !== void 0 ? _b : [];
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKItemCategory],
            getLookups,
        });
    });
    const accountMenu = (React.createElement(Menu, { selectedKeys: [currentKey], style: { minWidth: "180px" }, className: "account-menu" },
        taskCount !== undefined && taskCount !== 0 && (React.createElement(Menu.Item, { key: "task", className: "action-item", onClick: () => {
                history.push(taskRoute);
            } },
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faTasks, size: "sm" }),
                " ",
                ` You have ${taskCount} due task`))),
        SessionStorage.getKey(SessionVariables.PASSWORD_EXPIRES_IN) && (React.createElement(Menu.Item, { key: "password-expiry-message", className: "action-item", style: { fontStyle: "italic", color: "#ffcc00", cursor: "auto" } },
            React.createElement("span", { className: "icofont-warning", key: key }, ` ${SessionStorage.getKey((_c = SessionVariables.PASSWORD_EXPIRES_IN) !== null && _c !== void 0 ? _c : "")}`))),
        React.createElement(Menu.Item, { key: "change-password", className: "action-item", onClick: onChangePassword },
            React.createElement("span", { className: "icofont-ui-password", key: key },
                `  Change Password`,
                " ")),
        React.createElement(Menu.Item, { key: "notification-settings", className: "action-item", onClick: notificationSettingsModalOpen },
            React.createElement("span", { className: "icofont-settings-alt", key: key },
                `  Notification Settings`,
                " ")),
        React.createElement(Menu.Item, { key: "logout", className: "action-item", onClick: handleClick },
            React.createElement("span", { className: "icofont-logout" },
                " ",
                `  Log Out`))));
    const stateParams = (_d = history.location.state) === null || _d === void 0 ? void 0 : _d.params;
    const parameters = stateParams && new URLSearchParams(stateParams);
    const isNotiRedirect = parameters && (!!parameters.get("isNotification") || false);
    useEffect(() => {
        if (isNotiRedirect &&
            history.location.pathname === "/vertical/notifications-events") {
            notificationSettingsModalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { overlay: accountMenu, trigger: ["click"], placement: "bottomRight" },
            React.createElement("div", { className: "item" },
                React.createElement("span", null,
                    React.createElement("strong", null, context.userFullName)),
                React.createElement("span", { className: "icofont-simple-down" }))),
        notificationSettingsModal && (React.createElement(NotificationSettingsContainer, { notificationSettingsModal: notificationSettingsModal, notificationSettingsModalClose: notificationSettingsModalClose, modalTitle: "Notification Settings", modalDescription: "Change Notification Settings", tabOptions: tabOptions })),
        React.createElement(ResetPasswordContainer, { changePasswordModal: changePasswordModal, setChangePasswordModal: changePasswordModalHandler, modalTitle: "Change Password", modalDescription: "Change your password that suits you well" }),
        React.createElement(Logout, null)));
};
const mapDispatchToProps = {
    getLookups,
};
export default connect(null, mapDispatchToProps)(SettingsDropdown);
