import React, { useEffect, useState } from "react";
import { Checkbox, Input, Select, Tabs } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { mobileNumberRegex, numberRegex, onlyAlpabeticCharactersRegex, registerationNoRegex, telephoneNumberRegex, ukPostCodeRegex, } from "../../../../../core/utils/regex";
import PropTypes from "prop-types";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import RegisterAddress from "../../../../../layout/components/address/RegisterAddress";
import ContactAddress from "../../../../../layout/components/address/ContactAddress";
import { useTranslation } from "react-i18next";
import localize from "../../../../../core/utils/translation-files";
import { inputDefaultLength, telephoneNumberDefaultLength, } from "../../../../../core/utils/default-length";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import ConditionalViewFormField from "../../../../../layout/components/conditionalViewFormField";
import telephoneTypeByValue from "../../../../../core/utils/telephoneTypeByValue";
import PromptComponent from "../../../../../layout/components/prompt-message";
import FormActionButtons from "../../../../../layout/components/common/FormsActionButtons";
import Asterik from "../../../shared/helper-components/Asterik";
import { useSelector } from "react-redux";
import UseToggleStateOnScroll from "../../../../../core/hooks/useToggleStateOnScroll";
import LookupDropDown from "../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../core/constants/lookup";
import XeroFormFields from "../../site-details/xeroFormFields";
import SiteDetailsFormFields from "../../site-details/siteDetailsFormFields";
import { isUniqueExternalReference } from "../../../../../core/validations/companiesValidation";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
const Option = Select.Option;
const defaultSubmitText = "Add Company";
const optionFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
const contactTypes = [
    {
        value: TelephoneType.Mobile,
        name: "Mobile",
    },
    {
        value: TelephoneType.Landline,
        name: "Landline",
    },
];
const { TabPane } = Tabs;
const CompanyForm = ({ submitText = defaultSubmitText, company, onSubmit, onCancel, companies, isEdit = false, isReadOnly = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const [t] = useTranslation();
    const [isUniqueER, setIsUniqueER] = useState(true);
    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, resetForm, dirty, setErrors, } = useFormik({
        validationSchema: Yup.object({
            name: Yup.string()
                .required(localize(["Company.company", "name", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            registrationNumber: Yup.string()
                .required(localize(["Company.registration", "number", "isRequired!"]))
                .matches(registerationNoRegex.regex, registerationNoRegex.message)
                .max(8, localize(["mustBe", "lessThan", "8", "characters"])),
            weight: Yup.number().required(localize(["TrackedItem.weight", "isRequired!"])),
            externalReference: Yup.string()
                .required(localize(["Company.apiCompanyId", "isRequired!"]))
                .matches(numberRegex.regex, numberRegex.message)
                .test("check", localize(["TrackedItem.apiId", "alreadyExist!"]), function (value) {
                return isUniqueER;
            })
                .nullable(),
            siteDetail: Yup.object().shape({
                financialMonth: Yup.string()
                    .required(localize(["Site.financialYearEnd", "isRequired!"]))
                    .nullable()
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ]))
                    .nullable(),
                financialSystem: Yup.string()
                    .required(localize(["Site.financialSystem", "isRequired!"]))
                    .nullable(),
                ingestionYear: Yup.string()
                    .required(localize(["Site.ingestionStartYear", "isRequired!"]))
                    .nullable(),
                tenantId: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.tenantId", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                accessToken: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.accessToken", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                refreshToken: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.refreshToken", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                clientId: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.clientId", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                clientSecret: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.clientSecret", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
            }),
            contactName: Yup.string()
                .required(localize(["contact", "Company.person", "name", "isRequired!"]))
                .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message)
                .max(50, localize(["Company.lengthMustNotBeGreaterThan50"])),
            primaryTelephoneType: Yup.number().required(localize(["Company.telephoneType", "isRequired!"])),
            primaryTelephoneNumber: Yup.string().when("primaryTelephoneType", {
                is: TelephoneType.Landline,
                then: Yup.string()
                    .required(localize(["primary", "contact", "number", "isRequired!"]))
                    .matches(telephoneNumberRegex.regex, telephoneNumberRegex.message)
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .required(localize(["primary", "contact", "number", "isRequired!"]))
                    .matches(mobileNumberRegex.regex, mobileNumberRegex.message)
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
            }),
            secondaryTelephoneNumber: Yup.string().when("secondaryTelephoneType", {
                is: TelephoneType.Landline,
                then: Yup.string()
                    .matches(telephoneNumberRegex.regex, t(telephoneNumberRegex.message))
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .matches(mobileNumberRegex.regex, t(mobileNumberRegex.message))
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
            }),
            emailAddress: Yup.string()
                .required(localize(["email", "address", "isRequired!"]))
                .email(localize(["enterAValid", "email", "address"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            addresses: Yup.array().of(Yup.object().shape({
                name: Yup.string()
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                line1: Yup.string()
                    .required(localize(["Company.line1", "isRequired!"]))
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"]))
                    .max(75, localize(["mustBe", "lessThan", "75", "characters"])),
                line2: Yup.string()
                    .max(75, localize(["mustBe", "lessThan", "75", "characters"]))
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"])),
                town: Yup.string()
                    .required(localize(["Company.town", "isRequired!"]))
                    .max(25, localize(["mustBe", "lessThan", "25", "characters"]))
                    .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message),
                county: Yup.string()
                    .max(25, localize(["mustBe", "lessThan", "25", "characters"]))
                    .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message),
                postCode: Yup.string()
                    .required(localize(["Company.postCode", "isRequired!"]))
                    .matches(ukPostCodeRegex.regex, ukPostCodeRegex.message)
                    .min(6, localize(["mustBe", "greaterThan", "6", "characters"]))
                    .max(8, localize(["mustBe", "lessThan", "8", "characters"])),
            })),
        }),
        initialValues: company,
        onSubmit: (values) => {
            onSubmit(values, resetForm, setErrors);
        },
    });
    const [key, setKey] = useState(0);
    const [selectedTab, setSelectedTab] = useState("1");
    const checkUniqueER = () => {
        if (values.externalReference)
            setIsUniqueER(isUniqueExternalReference(values === null || values === void 0 ? void 0 : values.externalReference, company === null || company === void 0 ? void 0 : company.externalReference));
    };
    useEffect(() => { }, [errors, touched]);
    const { visible: primaryVisible, setVisible: setPrimaryVisible } = UseToggleStateOnScroll();
    const { visible: secondaryVisible, setVisible: setSecondaryVisible } = UseToggleStateOnScroll();
    const onSameAddressChange = (e) => {
        var _a, _b, _c, _d, _e, _f;
        if (e.target.checked) {
            setFieldValue(`addresses.${0}.type`, 1);
            setFieldValue(`addresses.${1}.name`, (_a = values.addresses[0]) === null || _a === void 0 ? void 0 : _a.name);
            setFieldValue(`addresses.${1}.line1`, (_b = values.addresses[0]) === null || _b === void 0 ? void 0 : _b.line1);
            setFieldValue(`addresses.${1}.line2`, (_c = values.addresses[0]) === null || _c === void 0 ? void 0 : _c.line2);
            setFieldValue(`addresses.${1}.town`, (_d = values.addresses[0]) === null || _d === void 0 ? void 0 : _d.town);
            setFieldValue(`addresses.${1}.county`, (_e = values.addresses[0]) === null || _e === void 0 ? void 0 : _e.county);
            setFieldValue(`addresses.${1}.postCode`, (_f = values.addresses[0]) === null || _f === void 0 ? void 0 : _f.postCode);
        }
        else {
            setFieldValue(`addresses.${0}.type`, 2);
            setFieldValue(`addresses.${1}.name`, "");
            setFieldValue(`addresses.${1}.line1`, "");
            setFieldValue(`addresses.${1}.line2`, "");
            setFieldValue(`addresses.${1}.town`, "");
            setFieldValue(`addresses.${1}.county`, "");
            setFieldValue(`addresses.${1}.postCode`, "");
            setFieldValue(`addresses.${1}.type`, 3);
        }
    };
    const handleTelephoneChange = (value) => {
        setFieldValue("primaryTelephoneType", value);
        setFieldValue("primaryTelephoneNumber", "");
    };
    const handleSecondaryTelephoneChange = (value) => {
        setFieldValue("secondaryTelephoneType", value);
        setFieldValue("secondaryTelephoneNumber", "");
    };
    const hide = useSelector((state) => state.togglePrint);
    const { handlePrint, nodeRef } = UsePrintPage(true);
    const group = SessionStorage.getKey(SessionVariables.COMPANY);
    const allowWeights = (!(group === null || group === void 0 ? void 0 : group.holdingCompany) && (group === null || group === void 0 ? void 0 : group.hasFinancialCategory));
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: `mb-1 customForm ${isReadOnly && `viewForm`}`, name: "basic", onSubmit: handleSubmit, key: key, id: "my-form", "data-test": "companyFormComponent" },
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement(Input, { name: "type", type: "hidden", value: "1" }),
            React.createElement(Input, { name: "parentId", type: "hidden" }),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["Company.basic", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["Company.company", "name"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }),
                                            " ")),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.name },
                                        React.createElement(Input, { type: "text", name: "name", placeholder: localize(["Company.company", "name"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.name, className: `${errors.name && touched.name ? "input-error" : null}` }),
                                        errors.name && touched.name && (React.createElement("p", { className: "error" }, errors.name))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["Company.registration", "number"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.registrationNumber },
                                        React.createElement(Input, { name: `registrationNumber`, placeholder: "NNNNNNNN or AANNNNNN", onBlur: handleBlur, onChange: handleChange, defaultValue: values.registrationNumber, className: `${errors.registrationNumber &&
                                                touched.registrationNumber
                                                ? "input-error"
                                                : null}` }),
                                        errors.registrationNumber &&
                                            touched.registrationNumber && (React.createElement("p", { className: "error" }, errors.registrationNumber))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null, localize(["Company.weight"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.weight },
                                        React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.weight, onChange: setFieldValue, inputName: "weight", localizationKey: ["Company.weight"], lookUpType: LookupNames.LKReportWeight, disabled: values.hasFinancialCategory, error: errors.weight && touched.weight ? errors.weight : null })))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["Company.apiCompanyId"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.externalReference },
                                        React.createElement(Input, { name: "externalReference", placeholder: localize(["Company.apiCompanyId"]), onChange: handleChange, defaultValue: values.externalReference, className: `${errors &&
                                                errors.externalReference &&
                                                touched &&
                                                touched.externalReference
                                                ? "input-error"
                                                : null}` }),
                                        errors &&
                                            errors.externalReference &&
                                            touched &&
                                            touched.externalReference && (React.createElement("p", { className: "error" }, errors && errors.externalReference)))))),
                        React.createElement(SiteDetailsFormFields, { values: values, errors: errors, touched: touched, handleChange: handleChange, setFieldValue: setFieldValue, isReadOnly: isReadOnly })))),
            ((_a = values.siteDetail) === null || _a === void 0 ? void 0 : _a.financialSystem) === 1 && (React.createElement(XeroFormFields, { values: values, errors: errors, touched: touched, handleChange: handleChange, isReadOnly: isReadOnly })),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["contact", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["contact", "Company.person", "name"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.contactName },
                                        React.createElement(Input, { name: "contactName", placeholder: localize([
                                                "contact",
                                                "Company.person",
                                                "name",
                                            ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.contactName, className: `${errors.contactName && touched.contactName
                                                ? "input-error"
                                                : null}` }),
                                        errors.contactName && touched.contactName && (React.createElement("p", { className: "error" }, errors.contactName))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", { "data-test": "primaryContactType" }, localize(["primary", "contact", "type"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: telephoneTypeByValue(values.primaryTelephoneType, contactTypes) },
                                        React.createElement(Select, { showSearch: true, className: "mb-lg-0 mb-4", defaultValue: values.primaryTelephoneType, placeholder: localize([
                                                "select",
                                                "primary",
                                                "contact",
                                                "type",
                                            ]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleTelephoneChange, open: primaryVisible, onDropdownVisibleChange: setPrimaryVisible }, contactTypes.map((option) => (React.createElement(Option, { key: option.value + "primary", value: option.value, id: "option-div-container" }, option.name)))),
                                        errors.primaryTelephoneType &&
                                            touched.primaryTelephoneType && (React.createElement("p", { className: "error" }, errors.primaryTelephoneType))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", key: values.primaryTelephoneType + "primary" }, values.primaryTelephoneType === TelephoneType.Mobile ? (React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "primaryMobileNumber" },
                                        localize(["primary", "mobile", "number"]),
                                        " ",
                                        React.createElement(Asterik, { hidden: isReadOnly }))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.secondaryTelephoneNumber },
                                    React.createElement(Input, { name: "primaryTelephoneNumber", placeholder: localize([
                                            "primary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: (isEdit ? "" : "07") + values.primaryTelephoneNumber, className: `${errors.primaryTelephoneNumber &&
                                            touched.primaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.primaryTelephoneNumber &&
                                        touched.primaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.primaryTelephoneNumber))))) : (React.createElement("div", { className: "form-group", key: values.primaryTelephoneType + "primary" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "primaryTelephoneNumber" },
                                        localize(["primary", "telephone", "number"]),
                                        " ",
                                        React.createElement(Asterik, { hidden: isReadOnly }))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.secondaryTelephoneNumber },
                                    React.createElement(Input, { name: "primaryTelephoneNumber", placeholder: localize([
                                            "primary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.primaryTelephoneNumber, className: `${errors.primaryTelephoneNumber &&
                                            touched.primaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.primaryTelephoneNumber &&
                                        touched.primaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.primaryTelephoneNumber)))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["email", "address"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.emailAddress },
                                        React.createElement(Input, { name: "emailAddress", placeholder: localize(["email", "address"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.emailAddress, className: `${errors.emailAddress && touched.emailAddress
                                                ? "input-error"
                                                : null}` }),
                                        errors.emailAddress && touched.emailAddress && (React.createElement("p", { className: "error" }, errors.emailAddress))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", { "data-test": "secondaryContactType" }, localize(["secondary", "contact", "type"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: telephoneTypeByValue(values.secondaryTelephoneType, contactTypes) },
                                        React.createElement(Select, { showSearch: true, className: "mb-lg-0 mb-4", defaultValue: values.secondaryTelephoneType, placeholder: localize(["select", "contact", "type"]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleSecondaryTelephoneChange, open: secondaryVisible, onDropdownVisibleChange: setSecondaryVisible }, contactTypes.map((option) => (React.createElement(Option, { key: option.value + "secondary", value: option.value, id: "option-div-container" }, option.name)))),
                                        errors.secondaryTelephoneType &&
                                            touched.secondaryTelephoneType && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneType))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", key: values.secondaryTelephoneType + "secondary" }, values.secondaryTelephoneType === TelephoneType.Mobile ? (React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "secondaryMobileNumber" }, localize(["secondary", "mobile", "number"]))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.secondaryTelephoneNumber },
                                    React.createElement(Input, { name: "secondaryTelephoneNumber", placeholder: localize([
                                            "secondary",
                                            "mobile",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: (isEdit ? "" : "07") +
                                            values.secondaryTelephoneNumber, className: `${errors.secondaryTelephoneNumber &&
                                            touched.secondaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.secondaryTelephoneNumber &&
                                        touched.secondaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneNumber))))) : (React.createElement("div", { className: "form-group", key: values.secondaryTelephoneType + "secondary" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "secondaryTelephoneNumber" }, localize(["secondary", "telephone", "number"]))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.secondaryTelephoneNumber },
                                    React.createElement(Input, { name: "secondaryTelephoneNumber", placeholder: localize([
                                            "secondary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.secondaryTelephoneNumber, className: `${errors.secondaryTelephoneNumber &&
                                            touched.secondaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.secondaryTelephoneNumber &&
                                        touched.secondaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneNumber)))))))))),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["address", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement(Tabs, { defaultActiveKey: hide.status ? "1" : selectedTab, onChange: setSelectedTab, className: hide.status ? "printMode" : "" },
                            React.createElement(TabPane, { tab: localize(["registered", "address"]), key: "1" },
                                React.createElement("div", { className: hide.status ? "box-heading small" : "hidden" }, localize(["registered", "address"])),
                                React.createElement(RegisterAddress, { values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, sameAddress: ((_b = values.addresses[0]) === null || _b === void 0 ? void 0 : _b.type) === 1, setFieldValue: setFieldValue, isReadOnly: isReadOnly }),
                                ((_c = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _c === void 0 ? void 0 : _c[1]) && ((_d = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _d === void 0 ? void 0 : _d[1]) ? (((_e = values.addresses[0]) === null || _e === void 0 ? void 0 : _e.type) === 1 &&
                                    ((_f = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _f === void 0 ? void 0 : _f[0]) &&
                                    ((_g = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _g === void 0 ? void 0 : _g[0]) ? (React.createElement("p", { className: "error" }, localize(["sameErrorsInContactAddress"]))) : (React.createElement("p", { className: "error" }, localize(["contactAddress", "isRequired!"])))) : null),
                            React.createElement(TabPane, { tab: localize(["contact", "address"]), key: hide.status ? "1" : "2" },
                                React.createElement("div", { className: hide.status ? "box-heading small" : "hidden" }, localize(["contact", "address"])),
                                React.createElement("div", { hidden: isReadOnly && ((_h = values.addresses[0]) === null || _h === void 0 ? void 0 : _h.type) !== 1, className: "mt-4" },
                                    React.createElement(Checkbox, { onChange: onSameAddressChange, name: `addresses.${0}.type`, className: ((_j = values.addresses[0]) === null || _j === void 0 ? void 0 : _j.type) !== 1 && hide.status
                                            ? "hidden"
                                            : "mb-2 ", defaultChecked: ((_k = values.addresses[0]) === null || _k === void 0 ? void 0 : _k.type) === 1, disabled: isReadOnly },
                                        " ",
                                        localize(["Company.sameAs", "registered", "address"]))),
                                React.createElement(ContactAddress, { values: values, handleBlur: handleBlur, handleChange: handleChange, setFieldValue: setFieldValue, errors: errors, touched: touched, isReadOnly: isReadOnly }),
                                ((_l = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _l === void 0 ? void 0 : _l[0]) && ((_m = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _m === void 0 ? void 0 : _m[0]) ? (React.createElement("p", { className: "error" }, localize(["registeredAddress", "isRequired!"]))) : null)))))),
        React.createElement(FormActionButtons, { onCancel: onCancel, fromId: "my-form", isEdit: !dirty || company.id > 0, isDirty: !dirty || isReadOnly, resetForm: () => {
                resetForm();
                setKey(Math.random());
            }, printOnClick: handlePrint, dataTest: "companyFormComponent", checkUnique: checkUniqueER })));
};
CompanyForm.propTypes = {
    submitText: PropTypes.string,
    company: PropTypes.shape({
        id: PropTypes.number,
        parentId: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.number,
        registrationNumber: PropTypes.string,
        contactName: PropTypes.string,
        primaryTelephoneType: PropTypes.number,
        primaryTelephoneNumber: PropTypes.string,
        secondaryTelephoneType: PropTypes.number,
        secondaryTelephoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            line1: PropTypes.string,
            line2: PropTypes.string,
            town: PropTypes.string,
            county: PropTypes.string,
            postCode: PropTypes.string,
            type: PropTypes.number,
            id: PropTypes.number,
        })),
        active: PropTypes.bool,
    }),
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    companies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        parentId: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.number,
        registrationNumber: PropTypes.string,
        contactName: PropTypes.string,
        primaryTelephoneType: PropTypes.number,
        primaryTelephoneNumber: PropTypes.string,
        secondaryTelephoneType: PropTypes.number,
        secondaryTelephoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            line1: PropTypes.string,
            line2: PropTypes.string,
            town: PropTypes.string,
            county: PropTypes.string,
            postCode: PropTypes.string,
            type: PropTypes.number,
            id: PropTypes.number,
        })),
        active: PropTypes.bool,
    })),
};
export default CompanyForm;
