import { Button, Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
import isActionAllowed from "../../../core/authorization/userPermissions";
import { DelphiGroupId } from "../../../core/constants/groupConstants";
import { SITE_EDIT } from "../../../core/constants/permissionConstants";
import { CompanyType } from "../../../core/enums/CompanyType";
import isEmpty from "../../../core/helpers/isEmpty";
import usePermissions from "../../../core/hooks/usePermissions";
import localize from "../../../core/utils/translation-files";
import RenderSvg from "../icons/RenderSvg";
import ListingPermissionDropdown from "./ListingPermissionDropdown";
const FiltersDropdownMenu = ({ onChangeGroupOptions, groupOptionValues, onApply, customPlaceHolder, haveDefaultCompanyHandler, haveDefaultSiteHandler, allowClearCompany, allowClearGroup = false, allowClearSite, showDropDown = false, removeCompanyId = false, removeSiteId = false, removeCategoryId = false, allowNAItem = false, }) => {
    const [visible, setVisible] = useState(false);
    const [filterValues, setFilterValues] = useState({
        groupId: null,
        companyId: null,
        siteId: null,
        categoryId: null,
        subcategoryId: null,
        trackedItemId: null,
    });
    const { singleGroupValues, singleCompanyValues, singleSiteValues, groupOptions, isSingleSiteAdmin, isDelphieorSuperUser, isSiteAdmin, } = usePermissions();
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        if ((groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) &&
            isEmpty(groupOptionValues === null || groupOptionValues === void 0 ? void 0 : groupOptionValues.groupId) &&
            !allowClearGroup) {
            if (isDelphieorSuperUser && haveDefaultCompanyHandler) {
                onChangeGroupOptions({
                    groupId: (_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : DelphiGroupId,
                    companyId: (_d = (_c = groupOptions[0]) === null || _c === void 0 ? void 0 : _c.listSimpleDataDTO[0]) === null || _d === void 0 ? void 0 : _d.id,
                    siteId: null,
                });
            }
            else if (haveDefaultSiteHandler && isSiteAdmin) {
                onChangeGroupOptions({
                    groupId: (_f = (_e = groupOptions[0]) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : DelphiGroupId,
                    companyId: (_h = (_g = groupOptions[0]) === null || _g === void 0 ? void 0 : _g.listSimpleDataDTO[0]) === null || _h === void 0 ? void 0 : _h.id,
                    siteId: (_l = (_k = (_j = groupOptions[0]) === null || _j === void 0 ? void 0 : _j.listSimpleDataDTO[0]) === null || _k === void 0 ? void 0 : _k.listSimpleDataDTO[0]) === null || _l === void 0 ? void 0 : _l.id,
                });
            }
            else {
                const [groupId, companyId] = [
                    (_p = (_m = singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.id) !== null && _m !== void 0 ? _m : (_o = groupOptions[0]) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : DelphiGroupId,
                    removeCompanyId ? null : (_q = singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.listSimpleDataDTO[0]) === null || _q === void 0 ? void 0 : _q.id,
                ];
                //select first site of company, if permission is site admin on site of any company
                const isSiteAdminOnCompany = !isActionAllowed([SITE_EDIT], {
                    type: CompanyType.Company,
                    id: companyId,
                    parentId: groupId,
                });
                onChangeGroupOptions({
                    groupId,
                    companyId: removeCompanyId ? null : companyId,
                    siteId: removeSiteId
                        ? null
                        : isSiteAdminOnCompany
                            ? (_s = (_r = singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.listSimpleDataDTO[0]) === null || _r === void 0 ? void 0 : _r.listSimpleDataDTO[0]) === null || _s === void 0 ? void 0 : _s.id
                            : (_t = singleCompanyValues === null || singleCompanyValues === void 0 ? void 0 : singleCompanyValues.listSimpleDataDTO[0]) === null || _t === void 0 ? void 0 : _t.id,
                    sortingParameters: { sortColumnName: null, sortOrder: null },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        groupOptions,
        singleGroupValues,
        singleCompanyValues,
        singleSiteValues,
        groupOptionValues === null || groupOptionValues === void 0 ? void 0 : groupOptionValues.groupId,
    ]);
    const handelApplyFilter = () => {
        setVisible(false);
        onApply(filterValues);
        //apply local values to parent on button click
        onChangeGroupOptions(filterValues);
    };
    useEffect(() => {
        if (!isEmpty(groupOptionValues === null || groupOptionValues === void 0 ? void 0 : groupOptionValues.groupId)) {
            //set values in local state for refresh page or default mounting
            setFilterValues(groupOptionValues);
        }
    }, [groupOptionValues]);
    const handleSetVisible = (visible) => {
        setVisible(visible);
        if (!visible) {
            ///on close set local values to prev apply filter values
            setFilterValues(groupOptionValues);
        }
    };
    return (React.createElement(React.Fragment, null, (!isSingleSiteAdmin || showDropDown) && (React.createElement(Popover, { placement: "bottomRight", overlayClassName: "filterOverlayWrapper", content: React.createElement(Menu, { className: "action-menu filtersMenuDropdown", style: { minWidth: "280px" }, id: "filter-dropdown" },
            React.createElement(ListingPermissionDropdown, { onChangeGroupOptions: setFilterValues, groupOptionValues: filterValues, customPlaceHolder: customPlaceHolder && customPlaceHolder, haveDefaultSiteHandler: haveDefaultSiteHandler && haveDefaultSiteHandler, haveDefaultCompanyHandler: haveDefaultCompanyHandler && haveDefaultCompanyHandler, allowClearCompany: allowClearCompany && allowClearCompany, allowClearSite: allowClearSite && allowClearSite, allowClearGroup: allowClearGroup && allowClearGroup, allowNAItem: allowNAItem }),
            React.createElement("div", { className: "col-12" },
                React.createElement(Button, { disabled: haveDefaultSiteHandler && isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId), htmlType: "button", type: "primary", style: { width: "100%" }, onClick: handelApplyFilter, id: "apply-filter-btn" },
                    React.createElement("span", { className: "icofont icofont-filter mr-2", style: { fontSize: "1.3em" } }),
                    localize(["Button.apply"])))), trigger: "click", visible: visible, onVisibleChange: handleSetVisible },
        React.createElement(Button, { htmlType: "button", type: "default", id: "show-filters" },
            React.createElement(RenderSvg, { icon: "FilterDropdown" }),
            localize(["Button.filter"]))))));
};
export default FiltersDropdownMenu;
