import { GET_TRACKED_ITEMS_SUCCESS, GET_USER_CREATED_TRACKED_ITEMS_SUCCESS, GET_SYSTEM_CREATED_TRACKED_ITEMS_SUCCESS, ADD_TRACKED_ITEM_SUCCESS, UPDATE_TRACKED_ITEM_SUCCESS, INACTIVATE_TRACKED_ITEM_SUCCESS, ACTIVATE_TRACKED_ITEM_SUCCESS, EMPTY_TRACKED_ITEM_SUCCESS, GET_HIDDEN_TRACKED_ITEMS_SUCCESS, } from "./types";
import { TrackedItemApi } from "../../core/api/trackedItemApi";
export const getTrackedItemsSuccess = (data) => ({
    type: GET_TRACKED_ITEMS_SUCCESS,
    payload: data,
});
export const getUserCreatedTrackedItemsSuccess = (data) => ({
    type: GET_USER_CREATED_TRACKED_ITEMS_SUCCESS,
    payload: data,
});
export const getSystemCreatedTrackedItemsSuccess = (data) => ({
    type: GET_SYSTEM_CREATED_TRACKED_ITEMS_SUCCESS,
    payload: data,
});
export const getHiddenTrackedItemsSuccess = (data) => ({
    type: GET_HIDDEN_TRACKED_ITEMS_SUCCESS,
    payload: data,
});
export const addTrackedItemSuccess = (data) => ({
    type: ADD_TRACKED_ITEM_SUCCESS,
    payload: data,
});
export const updateTrackedItemSuccess = (data) => ({
    type: UPDATE_TRACKED_ITEM_SUCCESS,
    payload: data,
});
export const inActivateTrackedItemSuccess = (data) => ({
    type: INACTIVATE_TRACKED_ITEM_SUCCESS,
    payload: data,
});
export const activateTrackedItemSuccess = (data) => ({
    type: ACTIVATE_TRACKED_ITEM_SUCCESS,
    payload: data,
});
export const emptyTrackedItemsSuccess = () => ({
    type: EMPTY_TRACKED_ITEM_SUCCESS,
});
export function getTrackedItems(companyId) {
    return function (dispatch) {
        return TrackedItemApi.getTrackedItems()
            .then((users) => {
            if (users.length > 0) {
                dispatch(getTrackedItemsSuccess(users));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getUserCreatedTrackedItems(groupId, companyId = -1, siteId = -1) {
    return function (dispatch) {
        dispatch(getUserCreatedTrackedItemsSuccess([]));
        return TrackedItemApi.getUserCreatedTrackedItems(groupId, companyId, siteId)
            .then((users) => {
            if (Array.isArray(users)) {
                dispatch(getUserCreatedTrackedItemsSuccess(users));
                return users;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getSystemCreatedTrackedItems(groupId, companyId = -1, siteId = -1) {
    return function (dispatch) {
        dispatch(getSystemCreatedTrackedItemsSuccess([]));
        return TrackedItemApi.getSystemCreatedTrackedItems(groupId, companyId, siteId)
            .then((users) => {
            if (Array.isArray(users)) {
                dispatch(getSystemCreatedTrackedItemsSuccess(users));
                return users;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getHiddenTrackedItems(groupId, companyId = -1, siteId = -1) {
    return function (dispatch) {
        dispatch(getUserCreatedTrackedItemsSuccess([]));
        return TrackedItemApi.getHiddenTrackedItems(groupId, companyId, siteId)
            .then((users) => {
            if (Array.isArray(users)) {
                dispatch(getHiddenTrackedItemsSuccess(users));
                return users;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getTrackedItemsForTiles(siteId) {
    return function (dispatch) {
        return TrackedItemApi.getTrackedItemsForTiles(siteId)
            .then((users) => {
            if (users.length > 0) {
                dispatch(getTrackedItemsSuccess(users));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addTrackedItem(user) {
    return function (dispatch) {
        return TrackedItemApi.createTrackedItem(user)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(addTrackedItemSuccess(response));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editTrackedItem(user) {
    return function (dispatch) {
        return TrackedItemApi.updateTrackedItem(user)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(updateTrackedItemSuccess(response));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function inActivateTrackedItem(data) {
    return function (dispatch) {
        return TrackedItemApi.inActivateTrackedItem(data)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(inActivateTrackedItemSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function activateTrackedItem(data) {
    return function (dispatch) {
        return TrackedItemApi.activateTrackedItem(data)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(activateTrackedItemSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
